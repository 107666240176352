import React, { useState } from "react";
import PropTypes from "prop-types";
import "./productdetails.css";

const ProductDetails = ({ product, onAddToCart, onAddToWishlist }) => {
  const [selectedVariant, setSelectedVariant] = useState(
    product.variants?.[0] || {
      name: "Default Variant",
      price: 0,
      image: "",
      stock: 0,
    }
  );
  const [quantity, setQuantity] = useState(1);

  const handleVariantChange = (variant) => {
    setSelectedVariant(variant);
  };

  const handleQuantityChange = (e) => {
    const newQuantity = Math.max(1, Number(e.target.value));
    setQuantity(newQuantity);
  };

  const calculateTotalPrice = () => {
    return (selectedVariant.price * quantity).toFixed(2);
  };

  const addToCartHandler = () => {
    if (onAddToCart) {
      onAddToCart(selectedVariant, quantity);
    } else {
      console.warn("Add to Cart function not provided.");
    }
  };

  const addToWishlistHandler = () => {
    if (onAddToWishlist) {
      onAddToWishlist(product);
    } else {
      console.warn("Add to Wishlist function not provided.");
    }
  };

  return (
    <div className="product-details-container">
      {/* Product Overview Section */}
      <div className="product-overview">
        <div className="image-gallery">
          <img
            className="main-image"
            src={selectedVariant.image || "/placeholder.png"}
            alt={product.name || "Product Image"}
          />
          <div className="thumbnail-gallery">
            {product.images?.map((img, index) => (
              <img
                key={index}
                className="thumbnail"
                src={img}
                alt={`Thumbnail ${index + 1}`}
                onClick={() => handleVariantChange(product.variants?.[index])}
              />
            )) || <p>No images available</p>}
          </div>
        </div>
        <div className="product-info">
          <h1>{product.name || "Unnamed Product"}</h1>
          <div className="price-and-rating">
            <p className="price">${selectedVariant.price.toFixed(2)}</p>
            <p className="rating">
              {product.rating || "N/A"} ★ (
              {product.reviews?.length || 0} reviews)
            </p>
          </div>
          <p className="description">{product.description || "No description available"}</p>
          <div className="variant-selector">
            <h3>Variants</h3>
            {product.variants?.map((variant, index) => (
              <button
                key={index}
                className={`variant-button ${
                  selectedVariant === variant ? "active" : ""
                }`}
                onClick={() => handleVariantChange(variant)}
              >
                {variant.name}
              </button>
            )) || <p>No variants available</p>}
          </div>
          <div className="purchase-options">
            <div className="quantity-selector">
              <label htmlFor="quantity-input">Quantity:</label>
              <input
                id="quantity-input"
                type="number"
                min="1"
                value={quantity}
                onChange={handleQuantityChange}
                aria-label="Select product quantity"
              />
            </div>
            <button
              className="add-to-cart-button"
              onClick={addToCartHandler}
              aria-label="Add product to cart"
            >
              Add to Cart
            </button>
            <button
              className="wishlist-button"
              onClick={addToWishlistHandler}
              aria-label="Add product to wishlist"
            >
              Add to Wishlist
            </button>
          </div>
          <p className="stock-info">
            {selectedVariant.stock > 0
              ? `In stock (${selectedVariant.stock} available)`
              : "Out of stock"}
          </p>
        </div>
      </div>

      {/* Product Details Section */}
      <div className="product-details">
        <h2>Product Details</h2>
        <ul>
          {product.specifications?.map((spec, index) => (
            <li key={index}>
              <strong>{spec.label}:</strong> {spec.value}
            </li>
          )) || <p>No specifications provided</p>}
        </ul>
      </div>

      {/* Shipping & Delivery Section */}
      <div className="shipping-info">
        <h2>Shipping & Delivery</h2>
        <p>Estimated delivery: {product.deliveryEstimate || "N/A"}</p>
        <p>Shipping fee: $
          {(product.shippingFee || 0).toFixed(2)}
        </p>
      </div>

      {/* Reviews Section */}
      <div className="reviews">
        <h2>Customer Reviews</h2>
        {product.reviews?.length > 0 ? (
          product.reviews.map((review, index) => (
            <div key={index} className="review">
              <p>
                <strong>{review.name || "Anonymous"}</strong> -{" "}
                {review.rating || "N/A"} ★
              </p>
              <p>{review.comment || "No comment provided"}</p>
            </div>
          ))
        ) : (
          <p>No reviews yet. Be the first to review this product!</p>
        )}
      </div>

      {/* Related Products Section */}
      <div className="related-products">
        <h2>You May Also Like</h2>
        <div className="related-products-grid">
          {product.relatedProducts?.map((related, index) => (
            <div key={index} className="related-product-card">
              <img src={related.image || "/placeholder.png"} alt={related.name} />
              <p>{related.name || "Unnamed Product"}</p>
              <p>${(related.price || 0).toFixed(2)}</p>
            </div>
          )) || <p>No related products available</p>}
        </div>
      </div>

      {/* FAQ Section */}
      <div className="faq">
        <h2>FAQs</h2>
        {product.faqs?.map((faq, index) => (
          <div key={index} className="faq-item">
            <p>
              <strong>Q: {faq.question}</strong>
            </p>
            <p>A: {faq.answer}</p>
          </div>
        )) || <p>No FAQs available</p>}
      </div>
    </div>
  );
};

ProductDetails.propTypes = {
  product: PropTypes.object.isRequired,
  onAddToCart: PropTypes.func,
  onAddToWishlist: PropTypes.func,
};

ProductDetails.defaultProps = {
  onAddToCart: null,
  onAddToWishlist: null,
};

export default ProductDetails;
