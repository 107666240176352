import React, { useState } from 'react';
import './about.css';
import logo from "./logo.png"; 

const About = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  const teamMembers = [
    { name: 'Fidelis E', email: 'fidelisegede@benfieg.com.ng', phone: '+2348037190683' },
    { name: 'Njideka E', email: 'njidekaegede@benfieg.com.ng', phone: '+2348035971600' },
    // Add more team members as needed
  ];

  const handleMemberClick = (member) => {
    setSelectedMember(member);
  };

  const closeModal = () => {
    setSelectedMember(null);
  };

  return (
    <div className="about-container">
      <header className="about-header">
        <h1> Benfieg Company Ltd.</h1>
        <img src={logo} alt="Benfieg banner" className="about-banner" />
      </header>

      <section className="about-section">
        <h2>Our Story</h2>
        <p>
          Founded in 1995, Benfieg Company Ltd specializes in high-quality tractor spare parts. Our mission is to provide durable, affordable, and reliable products to our customers worldwide.
        </p>
      </section>

      <section className="about-section">
        <h2>Our Vision</h2>
        <p>
          To become the leading supplier of tractor spare parts globally by combining innovation with customer satisfaction.
        </p>
      </section>

      <section className="about-section">
        <h2>Our Mission</h2>
        <p>
          Ensuring that every tractor owner has access to the best spare parts that enhance productivity and longevity.
        </p>
      </section>

      <section className="about-section">
        <h2>Meet Our Team</h2>
        <p>Click on a team member's name to learn more about them:</p>
        <ul className="team-list">
          {teamMembers.map((member, index) => (
            <li key={index} className="team-member" onClick={() => handleMemberClick(member)}>
              {member.name}
            </li>
          ))}
        </ul>
      </section>

      <section className="about-section">
        <h2>Get in Touch</h2>
        <p>
          We'd love to hear from you! <a href="/contact">Contact us</a> for any inquiries.
        </p>
      </section>

      {selectedMember && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h3>{selectedMember.name}</h3>
            <p><strong>Email:</strong> {selectedMember.email}</p>
            <p><strong>Phone:</strong> {selectedMember.phone}</p>
            <button className="close-button" onClick={closeModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
