import React, { useState } from "react";
import { Link } from "react-router-dom";  // Importing Link for routing
import "./category.css";


// Expanded categories and subcategories list
const categories = [
  {
    name: "Engine Parts",
    subcategories: [
      { name: "Rings", path: "/engine-parts/rings" },
      { name: "Pistons", path: "/engine-parts/pistons" },
      { name: "Cylinder", path: "/engine-parts/cylinder" },
      { name: "Gaskets", path: "/engine-parts/gaskets" },
      { name: "Crankshafts", path: "/engine-parts/crankshafts" },
      { name: "Camshafts", path: "/engine-parts/camshafts" },
      { name: "Engine Blocks", path: "/engine-parts/engine-blocks" },
    ],
  },
  {
    name: "Transmission Parts",
    subcategories: [
      { name: "Clutchplates", path: "/transmission-parts/clutchplates" },
      { name: "Gearboxes", path: "/transmission-parts/gearboxes" },
      { name: "Bearings", path: "/transmission-parts/bearings" },
      { name: "Shafts", path: "/transmission-parts/shafts" },
      { name: "Torque Converters", path: "/transmission-parts/torque-converters" },
    ],
  },
  {
    name: "Hydraulic Parts",
    subcategories: [
      { name: "Hydraulic Pumps", path: "/hydraulic-parts/hydraulic pumps" },
      { name: "Hydraulic Cylinders", path: "/hydraulic-parts/hydraulic-cylinders" },
      { name: "Valves", path: "/hydraulic-parts/valves" },
      { name: "Hoses and Fittings", path: "/hydraulic-parts/hoses-fittings" },
      { name: "Filters", path: "/hydraulic-parts/filters" },
    ],
  },
  {
    name: "Electrical Parts",
    subcategories: [
      { name: "Alternators", path: "/electrical-parts/alternators" },
      { name: "Starters", path: "/electrical-parts/starters" },
      { name: "Batteries", path: "/electrical-parts/batteries" },
      { name: "Lights", path: "/electrical-parts/lights" },
      { name: "Wiring Harnesses", path: "/electrical-parts/wiring-harnesses" },
      { name: "Switches", path: "/electrical-parts/switches" },
    ],
  },
  {
    name: "Brake System",
    subcategories: [
      { name: "Brake Pads", path: "/brake-system/brakepads" },
      { name: "Brake Discs", path: "/brake-system/brakediscs" },
      { name: "brakedrums", path: "/brake-system/brakedrums" },
      { name: "master cylinders", path: "/brake-system/mastercylinders" },
      { name: "Brake Cables", path: "/brake-system/brakecables" },
    ],
  },
  {
    name: "Cooling System",
    subcategories: [
      { name: "Radiators", path: "/cooling-system/radiators" },
      { name: "Fans", path: "/cooling-system/fans" },
      { name: "Thermostats", path: "/cooling-system/thermostats" },
      { name: "Water Pumps", path: "/cooling-system/water-pumps" },
      { name: "Coolant Hoses", path: "/cooling-system/coolant-hoses" },
    ],
  },
  {
    name: "Fuel System",
    subcategories: [
      { name: "Fuel Pumps", path: "/fuel-system/fuel-pumps" },
      { name: "Injectors", path: "/fuel-system/injectors" },
      { name: "Fuel Filters", path: "/fuel-system/fuel-filters" },
      { name: "Fuel Tanks", path: "/fuel-system/fuel-tanks" },
      { name: "Fuel Lines", path: "/fuel-system/fuel-lines" },
    ],
  },
  {
    name: "Exhaust System",
    subcategories: [
      { name: "Mufflers", path: "/exhaust-system/mufflers" },
      { name: "Exhaust Pipes", path: "/exhaust-system/exhaust-pipes" },
      { name: "Catalytic Converters", path: "/exhaust-system/catalytic-converters" },
      { name: "Exhaust Manifolds", path: "/exhaust-system/exhaust-manifolds" },
    ],
  },
  {
    name: "Chassis and Suspension",
    subcategories: [
      { name: "Shock Absorbers", path: "/chassis-suspension/shock-absorbers" },
      { name: "Springs", path: "/chassis-suspension/springs" },
      { name: "Control Arms", path: "/chassis-suspension/control-arms" },
      { name: "Suspension Bushings", path: "/chassis-suspension/suspension-bushings" },
      { name: "Ball Joints", path: "/chassis-suspension/ball-joints" },
    ],
  },
  {
    name: "Steering Parts",
    subcategories: [
      { name: "Steering Gearboxes", path: "/steering-parts/steering-gearboxes" },
      { name: "Steering Columns", path: "/steering-parts/steering-columns" },
      { name: "Power Steering Pumps", path: "/steering-parts/power-steering-pumps" },
      { name: "Tie Rods", path: "/steering-parts/tie-rods" },
      { name: "Steering Racks", path: "/steering-parts/steering-racks" },
    ],
  },
  {
    name: "Lighting and Mirrors",
    subcategories: [
      { name: "Headlights", path: "/lighting-mirrors/headlights" },
      { name: "Tail Lights", path: "/lighting-mirrors/tail-lights" },
      { name: "Side Mirrors", path: "/lighting-mirrors/side-mirrors" },
      { name: "Indicators", path: "/lighting-mirrors/indicators" },
    ],
  },
  {
    name: "Tires and Wheels", // New category
    subcategories: [
      { name: "Tires", path: "/tires-wheels/tires" },
      { name: "Wheels", path: "/tires-wheels/wheels" },
    ],
  },
  {
    name: "Body and Cabin Parts", // New category
    subcategories: [
      { name: "Body Panels", path: "/body-cabin/body-panels" },
      { name: "Cabin Accessories", path: "/body-cabin/cabin-accessories" },
    ],
  },
  {
    name: "Attachments and Implements", // New category
    subcategories: [
      { name: "Plows", path: "/attachments-implements/plows" },
      { name: "Harvesters", path: "/attachments-implements/harvesters" },
    ],
  },
  {
    name: "Belts and Chains", // New category
    subcategories: [
      { name: "Drive Belts", path: "/belts-chains/drive-belts" },
      { name: "Chains", path: "/belts-chains/chains" },
    ],
  },
  {
    name: "Filters", // New category
    subcategories: [
      { name: "Oil Filters", path: "/filters/oil-filters" },
      { name: "Air Filters", path: "/filters/air-filters" },
    ],
  },
  {
    name: "Tools and Accessories", // New category
    subcategories: [
      { name: "Hand Tools", path: "/tools-accessories/hand-tools" },
      { name: "Power Tools", path: "/tools-accessories/power-tools" },
    ],
  },
  {
    name: "Safety and Protection", // New category
    subcategories: [
      { name: "Protective Gear", path: "/safety-protection/protective-gear" },
      { name: "Fire Extinguishers", path: "/safety-protection/fire-extinguishers" },
    ],
  },
  {
    name: "Miscellaneous", // New category
    subcategories: [
      { name: "Miscellaneous Parts", path: "/miscellaneous/miscellaneous-parts" },
    ],
  },
];

const Category = () => {
  const [openCategory, setOpenCategory] = useState(null);
  const [allOpen, setAllOpen] = useState(false); // For "Open All" and "Close All"

  const toggleSubcategories = (index) => {
    setOpenCategory(openCategory === index ? null : index);
  };

  const toggleAllCategories = () => {
    if (allOpen) {
      setOpenCategory(null); // Collapse all categories
    } else {
      setOpenCategory("all"); // Expand all categories
    }
    setAllOpen(!allOpen);
  };

  return (
    <div className="category-container">
      <h2>Shop by Category</h2>
      <div className="controls">
        <button onClick={toggleAllCategories}>
          {allOpen ? "Close All Categories" : "Open All Categories"}
        </button>
      </div>
      <div className="category-grid">
        {categories.map((category, index) => (
          <div key={index} className="category-card">
            <h3 onClick={() => toggleSubcategories(index)}>{category.name}</h3>
            {(openCategory === index || allOpen) && (
              <ul className="subcategory-list">
                {category.subcategories.map((subcategory, subIndex) => (
                  <li key={subIndex}>
                    <Link to={`/${category.name.toLowerCase().replace(" ", "-")}/${subcategory.name.toLowerCase().replace(" ", "-")}`}>
                      {subcategory.name} </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
