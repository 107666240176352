import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import "./contact.css";

const Contact = () => {
  const [status, setStatus] = useState("");
  const [captchaQuestion, setCaptchaQuestion] = useState({});

  const navigate = useNavigate(); // Initialize navigate function

  // Generate a random captcha question
  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10) + 1;
    const num2 = Math.floor(Math.random() * 10) + 1;
    setCaptchaQuestion({ question: `${num1} + ${num2}`, answer: num1 + num2 });
  };

  // Initialize captcha on mount
  React.useEffect(() => {
    generateCaptcha();
  }, []);

  // Function to send message to Telegram bot
  const sendMessageToTelegram = (message) => {
    const token = "7552208424:AAEY8lIrbDj2eaQ8RPajWsK-5KXu6ZG5hEc";  // Your bot token
    const chatId = "7783122341";  // Your chat ID
    const url = `https://api.telegram.org/bot${token}/sendMessage`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        chat_id: chatId,
        text: message,
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log("Message sent successfully:", data))
      .catch((error) => console.error("Error sending message:", error));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;
    const name = form.name.value.trim();
    const email = form.email.value.trim();
    const phone = form.phone.value.trim();
    const whatsapp = form.whatsapp.value.trim();
    const message = form.message.value.trim();

    // Validate at least one contact number
    if (!phone && !whatsapp) {
      alert("Please provide at least one phone number or WhatsApp number.");
      return;
    }

    // Validate captcha
    if (parseInt(form.captcha.value.trim()) !== captchaQuestion.answer) {
      alert("Captcha answer is incorrect. Please try again.");
      generateCaptcha();
      return;
    }

    // Prepare message to send to Telegram
    const telegramMessage = `
      New contact form submission:
      Name: ${name}
      Email: ${email}
      Phone: ${phone}
      WhatsApp: ${whatsapp}
      Message: ${message}
    `;

    // Send the message to Telegram
    sendMessageToTelegram(telegramMessage);

    // Mock API call and reset form
    setStatus("Your message has been sent!");
    form.reset(); // Reset form fields
    generateCaptcha(); // Generate a new captcha
  };

  return (
    <div className="contact-container">
      <h1 className="contact-title">Contact Us</h1>

      {/* Add a button to return to the previous page */}
      <button
        onClick={() => navigate(-1)} // Navigate to the previous page
        className="contact-button"
        style={{ marginBottom: "20px" }}
      >
        Go Back
      </button>

      {/* Contact Form */}
      <form onSubmit={handleSubmit} className="contact-form">
        <label htmlFor="name" className="contact-label">
          Name:
        </label>
        <input
          type="text"
          id="name"
          name="name"
          className="contact-input"
          placeholder="Your Name"
          required
        />

        <label htmlFor="email" className="contact-label">
          Email (optional):
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className="contact-input"
          placeholder="Your Email"
        />

        <label htmlFor="phone" className="contact-label">
          Phone Number:
        </label>
        <input
          type="tel"
          id="phone"
          name="phone"
          className="contact-input"
          placeholder="Your Phone Number"
        />

        <label htmlFor="whatsapp" className="contact-label">
          WhatsApp Number:
        </label>
        <input
          type="tel"
          id="whatsapp"
          name="whatsapp"
          className="contact-input"
          placeholder="Your WhatsApp Number"
        />

        <label htmlFor="message" className="contact-label">
          Message:
        </label>
        <textarea
          id="message"
          name="message"
          className="contact-textarea"
          placeholder="Your Message"
          rows="5"
          required
        ></textarea>

        <label htmlFor="captcha" className="contact-label">
          Captcha: {captchaQuestion.question}
        </label>
        <input
          type="text"
          id="captcha"
          name="captcha"
          className="contact-input"
          placeholder="Your Answer"
          required
        />

        <button type="submit" className="contact-button">
          Send Message
        </button>
      </form>

      {/* Confirmation Status */}
      {status && <p className="form-status">{status}</p>}

      {/* Additional Details */}
      <div className="contact-details">
        <div className="contact-address">
          <h2>Our Location</h2>
          <p>A25/4 Agbo Edo Market Nkwo Nnewi, Anambra, Nigeria</p>
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3967.823916081006!2d6.905418299999995!3d6.018896600000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1043bd5957eaf1bf%3A0x477e19db78805939!2sa25%2C%204%20Agbo%20Edo%20Market%2C%20Nnewi%20435101%2C%20Anambra!5e0!3m2!1sen!2sng!4v1732569257769!5m2!1sen!2sng"
            width="70%"
            height="200"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
        <div className="contact-hours">
          <h2>Business Hours</h2>
          <p>Monday - Friday: 9:00 AM - 5:30 PM</p>
          <p>Saturday: 10:00 AM - 5:00 PM</p>
          <p>Sunday: 10:00 AM - 3:00 PM</p>
        </div>
        <div className="social-media">
          <h2>Follow Us</h2>
          <div className="social-links">
            <div className="social-link">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                Facebook
              </a>
            </div>
            <div className="social-link">
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                Twitter
              </a>
            </div>
            {/* Remove LinkedIn */}
          </div>
        </div>
        <div className="faq-section">
          <h2>Frequently Asked Questions</h2>
          <details>
            <summary> How can I contact customer support? </summary>
            <p>You can reach us via the contact form or at&nbsp; 
              <a href="mailto:contact@benfieg.com.ng"> contact@benfieg.com.ng</a>
              </p>

          </details>

          <details>
            <summary>Where is your location? </summary>
            <p>Check the map in the location section above.</p>
          </details>
        </div>
      </div>
    </div>
  );
};

export default Contact;
