import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";
import logo from "./logo.png";  // Import logo correctly
import { ReactComponent as CartIcon } from './cart-icon.svg'; // Import cart icon as a React component (use SVG or icon library)

const Header = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" className="logo-image" /> {/* Use the imported logo here */}
      </div>
      <div className="company-name-container">
        <Link to="/">BenFieg Company </Link>
      </div>
      <button className="hamburger" onClick={toggleNav} aria-label="Toggle navigation">
        ☰
      </button>
      {/* Add the cart icon beside the hamburger button */}
      <Link to="/cart" className="cart-icon">
        <CartIcon className="cart-icon-svg" />
      </Link>
      {isNavOpen && (
        <div className="nav-modal">
          <div className="close-btn" onClick={toggleNav} aria-label="Close navigation">×</div>
          <nav className="nav">
            <div className="nav-links">
              <Link to="/" onClick={toggleNav}>Home</Link>
              <Link to="/category" onClick={toggleNav}>Shop</Link>
              <Link to="/form" onClick={toggleNav}>Login/Sign-Up</Link>
              <Link to="/contact" onClick={toggleNav}>Contact</Link>
              <Link to="/about" onClick={toggleNav}>About</Link>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
};

export default Header;
