import React, { useState } from "react";
import { productsData } from "./productData"; // Import the product data
import './AdminProducts.css';

const categories = [
    {
    name: "Engine Parts",
    subcategories: [
    { name: "Rings", path: "/engine-parts/rings" },
    { name: "Pistons", path: "/engine-parts/pistons" },
    { name: "Cylinder Heads", path: "/engine-parts/cylinder-heads" },
    { name: "Gaskets", path: "/engine-parts/gaskets" },
    { name: "Crankshafts", path: "/engine-parts/crankshafts" },
    { name: "Camshafts", path: "/engine-parts/camshafts" },
    { name: "Engine Blocks", path: "/engine-parts/engine-blocks" },
    ],
    },
    {
    name: "Transmission Parts",
    subcategories: [
    { name: "Clutch Plates", path: "/transmission-parts/clutch-plates" },
    { name: "Gearboxes", path: "/transmission-parts/gearboxes" },
    { name: "Bearings", path: "/transmission-parts/bearings" },
    { name: "Shafts", path: "/transmission-parts/shafts" },
    { name: "Torque Converters", path: "/transmission-parts/torque-converters" },
    ],
    },
    {
    name: "Hydraulic Parts",
    subcategories: [
    { name: "Hydraulic Pumps", path: "/hydraulic-parts/hydraulic-pumps" },
    { name: "Hydraulic Cylinders", path: "/hydraulic-parts/hydraulic-cylinders" },
    { name: "Valves", path: "/hydraulic-parts/valves" },
    { name: "Hoses and Fittings", path: "/hydraulic-parts/hoses-fittings" },
    { name: "Filters", path: "/hydraulic-parts/filters" },
    ],
    },
    {
    name: "Electrical Parts",
    subcategories: [
    { name: "Alternators", path: "/electrical-parts/alternators" },
    { name: "Starters", path: "/electrical-parts/starters" },
    { name: "Batteries", path: "/electrical-parts/batteries" },
    { name: "Lights and Bulbs", path: "/electrical-parts/lights-bulbs" },
    { name: "Wiring Harnesses", path: "/electrical-parts/wiring-harnesses" },
    { name: "Switches and Relays", path: "/electrical-parts/switches-relays" },
    ],
    },
    {
    name: "Brake System",
    subcategories: [
    { name: "Brake Pads", path: "/brake-system/brake-pads" },
    { name: "Brake Discs", path: "/brake-system/brake-discs" },
    { name: "Brake Drums", path: "/brake-system/brake-drums" },
    { name: "Master Cylinders", path: "/brake-system/master-cylinders" },
    { name: "Brake Cables", path: "/brake-system/brake-cables" },
    ],
    },
    {
    name: "Cooling System",
    subcategories: [
    { name: "Radiators", path: "/cooling-system/radiators" },
    { name: "Fans", path: "/cooling-system/fans" },
    { name: "Thermostats", path: "/cooling-system/thermostats" },
    { name: "Water Pumps", path: "/cooling-system/water-pumps" },
    { name: "Coolant Hoses", path: "/cooling-system/coolant-hoses" },
    ],
    },
    {
    name: "Fuel System",
    subcategories: [
    { name: "Fuel Pumps", path: "/fuel-system/fuel-pumps" },
    { name: "Injectors", path: "/fuel-system/injectors" },
    { name: "Fuel Filters", path: "/fuel-system/fuel-filters" },
    { name: "Fuel Tanks", path: "/fuel-system/fuel-tanks" },
    { name: "Fuel Lines", path: "/fuel-system/fuel-lines" },
    ],
    },
    {
    name: "Exhaust System",
    subcategories: [
    { name: "Mufflers", path: "/exhaust-system/mufflers" },
    { name: "Exhaust Pipes", path: "/exhaust-system/exhaust-pipes" },
    { name: "Catalytic Converters", path: "/exhaust-system/catalytic-converters" },
    { name: "Exhaust Manifolds", path: "/exhaust-system/exhaust-manifolds" },
    ],
    },
    {
    name: "Chassis and Suspension",
    subcategories: [
    { name: "Shock Absorbers", path: "/chassis-suspension/shock-absorbers" },
    { name: "Springs", path: "/chassis-suspension/springs" },
    { name: "Control Arms", path: "/chassis-suspension/control-arms" },
    { name: "Suspension Bushings", path: "/chassis-suspension/suspension-bushings" },
    { name: "Ball Joints", path: "/chassis-suspension/ball-joints" },
    ],
    },
    {
    name: "Steering Parts",
    subcategories: [
    { name: "Steering Gearboxes", path: "/steering-parts/steering-gearboxes" },
    { name: "Steering Columns", path: "/steering-parts/steering-columns" },
    { name: "Power Steering Pumps", path: "/steering-parts/power-steering-pumps" },
    { name: "Tie Rods", path: "/steering-parts/tie-rods" },
    { name: "Steering Racks", path: "/steering-parts/steering-racks" },
    ],
    },
    {
    name: "Lighting and Mirrors",
    subcategories: [
    { name: "Headlights", path: "/lighting-mirrors/headlights" },
    { name: "Tail Lights", path: "/lighting-mirrors/tail-lights" },
    { name: "Side Mirrors", path: "/lighting-mirrors/side-mirrors" },
    { name: "Indicators", path: "/lighting-mirrors/indicators" },
    ],
    },
    {
    name: "Tires and Wheels",
    subcategories: [
    { name: "Tires", path: "/tires-wheels/tires" },
    { name: "Wheels", path: "/tires-wheels/wheels" },
    ],
    },
    {
    name: "Body and Cabin Parts",
    subcategories: [
    { name: "Body Panels", path: "/body-cabin/body-panels" },
    { name: "Cabin Accessories", path: "/body-cabin/cabin-accessories" },
    ],
    },
    {
    name: "Attachments and Implements",
    subcategories: [
    { name: "Plows", path: "/attachments-implements/plows" },
    { name: "Harvesters", path: "/attachments-implements/harvesters" },
    ],
    },
    {
    name: "Belts and Chains",
    subcategories: [
    { name: "Drive Belts", path: "/belts-chains/drive-belts" },
    { name: "Chains", path: "/belts-chains/chains" },
    ],
    },
    {
    name: "Filters",
    subcategories: [
    { name: "Oil Filters", path: "/filters/oil-filters" },
    { name: "Air Filters", path: "/filters/air-filters" },
    ],
    },
    {
    name: "Tools and Accessories",
    subcategories: [
    { name: "Hand Tools", path: "/tools-accessories/hand-tools" },
    { name: "Power Tools", path: "/tools-accessories/power-tools" },
    ],
    },
    {
    name: "Safety and Protection",
    subcategories: [
    { name: "Protective Gear", path: "/safety-protection/protective-gear" },
    { name: "Fire Extinguishers", path: "/safety-protection/fire-extinguishers" },
    ],
    },
    {
    name: "Miscellaneous",
    subcategories: [
    { name: "Miscellaneous Parts", path: "/miscellaneous/miscellaneous-parts" },
    ],
    },
    ];
    

const AdminProducts = () => {
  // Local state to hold the product updates
  const [productId, setProductId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [images, setImages] = useState([]);
  const [message, setMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  // Handle file selection for images
  const handleImageChange = (e) => {
    const files = e.target.files;
    setImages(Array.from(files)); // Store selected images
  };

  // Handle product deletion
  const handleDeleteProduct = (id, subcategory) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      const productList = productsData[subcategory] || [];
      const updatedProducts = productList.filter((product) => product.id !== id);

      // Update the data
      productsData[subcategory] = updatedProducts;
      setMessage("Product removed successfully!");
    }
  };

  // Handle form submission and update product data
  const handleUpdateProduct = (e) => {
    e.preventDefault();

    if (!selectedCategory || !selectedSubcategory) {
      setMessage("Please select a category and subcategory.");
      return;
    }

    // Check if the product exists in the data
    const subcategory = selectedSubcategory;
    const productList = productsData[subcategory] || [];
    const productIndex = productList.findIndex((product) => product.id === productId);

    if (productIndex !== -1) {
      // Update the product data
      productsData[subcategory][productIndex] = {
        id: productId,
        name: name || productsData[subcategory][productIndex].name,
        description: description || productsData[subcategory][productIndex].description,
        price: price || productsData[subcategory][productIndex].price,
        images: images.length ? images : productsData[subcategory][productIndex].images, // Handle multiple images
      };

      setMessage("Product updated successfully!");
    } else {
      setMessage("Product not found!");
    }
  };

  // Filter products based on search term
  const filteredProducts = Object.values(productsData).flat().filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Handle category selection and update subcategory options
  const handleCategoryChange = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);
    setSelectedSubcategory(""); // Reset subcategory when category changes
  };

  // Get subcategories for the selected category
  const subcategories = categories.find(
    (category) => category.name === selectedCategory
  )?.subcategories || [];

  return (
    <div className="admin-products">
      <h2>Update Product Information</h2>

      <div className="search-bar">
        <label>Search Products</label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by product name"
        />
      </div>

      <form onSubmit={handleUpdateProduct}>
        <div>
          <label>Product ID</label>
          <input
            type="text"
            value={productId}
            onChange={(e) => setProductId(e.target.value)}
            placeholder="Enter product ID"
          />
        </div>

        <div>
          <label>Category</label>
          <select
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category.name} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        {selectedCategory && (
          <div>
            <label>Subcategory</label>
            <select
              value={selectedSubcategory}
              onChange={(e) => setSelectedSubcategory(e.target.value)}
            >
              <option value="">Select Subcategory</option>
              {subcategories.map((subcategory) => (
                <option key={subcategory.name} value={subcategory.name}>
                  {subcategory.name}
                </option>
              ))}
            </select>
          </div>
        )}

        <div>
          <label>Name</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter product name"
          />
        </div>

        <div>
          <label>Description</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter product description"
          />
        </div>

        <div>
          <label>Price</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Enter product price"
          />
        </div>

        <div>
          <label>Images</label>
          <input
            type="file"
            multiple
            onChange={handleImageChange}
          />
        </div>

        <button type="submit">Update Product</button>
      </form>

      {message && <p>{message}</p>}



      <h3>Filtered Products:</h3>
      <div className="product-list">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <div key={product.id} className="product-item">
              <p><strong>{product.name}</strong></p>
              <p>{product.description}</p>
              <p>${product.price}</p>
              
              <div className="images">
                {product.images && product.images.map((img, idx) => (
                  <img key={idx} src={URL.createObjectURL(img)} alt={`product-${idx}`} />
                ))}
              </div>
            </div>
          ))
        ) : (
          <p>No products found</p>
        )}
      </div>
    </div>
  );
};

export default AdminProducts;
