import React, { useState, useEffect } from "react";
import "./home.css";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [categories, setCategories] = useState([]);
  const [featuredProducts, setFeaturedProducts] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);

  const navigate = useNavigate();

  // Fetch categories and featured products from backend (simulate for now)
  useEffect(() => {
    const fetchCategories = async () => {
      const fakeCategories = [
        { id: 1, name: "Engine Parts", image: "./engine-parts.jpg", route: "/category/" },
        { id: 2, name: "Tires & Wheels", image: "./tires-wheels.jpg", route: "/tires-and%20wheels/wheels" },
        { id: 3, name: "Hydraulic Parts", image: "./hydraulic.jpg", route: "/category" },
      ];
      setCategories(fakeCategories);
    };

    const fetchFeaturedProducts = async () => {
      const fakeProducts = [
        { id: 1, name: "Filters", price: "Available", image: "./filter.jpg", category: "Hydraulic Parts", route: "/hydraulic-parts/filters" },
        { id: 2, name: "Piston & Rings", price: "Available", image: "./pistonrings.jpg", category: "Engine Parts", route: "/engine-parts/pistons" },
        { id: 3, name: "Gaskets", price: "Available", image: "./gaskets.jpg", category: "Engine Parts", route: "/engine-parts/gaskets" },
      ];
      setFeaturedProducts(fakeProducts);
    };

    fetchCategories();
    fetchFeaturedProducts();
  }, []);

   // Handle category click
   const handleCategoryClick = (categoryRoute) => {
    console.log(`Navigating to category: ${categoryRoute}`);
    navigate(categoryRoute); // Use the route directly from the category object
  };

  // Handle featured product click
  const handleProductClick = (productRoute) => {
    console.log(`Navigating to product: ${productRoute}`);
    navigate(productRoute); // Use the route directly from the product object
  };

  // Handle Shop Now button click
  const handleShopNowClick = () => {
    navigate("/category"); // Adjust the path to match your route setup
  };

  // Function to toggle dropdown
  const toggleDropdown = (id) => {
    setOpenDropdown(openDropdown === id ? null : id);
  };

  // Details for "Why Choose Us?" section
  const reasons = [
    {
      id: 1,
      title: "Wide range of quality spare parts",
      details: "We source and provide a comprehensive selection of high-quality spare parts that ensure your equipment runs smoothly. From the smallest component to large machinery parts, we have you covered with reliable and durable options.",
    },
    {
      id: 2,
      title: "Competitive pricing and discounts",
      details: "Our products are priced competitively to offer you the best value for your investment. We also provide regular discounts and promotions, making it easier for you to access top-tier parts without breaking the bank.",
    },
    {
      id: 3,
      title: "Fast and reliable delivery",
      details: "With our dedicated logistics team and partnerships with trusted couriers, we ensure your orders are delivered swiftly and safely. We offer various delivery options to meet your urgency and budget.",
    },
    {
      id: 4,
      title: "Expert customer support team",
      details: "Our customer support team is trained to handle all inquiries and issues with efficiency and expertise. We are here to answer your questions, provide guidance, and resolve any problems quickly, so you can get back to work with minimal downtime.",
    },
  ];

  return (
    <div className="home-container">
      {/* Top Banner */}
      <div className="banner">
        <div className="banner-content">
          <p className="collection-season">Powering Farms with Quality Parts</p>
          <h1>Boost tractor performance with quality, affordable parts.</h1>
          <button className="shop-now-button" onClick={handleShopNowClick}>
            Shop Now
          </button>
        </div>
      </div>

      {/* Category Sections */}
      <div className="categories">
        {categories.map((category) => (
          <div
            key={category.id}
            className="category"
            onClick={() => handleCategoryClick(category.route)}
          >
            <img src={category.image} alt={category.name} />
            <div className="category-name">{category.name}</div>
          </div>
        ))}
      </div>

      {/* Featured Products Section */}
      <div className="featured-products">
        <h2>Featured Products</h2>
        <div className="product-list">
          {featuredProducts.map((product) => (
            <div
              key={product.id}
              className="product-card"
              onClick={() => handleProductClick(product.route)}
            >
              <img src={product.image} alt={product.name} />
              <div className="product-info">
                <h3>{product.name}</h3>
                <p>{product.price}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Why Choose Us Section */}
      <div className="info-sections">
        <div className="why-choose-us">
          <h2>Why Choose Us?</h2>
          <ul>
            {reasons.map((reason) => (
              <li key={reason.id} className="dropdown-container">
                <div className="dropdown-title" onClick={() => toggleDropdown(reason.id)}>
                  <h3>{reason.title}</h3>
                  <button className="toggle-button">
                    {openDropdown === reason.id ? "▲" : "▼"}
                  </button>
                </div>
                {openDropdown === reason.id && (
                  <div className="dropdown-content">
                    <p>{reason.details}</p>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>

        {/* Customer Reviews */}
        <div className="customer-reviews">
          <h2>Customer Reviews</h2>
          <p>"Benfieg has the best spare parts for my farm equipment. Highly recommended!" – John Doe</p>
          <p>"Fast delivery and exceptional customer service. I will be coming back!" – Sarah K.</p>
        </div>

        {/* Our Partners */}
        <div className="our-partners">
          <h2>Our Partners</h2>
          <p>We are proud partners with industry leaders like:</p>
          <div className="partners-container">
            <div className="partner">
              <img src="./agco-logo.png" alt="AGCO Corporation" className="partner-logo" />
              <span>AGCO Corporation</span>
            </div>
            <div className="partner">
              <img src="./john-deere-logo.png" alt="John Deere" className="partner-logo" />
              <span>John Deere</span>
            </div>
            <div className="partner">
              <img src="./case-ih-logo.png" alt="Case IH" className="partner-logo" />
              <span>Case IH</span>
            </div>
            <div className="partner">
              <img src="./new-holland-logo.png" alt="New Holland Agriculture" className="partner-logo" />
              <span>New Holland Agriculture</span>
            </div>
          </div>
        </div>

        {/* Delivery & Returns Policies */}
        <div className="delivery-returns">
          <h2>Delivery & Returns</h2>
          <p>
            We offer fast and reliable delivery nationwide. If you're not completely satisfied with your purchase, we provide hassle-free returns within 30 days of delivery. However, returns may not be accepted for items that have been used, installed, or damaged during handling. Please ensure the product is in its original condition and packaging for a smooth return process.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
