import React, { useState } from "react";
import "./footer.css";

const Footer = () => {
  // State to track if the footer is open or closed
  const [isOpen, setIsOpen] = useState(false);

  // Toggle footer open/close
  const toggleFooter = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="footer-container">
      <div className="footer-content" style={{ display: isOpen ? "block" : "none" }}>
        <div className="footer-section about-us">
          <h3>About Us</h3>
          <p>
            We are a leading provider of high-quality spare parts for heavy-duty equipment.
            Our mission is to ensure your machinery runs smoothly with reliable and affordable parts.
          </p>
        </div>

        <div className="footer-section quick-links">
          <h3>Quick Links</h3>
          <ul>
            <li><a href="/about">About Us</a></li>
            <li><a href="/category">Products</a></li>
            <li><a href="/contact">Contact</a></li>
            <li><a href="/privacypolicy">Privacy Policy</a></li>
            <li><a href="/termsofservice">Terms of Service</a></li>
          </ul>
        </div>

        <div className="footer-section contact-info">
          <h3>Contact Us</h3>
          <p>
            Email: ceo@benfieg.com.ng
          </p>
          <p>
            Phone: +2348037190683
          </p>
          <div className="social-media">
            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
              <img src="./facebook-icon.png" alt="Facebook" />
            </a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <img src="./twitter-icon.png" alt="Twitter" />
            </a>
            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
              <img src="./linkedin-icon.png" alt="LinkedIn" />
            </a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; 2024 Benfieg. All rights reserved.</p>
      </div>

      {/* Toggle button (^) */}
      <div className="footer-toggle" onClick={toggleFooter}>
        <span>{isOpen ? "▼" : "▲"}</span> {/* Toggle between arrow up and down */}
      </div>
    </div>
  );
};

export default Footer;
