import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <header>
        <h1>Privacy Policy</h1>
      </header>

      <section className="privacy-policy-content">
        <p>Effective date: January 1, 2024</p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to our website. Your privacy is important to us. This Privacy Policy outlines the types of personal information that we collect, how it is used, and your rights regarding your personal data.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          We collect various types of personal information depending on your interaction with our website. This may include:
        </p>
        <div className="info-item">Name and contact information (email, phone number, etc.)</div>
        <div className="info-item">Billing and shipping information (for e-commerce transactions)</div>
        <div className="info-item">Payment information (credit card, bank details)</div>
        <div className="info-item">Account credentials (username, password)</div>
        <div className="info-item">Browser and device information (IP address, operating system, browser type)</div>
        <div className="info-item">Location data (if enabled on your device)</div>
        <div className="info-item">Purchase history and preferences</div>

        <h2>3. How We Use Your Information</h2>
        <p>
          The personal information we collect may be used for the following purposes:
        </p>
        <div className="info-item">To provide customer support and respond to inquiries</div>
        <div className="info-item">To process payments, complete orders, and deliver products or services</div>
        <div className="info-item">To improve our website, products, and services</div>
        <div className="info-item">To send you promotional emails, newsletters, or updates (with your consent)</div>
        <div className="info-item">To analyze website usage, track user behavior, and improve user experience</div>
        <div className="info-item">To comply with legal obligations and protect our rights</div>

        <h2>4. Data Retention</h2>
        <p>
          We retain your personal data only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
        </p>
        <p>
          After this period, your personal data will be securely deleted or anonymized.
        </p>

        <h2>5. Cookies and Tracking Technologies</h2>
        <p>
          Our website uses cookies and other tracking technologies to enhance your experience. These technologies help us to:
        </p>
        <div className="info-item">Remember your preferences and settings</div>
        <div className="info-item">Analyze website traffic and usage patterns</div>
        <div className="info-item">Provide targeted advertisements and marketing communications</div>
        <p>
          You can control cookies through your browser settings. However, disabling cookies may affect certain features of our website.
        </p>

        <h2>6. Data Protection</h2>
        <p>
          We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. These measures include:
        </p>
        <div className="info-item">Encryption of sensitive data during transmission</div>
        <div className="info-item">Access controls and authentication procedures</div>
        <div className="info-item">Regular security audits and monitoring</div>
        <p>
          Despite these efforts, no security measure is completely foolproof, and we cannot guarantee the absolute security of your data.
        </p>

        <h2>7. Sharing Your Information</h2>
        <p>
          We do not sell, rent, or share your personal information with third parties, except in the following circumstances:
        </p>
        <div className="info-item">With service providers who assist us in processing orders, payment transactions, or sending emails</div>
        <div className="info-item">With business partners to provide joint services or promotional offers</div>
        <div className="info-item">With legal authorities if required by law (e.g., to comply with a subpoena or court order)</div>
        <div className="info-item">In the event of a merger, acquisition, or sale of our business, your data may be transferred to the new owner</div>

        <h2>8. Third-Party Links</h2>
        <p>
          Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these external sites. We encourage you to read their privacy policies before providing any personal information.
        </p>

        <h2>9. Your Rights</h2>
        <p>
          You have the following rights regarding your personal information:
        </p>
        <div className="info-item">Access: You can request access to the personal data we hold about you</div>
        <div className="info-item">Correction: You can request that we correct any inaccurate or incomplete data</div>
        <div className="info-item">Deletion: You can request the deletion of your personal data (subject to certain exceptions)</div>
        <div className="info-item">Opt-out: You can opt out of marketing communications or withdraw consent for certain data processing activities</div>
        <div className="info-item">Data Portability: You can request a copy of your personal data in a structured, commonly used format</div>
        <p>
          To exercise your rights, please contact us at <a href="mailto:contact@benfieg.com.ng">our mail</a>.
        </p>

        <h2>10. Changes to This Privacy Policy</h2>
        <p>
          We reserve the right to update this Privacy Policy at any time. Any changes will be posted on this page, and we will update the "Effective date" at the top of the policy. We encourage you to review this policy periodically for any updates.
        </p>

        <h2>11. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or your personal data, please contact us at:
        </p>
        <p>
          Email: <a href="mailto:contact@benfieg.com.ng">contact@benfieg.com.ng</a>
        </p>
        <p>
          Address: A25/4 Agbo-Edo Market, Nkwo Nnewi, Anambra, Nigeria.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
