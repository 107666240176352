import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/home";
import Contact from "./components/contact";
import Form from "./components/form";
import About from "./components/about";
import Shop from "./components/category";
import Catalogue from "./components/catalogue";
import Header from "./components/header";
import Footer from "./components/footer"; // Import Footer component
import PrivacyPolicy from "./components/privacypolicy";
import TermsOfService from "./components/termsofservice";
import Cart from "./components/cart";
import ProductPage from "./components/ProductPage";
import AdminProducts from "./components/AdminProducts";
import Checkout from "./components/checkout";
import Banks from "./components/banks";
import ProductDetails from "./components/productdetails";
import "./App.css";

function App() {
  const [cartItems, setCartItems] = useState([]);

  // Function to add items to the cart
  const addToCart = (product, quantity) => {
    setCartItems((prevCart) => {
      // Check if the product is already in the cart
      const existingItem = prevCart.find((item) => item.id === product.id);
      if (existingItem) {
        // Update quantity if the product exists
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + quantity }
            : item
        );
      } else {
        // Add new product to the cart
        return [...prevCart, { ...product, quantity }];
      }
    });
  };

  // Function to update item quantity
  const updateQuantity = (productId, newQuantity) => {
    setCartItems((prevCart) =>
      prevCart.map((item) =>
        item.id === productId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  // Function to remove an item from the cart
  const removeItem = (productId) => {
    setCartItems((prevCart) => prevCart.filter((item) => item.id !== productId));
  };

  // Function to handle checkout
  const handleCheckout = () => {
    console.log("Checkout clicked. Cart items:", cartItems);
    // Handle checkout logic (e.g., navigate to payment page)
  };

  return (
    <Router>
      <div className="App">
        {/* Header component for navigation */}
        <Header />

        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/form" element={<Form />} />
            <Route path="/category" element={<Shop />} />
            <Route path="/catalogue" element={<Catalogue />} />
            <Route path="/termsofservice" element={<TermsOfService />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/productdetails" element={<ProductDetails />} />
            <Route path="/banks" element={<Banks />} />
            <Route
              path="/cart"
              element={
                <Cart
                  cartItems={cartItems}
                  onUpdateQuantity={updateQuantity}
                  onRemoveItem={removeItem}
                  onCheckout={handleCheckout}
                />
              }
            />
            <Route path="/AdminProducts" element={<AdminProducts />} />
            <Route
              path="/:category/:subcategory"
              element={<ProductPage addToCart={addToCart} />}
            />
            {/* Fallback Route */}
            <Route path="*" element={<div>Page Not Found</div>} />
          </Routes>
        </main>

        {/* Footer component */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
