import React, { useState } from "react";
import "./checkout.css";
import bankDetails from "./banks"; // Import bank details

const Checkout = ({ cartItems = [], onPlaceOrder }) => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    address: "",
    phone: "",
  });
  const [paymentMethod, setPaymentMethod] = useState("creditCard");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handlePaymentChange = (e) => {
    setPaymentMethod(e.target.value);
  };

  const calculateTotal = () => {
    return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);
  };

  const handleOrderSubmit = async () => {
    if (userDetails.name && userDetails.email && userDetails.address && userDetails.phone) {
      setIsLoading(true);

      // Handle order submission logic here (e.g., sending order details to a server)
      setIsLoading(false);
      alert("Order placed successfully!");
    } else {
      alert("Please fill out all the fields before placing the order.");
    }
  };

  return (
    <div className="checkout-container">
      <h2>Checkout</h2>
      <form className="checkout-form">
        <div className="form-section">
          <h3>Personal Information</h3>
          <label>
            Name:
            <input type="text" name="name" value={userDetails.name} onChange={handleInputChange} />
          </label>
          <label>
            Email:
            <input type="email" name="email" value={userDetails.email} onChange={handleInputChange} />
          </label>
          <label>
            Phone:
            <input type="tel" name="phone" value={userDetails.phone} onChange={handleInputChange} />
          </label>
          <label>
            Address:
            <textarea name="address" value={userDetails.address} onChange={handleInputChange}></textarea>
          </label>
        </div>

        <div className="form-section">
          <h3>Payment Method</h3>
          <label>
            <input
              type="radio"
              name="payment"
              value="creditCard"
              checked={paymentMethod === "creditCard"}
              onChange={handlePaymentChange}
            />
            Credit Card(Unavailable)
          </label>
          <label>
            <input
              type="radio"
              name="payment"
              value="bankTransfer"
              checked={paymentMethod === "bankTransfer"}
              onChange={handlePaymentChange}
            />
            Bank Transfer
          </label>
        </div>

        {paymentMethod === "bankTransfer" && (
          <div className="form-section">
            <h3>Bank Details (Pick Any)</h3>
            <div className="bank-details">
              {bankDetails.map((bank, index) => (
                <div key={index} className="bank-card">
                  <p>Bank Name: {bank.bankName}</p>
                  <p>Account Holder: {bank.accountHolder}</p>
                  <p>Account Number: {bank.accountNumber}</p>
                  {bank.sortCode && <p>Sort Code: {bank.sortCode}</p>}
                </div>
              ))}
            </div>
            <button
              type="button"
              className="bank-transfer-button"
              onClick={() => alert("Bank Transfer Confirmation Submitted")}
            >
              Bank Transfer Made
            </button>
          </div>
        )}

        <div className="form-section shipping-fee">
          <h3>Shipping Fee</h3>
          <p>
            Shipping fees depend on the size of the goods and the distance to the delivery address.
            <br />
            Please <a href="tel:+2348037190683">call us</a> to confirm the current shipping fee.
          </p>
        </div>

        <div className="form-section">
          <h3>Order Summary</h3>
          <div className="order-summary">
            {cartItems.map((item) => (
              <div key={item.id} className="order-item">
                <p className="order-item-name">{item.name}</p>
                <p className="order-item-details">
                  {item.quantity} x ${item.price.toFixed(2)}
                </p>
              </div>
            ))}
            <div className="total-amount">
              <h4>Total: ${calculateTotal()}</h4>
            </div>
          </div>
        </div>

        <button
          type="button"
          onClick={handleOrderSubmit}
          className="place-order-button"
          disabled={isLoading}
        >
          {isLoading ? "Placing Order..." : "Place Order"}
        </button>
      </form>
    </div>
  );
};

export default Checkout;
