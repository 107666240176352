import React, { useState, useEffect } from "react";
import catalogueData from "./cataloguedata";
import "./catalogue.css";

const Catalogue = () => {
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(6);
  const [loading, setLoading] = useState(true);

  // Simulate data fetching and apply loading state
  useEffect(() => {
    setTimeout(() => {
      setProducts(catalogueData);
      setLoading(false);
    }, 1000); // Simulated delay
  }, []);

  // Search filter
  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Sort products
  const sortedProducts = [...filteredProducts].sort((a, b) =>
    sortOrder === "asc"
      ? parseFloat(a.price.replace("$", "")) - parseFloat(b.price.replace("$", ""))
      : parseFloat(b.price.replace("$", "")) - parseFloat(a.price.replace("$", ""))
  );

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = sortedProducts.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);

  return (
    <div className="catalogue-wrapper">
      <div className="catalogue-controls">
        {/* Search Bar */}
        <input
          type="text"
          placeholder="Search products..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="catalogue-search"
        />

        {/* Sort Dropdown */}
        <select
          value={sortOrder}
          onChange={(e) => setSortOrder(e.target.value)}
          className="catalogue-sort"
        >
          <option value="asc">Sort by Price: Low to High</option>
          <option value="desc">Sort by Price: High to Low</option>
        </select>
      </div>

      {loading ? (
        <div className="catalogue-loading">Loading...</div>
      ) : (
        <div className="catalogue-container">
          {currentProducts.map((product) => (
            <div className="catalogue-card" key={product.id}>
              <img
                className="catalogue-image"
                src={product.image}
                alt={product.name}
              />
              <h3 className="catalogue-title">{product.name}</h3>
              <p className="catalogue-description">{product.description}</p>
              <span className="catalogue-price">{product.price}</span>
              <a className="catalogue-link" href={product.link}>
                View Details
              </a>
            </div>
          ))}
        </div>
      )}

      {/* Pagination Controls */}
      <div className="catalogue-pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => setCurrentPage(index + 1)}
            className={`pagination-button ${
              currentPage === index + 1 ? "active" : ""
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Catalogue;
