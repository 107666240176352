// productData.js

export const productsData = {
  rings: [
    { 
        id: 1, 
        name: "Piston Ring 026", 
        price: 150, 
        description: "Compression and oil control ring with molybdenum coating, 85-100 mm diameter.", 
        image: "/images/ring026.jpg" 
      },
      { 
        id: 2, 
        name: "Piston Ring 002", 
        price: 130, 
        description: "Plain compression ring for smaller engines, 70-85 mm diameter.", 
        image: "/images/ring002.jpg" 
      },
      { 
        id: 3, 
        name: "Piston Ring 003", 
        price: 160, 
        description: "High-performance barrel-faced compression ring, 100-120 mm diameter.", 
        image: "/images/ring003.jpg" 
      },
      { 
        id: 4, 
        name: "Piston Ring 005", 
        price: 180, 
        description: "Heavy-duty scraper ring for medium to large tractors, 120-150 mm diameter.", 
        image: "/images/ring005.jpg" 
      },
      { 
        id: 5, 
        name: "Piston Ring 007", 
        price: 200, 
        description: "Gas nitrided rings for turbocharged engines, 150-200 mm diameter.", 
        image: "/images/ring007.jpg" 
      },
      { 
        id: 6, 
        name: "Piston Ring 008", 
        price: 120, 
        description: "Plain compression ring for small engines, 65-90 mm diameter.", 
        image: "/images/ring008.jpg" 
      },
      { 
        id: 7, 
        name: "Piston Ring 010", 
        price: 140, 
        description: "Taper-faced compression ring, 90-115 mm diameter.", 
        image: "/images/ring010.jpg" 
      },
      { 
        id: 8, 
        name: "Piston Ring 012", 
        price: 170, 
        description: "Chrome-plated oil control ring for heavy-duty applications, 110-140 mm diameter.", 
        image: "/images/ring012.jpg" 
      },
      { 
        id: 9, 
        name: "Piston Ring 015", 
        price: 190, 
        description: "Barrel-faced compression ring for turbocharged engines, 140-180 mm diameter.", 
        image: "/images/ring015.jpg" 
      },
      { 
        id: 10, 
        name: "Rolls Royce 1300s Ring", 
        price: 220, 
        description: "High-performance molybdenum-coated rings for heavy-duty Rolls Royce 1300s engines, 130-150 mm diameter.", 
        image: "/images/rollsroyce1300s.jpg" 
      },
      { 
        id: 11, 
        name: "Ford Tractor Ring", 
        price: 150, 
        description: "Barrel-faced compression ring for Ford compact and large tractors, 80-135 mm diameter.", 
        image: "/images/fordtractor.jpg" 
      },
      { 
        id: 12, 
        name: "Massey Ferguson Ring", 
        price: 160, 
        description: "Chrome-plated or ductile iron rings for Massey Ferguson models, 85-120 mm diameter.", 
        image: "/images/masseyferguson.jpg" 
      },
      { 
        id: 13, 
        name: "John Deere Tractor Ring", 
        price: 180, 
        description: "Gas nitrided compression rings for John Deere engines, 90-120 mm diameter.", 
        image: "/images/johndeere.jpg" 
      },
      { 
        id: 14, 
        name: "Perkins Engine Ring", 
        price: 170, 
        description: "Moly-coated compression rings for Perkins engines, 80-135 mm diameter.", 
        image: "/images/perkins.jpg" 
      },
      { 
        id: 15, 
        name: "Caterpillar Ring", 
        price: 250, 
        description: "Gas nitrided heavy-duty rings for Caterpillar engines, 130-170 mm diameter.", 
        image: "/images/caterpillar.jpg" 
      },
      { 
        id: 16, 
        name: "Piston Ring 018", 
        price: 140, 
        description: "Flexible scraper ring for older engines, 75-95 mm diameter.", 
        image: "/images/ring018.jpg" 
      },
      { 
        id: 17, 
        name: "Piston Ring 020", 
        price: 200, 
        description: "Advanced multi-piece oil control ring, 120-160 mm diameter.", 
        image: "/images/ring020.jpg" 
      },
      { 
        id: 18, 
        name: "Piston Ring 022", 
        price: 190, 
        description: "High-wear resistance chrome-plated ring, 100-130 mm diameter.", 
        image: "/images/ring022.jpg" 
      },
      { 
        id: 19, 
        name: "International Harvester Ring", 
        price: 175, 
        description: "Ductile iron compression ring for IH tractors, 90-140 mm diameter.", 
        image: "/images/internationalharvester.jpg" 
      },
      { 
        id: 20, 
        name: "New Holland Tractor Ring", 
        price: 185, 
        description: "Chrome and moly-coated rings for New Holland tractors, 85-125 mm diameter.", 
        image: "/images/newholland.jpg" 
      },
      { 
        id: 21, 
        name: "Kubota Tractor Ring", 
        price: 155, 
        description: "Slotted oil control ring for Kubota compact engines, 75-105 mm diameter.", 
        image: "/images/kubota.jpg" 
      },
      { 
        id: 22, 
        name: "Piston Ring 024", 
        price: 130, 
        description: "Economical plain compression ring, 70-100 mm diameter.", 
        image: "/images/ring024.jpg" 
      },
      { 
        id: 23, 
        name: "Piston Ring 027", 
        price: 210, 
        description: "Heavy-duty chrome-plated ring for industrial applications, 150-200 mm diameter.", 
        image: "/images/ring027.jpg" 
      },
      { 
        id: 24, 
        name: "Piston Ring 030", 
        price: 195, 
        description: "Gas nitrided top ring for turbocharged engines, 120-160 mm diameter.", 
        image: "/images/ring030.jpg" 
      },
      { 
        id: 25, 
        name: "Fiat Tractor Ring", 
        price: 170, 
        description: "Durable molybdenum-coated ring for Fiat tractors, 85-120 mm diameter.", 
        image: "/images/fiattractor.jpg" 
      },
      { 
        id: 26, 
        name: "Claas Tractor Ring", 
        price: 200, 
        description: "Specialized oil control ring for Claas engines, 100-130 mm diameter.", 
        image: "/images/claas.jpg" 
      },
      { 
        id: 27, 
        name: "Deutz Tractor Ring", 
        price: 185, 
        description: "High-performance nitrided compression ring for Deutz engines, 110-140 mm diameter.", 
        image: "/images/deutz.jpg" 
      },
      { 
        id: 28, 
        name: "Piston Ring 032", 
        price: 150, 
        description: "Barrel-faced compression ring for medium engines, 80-110 mm diameter.", 
        image: "/images/ring032.jpg" 
      },
      {
        id: 29, 
        name: "Piston Ring 035", 
        price: 230, 
        description: "Advanced turbo-rated moly-coated ring, 140-170 mm diameter.", 
        image: "/images/ring035.jpg" 
      },
      { 
        id: 30, 
        name: "Piston Ring 038", 
        price: 190, 
        description: "Gas nitrided scraper ring for high-pressure applications, 120-150 mm diameter.", 
        image: "/images/ring038.jpg" 
      },
      { 
        id: 31, 
        name: "Piston Ring 040", 
        price: 145, 
        description: "Versatile compression ring for small and medium tractors, 75-95 mm diameter.", 
        image: "/images/ring040.jpg" 
      },
      { 
        id: 32, 
        name: "Piston Ring 042", 
        price: 210, 
        description: "High-durability oil control ring for industrial engines, 100-130 mm diameter.", 
        image: "/images/ring042.jpg" 
      },
      { 
        id: 33, 
        name: "Case IH Ring", 
        price: 190, 
        description: "Heavy-duty molybdenum-coated ring for Case IH tractors, 90-120 mm diameter.", 
        image: "/images/caseih.jpg" 
      },
      { 
        id: 34, 
        name: "Mahindra Tractor Ring", 
        price: 160, 
        description: "Economical ductile iron ring for Mahindra tractors, 85-110 mm diameter.", 
        image: "/images/mahindra.jpg" 
      },
      { 
        id: 35, 
        name: "Piston Ring 045", 
        price: 175, 
        description: "Chrome-plated compression ring for rugged applications, 120-150 mm diameter.", 
        image: "/images/ring045.jpg" 
      },
      { 
        id: 36, 
        name: "Leyland Tractor Ring", 
        price: 180, 
        description: "High-performance ring for Leyland tractors, 95-120 mm diameter.", 
        image: "/images/leyland.jpg" 
      },
      { 
        id: 37, 
        name: "Zetor Tractor Ring", 
        price: 165, 
        description: "Durable scraper and compression ring for Zetor engines, 90-125 mm diameter.", 
        image: "/images/zetor.jpg" 
      },
      { 
        id: 38, 
        name: "Piston Ring 048", 
        price: 190, 
        description: "Advanced turbo-rated nitrided ring, 130-160 mm diameter.", 
        image: "/images/ring048.jpg" 
      },
      { 
        id: 39, 
        name: "Piston Ring 050", 
        price: 220, 
        description: "Premium oil control ring for high-load applications, 140-170 mm diameter.", 
        image: "/images/ring050.jpg" 
      },
      { 
        id: 40, 
        name: "Volvo Penta Ring", 
        price: 250, 
        description: "Marine-grade ring for Volvo Penta engines, 100-135 mm diameter.", 
        image: "/images/volvopenta.jpg" 
      },
      { 
        id: 41, 
        name: "MTZ Belarus Ring", 
        price: 180, 
        description: "Robust ductile iron ring for MTZ Belarus tractors, 85-120 mm diameter.", 
        image: "/images/mtzbelarus.jpg" 
      },
      { 
        id: 42, 
        name: "Piston Ring 055", 
        price: 240, 
        description: "Gas nitrided heavy-duty ring for extreme conditions, 150-200 mm diameter.", 
        image: "/images/ring055.jpg" 
      },
      { 
        id: 43, 
        name: "Claas Axion Ring", 
        price: 200, 
        description: "Turbo-specific compression ring for Claas Axion tractors, 100-140 mm diameter.", 
        image: "/images/claasaxion.jpg" 
      },
      { 
        id: 44, 
        name: "John Deere 6020 Series Ring", 
        price: 195, 
        description: "High-performance nitrided ring for John Deere 6020 series, 95-130 mm diameter.", 
        image: "/images/johndeere6020.jpg" 
      },
      { 
        id: 45, 
        name: "Fendt Tractor Ring", 
        price: 215, 
        description: "Durable moly-coated ring for Fendt engines, 110-145 mm diameter.", 
        image: "/images/fendt.jpg" 
      },
      { 
        id: 46, 
        name: "Piston Ring 060", 
        price: 155, 
        description: "Economical chrome-plated compression ring for older tractors, 80-100 mm diameter.", 
        image: "/images/ring060.jpg" 
      },
      { 
        id: 47, 
        name: "Landini Tractor Ring", 
        price: 185, 
        description: "High-quality compression ring for Landini models, 85-115 mm diameter.", 
        image: "/images/landini.jpg" 
      },
      { 
        id: 48, 
        name: "David Brown Tractor Ring", 
        price: 170, 
        description: "Flexible scraper ring for vintage David Brown tractors, 75-95 mm diameter.", 
        image: "/images/davidbrown.jpg" 
      },
      { 
        id: 49, 
        name: "Renault Tractor Ring", 
        price: 180, 
        description: "Long-lasting compression ring for Renault models, 90-120 mm diameter.", 
        image: "/images/renaulttractor.jpg" 
      },
      { 
        id: 50, 
        name: "Piston Ring 065", 
        price: 260, 
        description: "Specialized nitrided turbo ring for heavy-duty engines, 160-200 mm diameter.", 
        image: "/images/ring065.jpg" 
      },
      { 
        id: 51, 
        name: "Massey Ferguson 135 Ring", 
        price: 195, 
        description: "Premium piston ring set for Massey Ferguson 135, 90-120 mm diameter.", 
        image: "/images/massey135.jpg" 
      },
      { 
        id: 52, 
        name: "Ford 5000 Ring", 
        price: 210, 
        description: "Durable oil control ring for Ford 5000 series tractors, 100-140 mm diameter.", 
        image: "/images/ford5000.jpg" 
      },
      { 
        id: 53, 
        name: "Rolls Royce 1300S Ring", 
        price: 350, 
        description: "High-performance compression ring for Rolls Royce engines, 120-160 mm diameter.", 
        image: "/images/rollsroyce1300s.jpg" 
      },
      { 
        id: 54, 
        name: "Valtra Tractor Ring", 
        price: 200, 
        description: "Heavy-duty molybdenum-coated ring for Valtra engines, 110-145 mm diameter.", 
        image: "/images/valtra.jpg" 
      },
      { 
        id: 55, 
        name: "Same Tractor Ring", 
        price: 175, 
        description: "Economical scraper ring for Same tractors, 85-115 mm diameter.", 
        image: "/images/sametractor.jpg" 
      },
      { 
        id: 56, 
        name: "John Deere 4020 Ring", 
        price: 230, 
        description: "Turbo-rated nitrided ring for John Deere 4020 series, 110-145 mm diameter.", 
        image: "/images/johndeere4020.jpg" 
      },
      { 
        id: 57, 
        name: "Fiat 640 Ring", 
        price: 180, 
        description: "Durable compression ring for Fiat 640 tractors, 90-125 mm diameter.", 
        image: "/images/fiat640.jpg" 
      },
      { 
        id: 58, 
        name: "Piston Ring 070", 
        price: 270, 
        description: "Specialized turbo oil control ring for heavy engines, 160-200 mm diameter.", 
        image: "/images/ring070.jpg" 
      },
      { 
        id: 59, 
        name: "International 444 Ring", 
        price: 200, 
        description: "High-performance nitrided ring for International 444, 85-120 mm diameter.", 
        image: "/images/international444.jpg" 
      },
      { 
        id: 60, 
        name: "Kubota B2601 Ring", 
        price: 160, 
        description: "Flexible scraper ring for compact Kubota B2601 tractors, 75-95 mm diameter.", 
        image: "/images/kubotab2601.jpg" 
      },
      { 
        id: 61, 
        name: "Deutz-Fahr Agrotron Ring", 
        price: 240, 
        description: "Turbocharged compression ring for Deutz-Fahr Agrotron engines, 120-160 mm diameter.", 
        image: "/images/deutzagrotron.jpg" 
      },
      { 
        id: 62, 
        name: "McCormick X7 Ring", 
        price: 230, 
        description: "Advanced molybdenum-coated ring for McCormick X7 tractors, 110-140 mm diameter.", 
        image: "/images/mccormickx7.jpg" 
      },
      { 
        id: 63, 
        name: "Piston Ring 075", 
        price: 195, 
        description: "Barrel-faced compression ring for mid-sized engines, 85-110 mm diameter.", 
        image: "/images/ring075.jpg" 
      },
      { 
        id: 64, 
        name: "Piston Ring 080", 
        price: 250, 
        description: "Chrome-plated ring for high-pressure industrial applications, 140-170 mm diameter.", 
        image: "/images/ring080.jpg" 
      },
      { 
        id: 65, 
        name: "Ford 7600 Ring", 
        price: 220, 
        description: "High-durability oil control ring for Ford 7600 tractors, 100-140 mm diameter.", 
        image: "/images/ford7600.jpg" 
      },
      { 
        id: 66, 
        name: "Mitsubishi MT180 Ring", 
        price: 170, 
        description: "Economical piston ring for Mitsubishi compact tractors, 75-95 mm diameter.", 
        image: "/images/mitsubishimt180.jpg" 
      },
      { 
        id: 67, 
        name: "Piston Ring 085", 
        price: 200, 
        description: "Heavy-duty nitrided scraper ring for rugged applications, 120-160 mm diameter.", 
        image: "/images/ring085.jpg" 
      },
      { 
        id: 68, 
        name: "David Brown 885 Ring", 
        price: 180, 
        description: "Flexible compression ring for David Brown 885 tractors, 85-110 mm diameter.", 
        image: "/images/davidbrown885.jpg" 
      },
      { 
        id: 69, 
        name: "Renault Ares Ring", 
        price: 190, 
        description: "High-performance oil control ring for Renault Ares models, 90-120 mm diameter.", 
        image: "/images/renaultares.jpg" 
      },
      { 
        id: 70, 
        name: "Fendt 800 Vario Ring", 
        price: 260, 
        description: "Turbo-specific nitrided ring for Fendt 800 Vario series, 140-170 mm diameter.", 
        image: "/images/fendtvario.jpg" 
      },
      { 
        id: 71, 
        name: "Case IH Magnum Ring", 
        price: 310, 
        description: "High-performance ring for Case IH Magnum series tractors, 150-200 mm diameter.", 
        image: "/images/caseihmagnum.jpg" 
      },
      { 
        id: 72, 
        name: "JCB Fastrac Ring", 
        price: 270, 
        description: "Durable compression ring for JCB Fastrac tractors, 120-160 mm diameter.", 
        image: "/images/jcbfastrac.jpg" 
      },
      { 
        id: 73, 
        name: "SAME Explorer Ring", 
        price: 220, 
        description: "Compression ring for SAME Explorer series, suitable for 100-130 mm diameter engines.", 
        image: "/images/sameexplorer.jpg" 
      },
      { 
        id: 74, 
        name: "Zetor 7211 Ring", 
        price: 185, 
        description: "Standard piston ring for Zetor 7211, 95-120 mm diameter.", 
        image: "/images/zetor7211.jpg" 
      },
      { 
        id: 75, 
        name: "Lamborghini Tractor Ring", 
        price: 250, 
        description: "High-durability piston ring for Lamborghini tractors, 110-140 mm diameter.", 
        image: "/images/lamborghini.jpg" 
      },
      { 
        id: 76, 
        name: "New Holland T6 Ring", 
        price: 270, 
        description: "Advanced oil control ring for New Holland T6 series, 130-170 mm diameter.", 
        image: "/images/newhollandt6.jpg" 
      },
      { 
        id: 77, 
        name: "Alpina Tractor Ring", 
        price: 190, 
        description: "Standard piston ring for Alpina tractors, 90-115 mm diameter.", 
        image: "/images/alpina.jpg" 
      },
      { 
        id: 78, 
        name: "Renault 70-14 Ring", 
        price: 180, 
        description: "Reliable oil control ring for Renault 70-14 series, 85-110 mm diameter.", 
        image: "/images/renault7014.jpg" 
      },
      { 
        id: 79, 
        name: "Fendt 300 Vario Ring", 
        price: 280, 
        description: "Premium piston ring for Fendt 300 Vario tractors, 120-150 mm diameter.", 
        image: "/images/fendt300vario.jpg" 
      },
      { 
        id: 80, 
        name: "Belarus 82 Ring", 
        price: 170, 
        description: "Durable compression ring for Belarus 82 series, 95-125 mm diameter.", 
        image: "/images/belarus82.jpg" 
      },
      { 
        id: 81, 
        name: "MTZ 1025 Ring", 
        price: 200, 
        description: "High-quality piston ring for MTZ 1025, 100-130 mm diameter.", 
        image: "/images/mtz1025.jpg" 
      },
      { 
        id: 82, 
        name: "Massey Ferguson 6480 Ring", 
        price: 260, 
        description: "Performance ring for Massey Ferguson 6480 tractors, 110-150 mm diameter.", 
        image: "/images/massey6480.jpg" 
      },
      { 
        id: 83, 
        name: "John Deere 7800 Ring", 
        price: 230, 
        description: "High-quality oil control ring for John Deere 7800, 120-160 mm diameter.", 
        image: "/images/johndeere7800.jpg" 
      },
      { 
        id: 84, 
        name: "Fiat 110-90 Ring", 
        price: 180, 
        description: "Standard compression ring for Fiat 110-90 series, 100-130 mm diameter.", 
        image: "/images/fiat11090.jpg" 
      },
      { 
        id: 85, 
        name: "Volvo BM Tractor Ring", 
        price: 215, 
        description: "High-durability ring for Volvo BM tractors, 110-140 mm diameter.", 
        image: "/images/volvobm.jpg" 
      },
      { 
        id: 86, 
        name: "Mitsubishi MT220 Ring", 
        price: 185, 
        description: "Affordable piston ring for Mitsubishi MT220, 90-120 mm diameter.", 
        image: "/images/mitsubishimt220.jpg" 
      },
      { 
        id: 87, 
        name: "Massey Ferguson 8250 Ring", 
        price: 250, 
        description: "Performance compression ring for Massey Ferguson 8250, 120-150 mm diameter.", 
        image: "/images/massey8250.jpg" 
      },
      { 
        id: 88, 
        name: "Case IH Puma Ring", 
        price: 290, 
        description: "Advanced piston ring for Case IH Puma tractors, 130-170 mm diameter.", 
        image: "/images/caseihpuma.jpg" 
      },
      { 
        id: 89, 
        name: "New Holland T7 Ring", 
        price: 310, 
        description: "High-performance oil control ring for New Holland T7 series, 140-180 mm diameter.", 
        image: "/images/newhollandt7.jpg" 
      },
      { 
        id: 90, 
        name: "Landini Powerfarm Ring", 
        price: 210, 
        description: "Reliable piston ring for Landini Powerfarm tractors, 100-130 mm diameter.", 
        image: "/images/landinipowerfarm.jpg" 
      },
      { 
    id: 71, 
    name: "Case IH Magnum Ring", 
    price: 310, 
    description: "High-performance ring for Case IH Magnum series tractors, 150-200 mm diameter.", 
    image: "/images/caseihmagnum.jpg" 
  },
  { 
    id: 72, 
    name: "JCB Fastrac Ring", 
    price: 270, 
    description: "Durable compression ring for JCB Fastrac tractors, 120-160 mm diameter.", 
    image: "/images/jcbfastrac.jpg" 
  },
  { 
    id: 73, 
    name: "SAME Explorer Ring", 
    price: 220, 
    description: "Compression ring for SAME Explorer series, suitable for 100-130 mm diameter engines.", 
    image: "/images/sameexplorer.jpg" 
  },
  { 
    id: 74, 
    name: "Zetor 7211 Ring", 
    price: 185, 
    description: "Standard piston ring for Zetor 7211, 95-120 mm diameter.", 
    image: "/images/zetor7211.jpg" 
  },
  { 
    id: 75, 
    name: "Lamborghini Tractor Ring", 
    price: 250, 
    description: "High-durability piston ring for Lamborghini tractors, 110-140 mm diameter.", 
    image: "/images/lamborghini.jpg" 
  },
  { 
    id: 76, 
    name: "New Holland T6 Ring", 
    price: 270, 
    description: "Advanced oil control ring for New Holland T6 series, 130-170 mm diameter.", 
    image: "/images/newhollandt6.jpg" 
  },
  { 
    id: 77, 
    name: "Alpina Tractor Ring", 
    price: 190, 
    description: "Standard piston ring for Alpina tractors, 90-115 mm diameter.", 
    image: "/images/alpina.jpg" 
  },
  { 
    id: 78, 
    name: "Renault 70-14 Ring", 
    price: 180, 
    description: "Reliable oil control ring for Renault 70-14 series, 85-110 mm diameter.", 
    image: "/images/renault7014.jpg" 
  },
  { 
    id: 79, 
    name: "Fendt 300 Vario Ring", 
    price: 280, 
    description: "Premium piston ring for Fendt 300 Vario tractors, 120-150 mm diameter.", 
    image: "/images/fendt300vario.jpg" 
  },
  { 
    id: 80, 
    name: "Belarus 82 Ring", 
    price: 170, 
    description: "Durable compression ring for Belarus 82 series, 95-125 mm diameter.", 
    image: "/images/belarus82.jpg" 
  },
  { 
    id: 81, 
    name: "MTZ 1025 Ring", 
    price: 200, 
    description: "High-quality piston ring for MTZ 1025, 100-130 mm diameter.", 
    image: "/images/mtz1025.jpg" 
  },
  { 
    id: 82, 
    name: "Massey Ferguson 6480 Ring", 
    price: 260, 
    description: "Performance ring for Massey Ferguson 6480 tractors, 110-150 mm diameter.", 
    image: "/images/massey6480.jpg" 
  },
  { 
    id: 83, 
    name: "John Deere 7800 Ring", 
    price: 230, 
    description: "High-quality oil control ring for John Deere 7800, 120-160 mm diameter.", 
    image: "/images/johndeere7800.jpg" 
  },
  { 
    id: 84, 
    name: "Fiat 110-90 Ring", 
    price: 180, 
    description: "Standard compression ring for Fiat 110-90 series, 100-130 mm diameter.", 
    image: "/images/fiat11090.jpg" 
  },
  { 
    id: 85, 
    name: "Volvo BM Tractor Ring", 
    price: 215, 
    description: "High-durability ring for Volvo BM tractors, 110-140 mm diameter.", 
    image: "/images/volvobm.jpg" 
  },
  { 
    id: 86, 
    name: "Mitsubishi MT220 Ring", 
    price: 185, 
    description: "Affordable piston ring for Mitsubishi MT220, 90-120 mm diameter.", 
    image: "/images/mitsubishimt220.jpg" 
  },
  { 
    id: 87, 
    name: "Massey Ferguson 8250 Ring", 
    price: 250, 
    description: "Performance compression ring for Massey Ferguson 8250, 120-150 mm diameter.", 
    image: "/images/massey8250.jpg" 
  },
  { 
    id: 88, 
    name: "Case IH Puma Ring", 
    price: 290, 
    description: "Advanced piston ring for Case IH Puma tractors, 130-170 mm diameter.", 
    image: "/images/caseihpuma.jpg" 
  },
  { 
    id: 89, 
    name: "New Holland T7 Ring", 
    price: 310, 
    description: "High-performance oil control ring for New Holland T7 series, 140-180 mm diameter.", 
    image: "/images/newhollandt7.jpg" 
  },
  { 
    id: 90, 
    name: "Landini Powerfarm Ring", 
    price: 210, 
    description: "Reliable piston ring for Landini Powerfarm tractors, 100-130 mm diameter.", 
    image: "/images/landinipowerfarm.jpg" 
  },
  { 
    id: 91, 
    name: "Kubota M7 Ring", 
    price: 230, 
    description: "High-quality piston ring for Kubota M7 series, 120-150 mm diameter.", 
    image: "/images/kubotam7.jpg" 
  },
  { 
    id: 92, 
    name: "Deutz-Fahr Agrotron Ring", 
    price: 250, 
    description: "Durable piston ring for Deutz-Fahr Agrotron series, 110-140 mm diameter.", 
    image: "/images/deutzfahragrotron.jpg" 
  },
  { 
    id: 93, 
    name: "Fiat 130-90 Ring", 
    price: 180, 
    description: "Standard compression ring for Fiat 130-90 tractors, 95-120 mm diameter.", 
    image: "/images/fiat13090.jpg" 
  },
  { 
    id: 94, 
    name: "SDF 6185.4 Ring", 
    price: 260, 
    description: "High-performance piston ring for SDF 6185.4, 110-150 mm diameter.", 
    image: "/images/sdf61854.jpg" 
  },
  { 
    id: 95, 
    name: "Belarus 820 Ring", 
    price: 175, 
    description: "Reliable piston ring for Belarus 820 series, 85-110 mm diameter.", 
    image: "/images/belarus820.jpg" 
  },
  { 
    id: 96, 
    name: "Fendt 700 Vario Ring", 
    price: 300, 
    description: "High-durability oil control ring for Fendt 700 Vario series, 130-170 mm diameter.", 
    image: "/images/fendt700vario.jpg" 
  },
  { 
    id: 97, 
    name: "John Deere 9600 Ring", 
    price: 220, 
    description: "Standard compression ring for John Deere 9600, 100-130 mm diameter.", 
    image: "/images/johndeere9600.jpg" 
  },
  { 
    id: 98, 
    name: "Zetor 6245 Ring", 
    price: 200, 
    description: "Performance piston ring for Zetor 6245 series, 100-130 mm diameter.", 
    image: "/images/zetor6245.jpg" 
  },
  { 
    id: 99, 
    name: "Massey Ferguson 5400 Ring", 
    price: 240, 
    description: "Compression ring for Massey Ferguson 5400 series, 110-140 mm diameter.", 
    image: "/images/massey5400.jpg" 
  },
  { 
    id: 100, 
    name: "New Holland T8 Ring", 
    price: 320, 
    description: "High-quality oil control ring for New Holland T8 series, 150-200 mm diameter.", 
    image: "/images/newhollandt8.jpg" 
  },
  { 
    id: 101, 
    name: "Volvo BM 4350 Ring", 
    price: 210, 
    description: "Durable piston ring for Volvo BM 4350 tractors, 100-130 mm diameter.", 
    image: "/images/volvobm4350.jpg" 
  },
  { 
    id: 102, 
    name: "Lamborghini R6 Ring", 
    price: 230, 
    description: "Reliable piston ring for Lamborghini R6 series, 110-140 mm diameter.", 
    image: "/images/lamborghinir6.jpg" 
  },
  { 
    id: 103, 
    name: "Mitsubishi MT200 Ring", 
    price: 195, 
    description: "Standard compression ring for Mitsubishi MT200, 90-120 mm diameter.", 
    image: "/images/mitsubishimt200.jpg" 
  },
  { 
    id: 104, 
    name: "SAME Iron 140 Ring", 
    price: 220, 
    description: "Compression ring for SAME Iron 140 series, 130-160 mm diameter.", 
    image: "/images/sameiron140.jpg" 
  },
  { 
    id: 105, 
    name: "John Deere 8700 Ring", 
    price: 250, 
    description: "High-quality compression ring for John Deere 8700 series, 130-160 mm diameter.", 
    image: "/images/johndeere8700.jpg" 
  },
  { 
    id: 106, 
    name: "Fiat 100-90 Ring", 
    price: 170, 
    description: "Standard piston ring for Fiat 100-90, 90-110 mm diameter.", 
    image: "/images/fiat10090.jpg" 
  },
  { 
    id: 107, 
    name: "Landini Rex 80 Ring", 
    price: 200, 
    description: "Compression ring for Landini Rex 80 series, 100-130 mm diameter.", 
    image: "/images/landinirex80.jpg" 
  },
  { 
    id: 108, 
    name: "Deutz-Fahr 7250 Ring", 
    price: 270, 
    description: "High-performance piston ring for Deutz-Fahr 7250 series, 120-150 mm diameter.", 
    image: "/images/deutzfahr7250.jpg" 
  }

  ],
  pistons: [
    { 
        id: 1, 
        name: "Kubota M7 Piston", 
        price: 350, 
        description: "High-performance piston for Kubota M7 series, suitable for 120-150 mm bore size.", 
        image: "/images/kubotam7piston.jpg" 
      },
      { 
        id: 2, 
        name: "Deutz-Fahr Agrotron Piston", 
        price: 400, 
        description: "Durable piston for Deutz-Fahr Agrotron series, 110-140 mm bore size.", 
        image: "/images/deutzfahragrotronpiston.jpg" 
      },
      { 
        id: 3, 
        name: "Fiat 130-90 Piston", 
        price: 300, 
        description: "Compression piston for Fiat 130-90 tractors, designed for 95-120 mm bore size.", 
        image: "/images/fiat13090piston.jpg" 
      },
      { 
        id: 4, 
        name: "SDF 6185.4 Piston", 
        price: 420, 
        description: "Performance piston for SDF 6185.4 series, 110-150 mm bore size.", 
        image: "/images/sdf61854piston.jpg" 
      },
      { 
        id: 5, 
        name: "Belarus 820 Piston", 
        price: 290, 
        description: "Reliable piston for Belarus 820 series, 85-110 mm bore size.", 
        image: "/images/belarus820piston.jpg" 
      },
      { 
        id: 6, 
        name: "Fendt 700 Vario Piston", 
        price: 450, 
        description: "High-durability piston for Fendt 700 Vario series, designed for 130-170 mm bore size.", 
        image: "/images/fendt700variopiston.jpg" 
      },
      { 
        id: 7, 
        name: "John Deere 9600 Piston", 
        price: 320, 
        description: "Compression piston for John Deere 9600 series, 100-130 mm bore size.", 
        image: "/images/johndeere9600piston.jpg" 
      },
      { 
        id: 8, 
        name: "Zetor 6245 Piston", 
        price: 310, 
        description: "High-performance piston for Zetor 6245 series, 100-130 mm bore size.", 
        image: "/images/zetor6245piston.jpg" 
      },
      { 
        id: 9, 
        name: "Massey Ferguson 5400 Piston", 
        price: 340, 
        description: "Compression piston for Massey Ferguson 5400 series, 110-140 mm bore size.", 
        image: "/images/massey5400piston.jpg" 
      },
      { 
        id: 10, 
        name: "New Holland T8 Piston", 
        price: 500, 
        description: "High-quality piston for New Holland T8 series, 150-200 mm bore size.", 
        image: "/images/newhollandt8piston.jpg" 
      },
      { 
        id: 11, 
        name: "Volvo BM 4350 Piston", 
        price: 330, 
        description: "Durable piston for Volvo BM 4350 tractors, 100-130 mm bore size.", 
        image: "/images/volvobm4350piston.jpg" 
      },
      { 
        id: 12, 
        name: "Lamborghini R6 Piston", 
        price: 360, 
        description: "High-performance piston for Lamborghini R6 series, 110-140 mm bore size.", 
        image: "/images/lamborghinir6piston.jpg" 
      },
      { 
        id: 13, 
        name: "Mitsubishi MT200 Piston", 
        price: 270, 
        description: "Standard compression piston for Mitsubishi MT200, 90-120 mm bore size.", 
        image: "/images/mitsubishimt200piston.jpg" 
      },
      { 
        id: 14, 
        name: "SAME Iron 140 Piston", 
        price: 320, 
        description: "High-quality piston for SAME Iron 140 series, 130-160 mm bore size.", 
        image: "/images/sameiron140piston.jpg" 
      },
      { 
        id: 15, 
        name: "John Deere 8700 Piston", 
        price: 380, 
        description: "Compression piston for John Deere 8700 series, 130-160 mm bore size.", 
        image: "/images/johndeere8700piston.jpg" 
      },
      { 
        id: 16, 
        name: "Fiat 100-90 Piston", 
        price: 280, 
        description: "Standard piston for Fiat 100-90 series, 90-110 mm bore size.", 
        image: "/images/fiat10090piston.jpg" 
      },
      { 
        id: 17, 
        name: "Landini Rex 80 Piston", 
        price: 310, 
        description: "Compression piston for Landini Rex 80 series, 100-130 mm bore size.", 
        image: "/images/landinirex80piston.jpg" 
      },
      { 
        id: 18, 
        name: "Deutz-Fahr 7250 Piston", 
        price: 450, 
        description: "High-performance piston for Deutz-Fahr 7250 series, 120-150 mm bore size.", 
        image: "/images/deutzfahr7250piston.jpg" 
      },
      { 
        id: 19, 
        name: "Case IH Magnum 340 Piston", 
        price: 550, 
        description: "High-durability piston for Case IH Magnum 340 series, 140-180 mm bore size.", 
        image: "/images/caseihmagnum340piston.jpg" 
      },
      { 
        id: 20, 
        name: "Claas Xerion 5000 Piston", 
        price: 600, 
        description: "High-performance piston for Claas Xerion 5000 series, 160-200 mm bore size.", 
        image: "/images/claasxerion5000piston.jpg" 
      },
      { 
        id: 21, 
        name: "Allis-Chalmers 185 Piston", 
        price: 290, 
        description: "Reliable piston for Allis-Chalmers 185 series, 90-110 mm bore size.", 
        image: "/images/allischalmers185piston.jpg" 
      },
      { 
        id: 22, 
        name: "Mahindra 575 DI Piston", 
        price: 250, 
        description: "Standard piston for Mahindra 575 DI series, 85-105 mm bore size.", 
        image: "/images/mahindra575dipiston.jpg" 
      },
      { 
        id: 23, 
        name: "Ford 7710 Piston", 
        price: 320, 
        description: "Durable piston for Ford 7710 series, 100-130 mm bore size.", 
        image: "/images/ford7710piston.jpg" 
      },
      { 
        id: 24, 
        name: "Ford 1300 Piston", 
        price: 270, 
        description: "Compression piston for Ford 1300 series, 80-100 mm bore size.", 
        image: "/images/ford1300piston.jpg" 
      },
      { 
        id: 25, 
        name: "Lamborghini R8 Piston", 
        price: 370, 
        description: "High-performance piston for Lamborghini R8 series, 120-150 mm bore size.", 
        image: "/images/lamborghinir8piston.jpg" 
      },
      { 
        id: 26, 
        name: "Mercedes-Benz Unimog U500 Piston", 
        price: 480, 
        description: "High-durability piston for Mercedes-Benz Unimog U500, 130-170 mm bore size.", 
        image: "/images/mercedesbenzunimogu500piston.jpg" 
      },
      { 
        id: 27, 
        name: "Zetor Proxima 80 Piston", 
        price: 310, 
        description: "Compression piston for Zetor Proxima 80 series, 95-125 mm bore size.", 
        image: "/images/zetorproxima80piston.jpg" 
      },
      { 
        id: 28, 
        name: "Case IH Puma 185 Piston", 
        price: 520, 
        description: "High-performance piston for Case IH Puma 185 series, 140-180 mm bore size.", 
        image: "/images/caseihpuma185piston.jpg" 
      },
      { 
        id: 29, 
        name: "Fiat 180-90 Piston", 
        price: 310, 
        description: "Standard piston for Fiat 180-90 series, 110-140 mm bore size.", 
        image: "/images/fiat18090piston.jpg" 
      },
      { 
        id: 30, 
        name: "SAME Dorado Piston", 
        price: 350, 
        description: "Durable piston for SAME Dorado series, 100-130 mm bore size.", 
        image: "/images/samedoradopiston.jpg" 
      },
      { 
        id: 31, 
        name: "Massey Ferguson 7600 Piston", 
        price: 430, 
        description: "High-performance piston for Massey Ferguson 7600 series, 130-160 mm bore size.", 
        image: "/images/massey7600piston.jpg" 
      },
      { 
        id: 32, 
        name: "John Deere 7R Piston", 
        price: 550, 
        description: "High-quality piston for John Deere 7R series, 140-180 mm bore size.", 
        image: "/images/johndeere7rpiston.jpg" 
      },
      { 
        id: 33, 
        name: "Zetor 4340 Piston", 
        price: 300, 
        description: "Compression piston for Zetor 4340 series, 95-120 mm bore size.", 
        image: "/images/zetor4340piston.jpg" 
      },
      { 
        id: 34, 
        name: "Tafe 35 DI Piston", 
        price: 260, 
        description: "Reliable piston for Tafe 35 DI series, 85-105 mm bore size.", 
        image: "/images/tafe35dipiston.jpg" 
      },
      { 
        id: 35, 
        name: "New Holland T7 Piston", 
        price: 500, 
        description: "High-durability piston for New Holland T7 series, 130-160 mm bore size.", 
        image: "/images/newhollandt7piston.jpg" 
      },
      { 
        id: 36, 
        name: "Kubota M110X Piston", 
        price: 270, 
        description: "Standard piston for Kubota M110X series, 100-130 mm bore size.", 
        image: "/images/kubotam110xpiston.jpg" 
      },
      { 
        id: 37, 
        name: "SDF 130 Piston", 
        price: 310, 
        description: "Reliable piston for SDF 130 series, 90-110 mm bore size.", 
        image: "/images/sdf130piston.jpg" 
      },
      { 
        id: 38, 
        name: "Caterpillar 428 Piston", 
        price: 400, 
        description: "Durable piston for Caterpillar 428 series, 120-150 mm bore size.", 
        image: "/images/caterpillar428piston.jpg" 
      },
      { 
        id: 39, 
        name: "International Harvester 3588 Piston", 
        price: 350, 
        description: "Compression piston for International Harvester 3588 series, 110-140 mm bore size.", 
        image: "/images/ih3588piston.jpg" 
      },
      { 
        id: 40, 
        name: "JCB Fastrac 8310 Piston", 
        price: 490, 
        description: "High-performance piston for JCB Fastrac 8310 series, 130-170 mm bore size.", 
        image: "/images/jcb8310piston.jpg" 
      },
      { 
        id: 41, 
        name: "Valtra T Series Piston", 
        price: 450, 
        description: "Durable piston for Valtra T Series, 140-180 mm bore size.", 
        image: "/images/valtratseriespiston.jpg" 
      },
      { 
        id: 42, 
        name: "Mitsubishi MT Series Piston", 
        price: 260, 
        description: "Reliable piston for Mitsubishi MT series, 80-100 mm bore size.", 
        image: "/images/mitsubishimtseriespiston.jpg" 
      },
      { 
        id: 43, 
        name: "John Deere 6M Piston", 
        price: 420, 
        description: "High-durability piston for John Deere 6M series, 120-150 mm bore size.", 
        image: "/images/johndeere6mpiston.jpg" 
      },
      { 
        id: 44, 
        name: "Deutz-Fahr 6210 Piston", 
        price: 460, 
        description: "High-performance piston for Deutz-Fahr 6210 series, 140-170 mm bore size.", 
        image: "/images/deutzfahr6210piston.jpg" 
      },
      { 
        id: 45, 
        name: "Case IH Steiger 500 Piston", 
        price: 570, 
        description: "Compression piston for Case IH Steiger 500 series, 150-200 mm bore size.", 
        image: "/images/caseihsteiger500piston.jpg" 
      },
      { 
        id: 46, 
        name: "Fendt 1050 Vario Piston", 
        price: 620, 
        description: "High-durability piston for Fendt 1050 Vario series, 140-180 mm bore size.", 
        image: "/images/fendt1050variopiston.jpg" 
      },
      { 
        id: 47, 
        name: "Same Explorer Piston", 
        price: 280, 
        description: "Standard piston for Same Explorer series, 95-125 mm bore size.", 
        image: "/images/sameexplorerpiston.jpg" 
      },
      { 
        id: 48, 
        name: "SAME Silver Piston", 
        price: 300, 
        description: "Reliable piston for SAME Silver series, 100-130 mm bore size.", 
        image: "/images/samesilverpiston.jpg" 
      },
      { 
        id: 49, 
        name: "Tractor International 1056XL Piston", 
        price: 410, 
        description: "Compression piston for Tractor International 1056XL series, 110-140 mm bore size.", 
        image: "/images/tractor1056xlpiston.jpg" 
      },
      { 
        id: 50, 
        name: "Trekker 2800 Piston", 
        price: 320, 
        description: "Durable piston for Trekker 2800 series, 100-130 mm bore size.", 
        image: "/images/trekker2800piston.jpg" 
      }
  ],
  cylinder: [
    { 
        id: 1, 
        name: "Case IH Magnum 340 Cylinder", 
        price: 700, 
        description: "High-performance hydraulic cylinder for Case IH Magnum 340 series, designed for heavy-duty operations.", 
        image: "/images/caseihmagnum340cylinder.jpg" 
      },
      { 
        id: 2, 
        name: "Claas Xerion 5000 Cylinder", 
        price: 750, 
        description: "Durable cylinder for Claas Xerion 5000 series, optimized for high-pressure hydraulic systems.", 
        image: "/images/claasxerion5000cylinder.jpg" 
      },
      { 
        id: 3, 
        name: "John Deere 7R Cylinder", 
        price: 680, 
        description: "High-durability hydraulic cylinder for John Deere 7R series, suitable for agricultural and industrial use.", 
        image: "/images/johndeere7rcylinder.jpg" 
      },
      { 
        id: 4, 
        name: "Ford 7710 Cylinder", 
        price: 600, 
        description: "Reliable hydraulic cylinder for Ford 7710 series, engineered for strength and long-lasting performance.", 
        image: "/images/ford7710cylinder.jpg" 
      },
      { 
        id: 5, 
        name: "Massey Ferguson 7600 Cylinder", 
        price: 720, 
        description: "Durable hydraulic cylinder for Massey Ferguson 7600 series, known for high-pressure and heavy-duty tasks.", 
        image: "/images/massey7600cylinder.jpg" 
      },
      { 
        id: 6, 
        name: "New Holland T7 Cylinder", 
        price: 650, 
        description: "Strong hydraulic cylinder for New Holland T7 series, suitable for robust agricultural work.", 
        image: "/images/newhollandt7cylinder.jpg" 
      },
      { 
        id: 7, 
        name: "SDF 130 Cylinder", 
        price: 580, 
        description: "Reliable hydraulic cylinder for SDF 130 series, designed to withstand high-load operations.", 
        image: "/images/sdf130cylinder.jpg" 
      },
      { 
        id: 8, 
        name: "Kubota M110X Cylinder", 
        price: 550, 
        description: "Hydraulic cylinder for Kubota M110X series, known for consistent performance in agricultural tasks.", 
        image: "/images/kubotam110xcylinder.jpg" 
      },
      { 
        id: 9, 
        name: "JCB Fastrac 8310 Cylinder", 
        price: 800, 
        description: "Heavy-duty hydraulic cylinder for JCB Fastrac 8310 series, ideal for industrial and agricultural applications.", 
        image: "/images/jcb8310cylinder.jpg" 
      },
      { 
        id: 10, 
        name: "Valtra T Series Cylinder", 
        price: 700, 
        description: "High-strength hydraulic cylinder for Valtra T Series, designed for powerful and precise operations.", 
        image: "/images/valtratseriescylinder.jpg" 
      },
      { 
        id: 11, 
        name: "Mercedes-Benz Unimog U500 Cylinder", 
        price: 850, 
        description: "Premium hydraulic cylinder for Mercedes-Benz Unimog U500, suitable for extreme conditions and heavy-duty usage.", 
        image: "/images/mercedesbenzunimogu500cylinder.jpg" 
      },
      { 
        id: 12, 
        name: "Lamborghini R8 Cylinder", 
        price: 770, 
        description: "High-performance hydraulic cylinder for Lamborghini R8 series, designed for optimal pressure and power output.", 
        image: "/images/lamborghinir8cylinder.jpg" 
      },
      { 
        id: 13, 
        name: "Zetor Proxima 80 Cylinder", 
        price: 540, 
        description: "Dependable hydraulic cylinder for Zetor Proxima 80 series, ideal for varied farming and field work.", 
        image: "/images/zetorproxima80cylinder.jpg" 
      },
      { 
        id: 14, 
        name: "Fendt 1050 Vario Cylinder", 
        price: 900, 
        description: "High-performance hydraulic cylinder for Fendt 1050 Vario, designed for exceptional load capacity and durability.", 
        image: "/images/fendt1050variocylinder.jpg" 
      },
      { 
        id: 15, 
        name: "Case IH Steiger 500 Cylinder", 
        price: 950, 
        description: "Heavy-duty hydraulic cylinder for Case IH Steiger 500 series, engineered for powerful operations and reliability.", 
        image: "/images/caseihsteiger500cylinder.jpg" 
      },
      { 
        id: 16, 
        name: "Tafe 35 DI Cylinder", 
        price: 500, 
        description: "Standard hydraulic cylinder for Tafe 35 DI series, built to withstand long-lasting pressure and use.", 
        image: "/images/tafe35dicylinder.jpg" 
      },
      { 
        id: 17, 
        name: "International Harvester 3588 Cylinder", 
        price: 600, 
        description: "Reliable hydraulic cylinder for International Harvester 3588 series, designed for tough farm and industrial tasks.", 
        image: "/images/ih3588cylinder.jpg" 
      },
      { 
        id: 18, 
        name: "Allis-Chalmers 185 Cylinder", 
        price: 550, 
        description: "High-quality hydraulic cylinder for Allis-Chalmers 185 series, engineered for dependable, heavy-duty use.", 
        image: "/images/allischalmers185cylinder.jpg" 
      },
      { 
        id: 19, 
        name: "SAME Dorado Cylinder", 
        price: 520, 
        description: "Efficient hydraulic cylinder for SAME Dorado series, optimized for durability and consistent performance.", 
        image: "/images/samedoradocylinder.jpg" 
      },
      { 
        id: 20, 
        name: "Mitsubishi MT Series Cylinder", 
        price: 480, 
        description: "Hydraulic cylinder for Mitsubishi MT series, built for reliable use in both agricultural and construction tasks.", 
        image: "/images/mitsubishimtseriescylinder.jpg" 
      }


  ],
  gaskets: [
    { 
        id: 1, 
        name: "Head Gasket for John Deere 6 Series", 
        price: 50, 
        description: "Durable head gasket designed for John Deere 6 Series tractors, ensuring a tight seal and optimal engine performance.", 
        image: "/images/johndeere6headgasket.jpg" 
      },
      { 
        id: 2, 
        name: "Cylinder Head Gasket for Case IH Magnum", 
        price: 60, 
        description: "High-quality cylinder head gasket for Case IH Magnum series, designed for long-lasting performance.", 
        image: "/images/caseihmagnumheadgasket.jpg" 
      },
      { 
        id: 3, 
        name: "Exhaust Manifold Gasket for Fendt 700 Vario", 
        price: 45, 
        description: "Reliable exhaust manifold gasket for Fendt 700 Vario series, ensuring leak-free operation.", 
        image: "/images/fendt700exhaustgasket.jpg" 
      },
      { 
        id: 4, 
        name: "Intake Manifold Gasket for Massey Ferguson 7600", 
        price: 40, 
        description: "Premium intake manifold gasket for Massey Ferguson 7600 series, built for efficient airflow and engine efficiency.", 
        image: "/images/massey7600intakegasket.jpg" 
      },
      { 
        id: 5, 
        name: "Oil Pan Gasket for Kubota M110X", 
        price: 30, 
        description: "Oil pan gasket for Kubota M110X series, designed to prevent leaks and protect the engine from oil loss.", 
        image: "/images/kubotam110xoilgasket.jpg" 
      },
      { 
        id: 6, 
        name: "Valve Cover Gasket for New Holland T7", 
        price: 35, 
        description: "Durable valve cover gasket for New Holland T7 series, built to maintain engine lubrication and prevent oil leakage.", 
        image: "/images/newhollandt7valvecovergasket.jpg" 
      },
      { 
        id: 7, 
        name: "Timing Cover Gasket for John Deere 7R", 
        price: 55, 
        description: "Timing cover gasket for John Deere 7R series, engineered for reliable performance and leak prevention.", 
        image: "/images/johndeere7rtimingcovergasket.jpg" 
      },
      { 
        id: 8, 
        name: "Water Pump Gasket for Claas Xerion", 
        price: 25, 
        description: "Water pump gasket for Claas Xerion series, designed for efficient coolant circulation and engine temperature control.", 
        image: "/images/claasxerionwaterpumpgasket.jpg" 
      },
      { 
        id: 9, 
        name: "Oil Seal Gasket for JCB Fastrac 8310", 
        price: 50, 
        description: "Oil seal gasket for JCB Fastrac 8310, providing a secure seal for oil retention and engine efficiency.", 
        image: "/images/jcb8310oilsealgasket.jpg" 
      },
      { 
        id: 10, 
        name: "Cylinder Head Gasket for Valtra T Series", 
        price: 65, 
        description: "Heavy-duty cylinder head gasket for Valtra T Series, optimized for performance and heat resistance.", 
        image: "/images/valtratseriesheadgasket.jpg" 
      },
      { 
        id: 11, 
        name: "Transmission Gasket for Case IH Steiger", 
        price: 70, 
        description: "High-quality transmission gasket for Case IH Steiger series, ensuring a leak-free and reliable transmission system.", 
        image: "/images/caseihsteigertransgasket.jpg" 
      },
      { 
        id: 12, 
        name: "Rear Main Seal Gasket for International Harvester 3588", 
        price: 45, 
        description: "Rear main seal gasket for International Harvester 3588, designed for durability and leak prevention.", 
        image: "/images/ih3588rearmainsealgasket.jpg" 
      },
      { 
        id: 13, 
        name: "Valve Seat Gasket for Zetor Proxima", 
        price: 20, 
        description: "Valve seat gasket for Zetor Proxima series, built for secure valve seating and optimal engine performance.", 
        image: "/images/zetorproximagasket.jpg" 
      },
      { 
        id: 14, 
        name: "Oil Filter Gasket for Tafe 35 DI", 
        price: 15, 
        description: "Oil filter gasket for Tafe 35 DI, designed for a tight seal to prevent oil leakage.", 
        image: "/images/tafe35dioilfiltergasket.jpg" 
      },
      { 
        id: 15, 
        name: "Head Gasket for Allis-Chalmers 185", 
        price: 55, 
        description: "Head gasket for Allis-Chalmers 185, engineered for high-performance and durability under heavy-duty conditions.", 
        image: "/images/allischalmers185headgasket.jpg" 
      },
      { 
        id: 16, 
        name: "Intake Manifold Gasket for SAME Dorado", 
        price: 25, 
        description: "Intake manifold gasket for SAME Dorado, built for enhanced engine airflow and optimal fuel combustion.", 
        image: "/images/samedoradointakegasket.jpg" 
      },
      { 
        id: 17, 
        name: "Water Pump Gasket for Mitsubishi MT Series", 
        price: 18, 
        description: "Water pump gasket for Mitsubishi MT series, designed to ensure efficient coolant circulation.", 
        image: "/images/mitsubishimtgasket.jpg" 
      },
      { 
        id: 18, 
        name: "Exhaust Gasket for Lamborghini R8", 
        price: 40, 
        description: "Exhaust gasket for Lamborghini R8, built for high temperature and pressure endurance.", 
        image: "/images/lamborghinir8exhaustgasket.jpg" 
      },
      { 
        id: 19, 
        name: "Head Gasket for Mercedes-Benz Unimog U500", 
        price: 75, 
        description: "Head gasket for Mercedes-Benz Unimog U500, designed for heavy-duty and industrial performance.", 
        image: "/images/mercedesbenzunimogu500headgasket.jpg" 
      },
      { 
        id: 20, 
        name: "Valve Cover Gasket for Rolls-Royce 1300", 
        price: 35, 
        description: "Valve cover gasket for Rolls-Royce 1300, ensuring optimal engine performance and oil retention.", 
        image: "/images/rollsroyce1300valvecovergasket.jpg" 
      },
      { 
        id: 21, 
        name: "Oil Pan Gasket for Ford 7710", 
        price: 22, 
        description: "Oil pan gasket for Ford 7710, designed to provide a secure seal and protect the engine from oil leaks.", 
        image: "/images/ford7710oilpangasket.jpg" 
      },
      { 
        id: 22, 
        name: "Timing Cover Gasket for Zetor 160", 
        price: 28, 
        description: "Timing cover gasket for Zetor 160, designed for efficient timing and engine synchronization.", 
        image: "/images/zetor160timingcovergasket.jpg" 
      },
      { 
        id: 23, 
        name: "Fuel Pump Gasket for Case IH 9330", 
        price: 18, 
        description: "Fuel pump gasket for Case IH 9330, ensuring secure and efficient fuel delivery.", 
        image: "/images/caseih9330fuelpumpgasket.jpg" 
      },
      { 
        id: 24, 
        name: "Exhaust Gasket for JCB Fastrac", 
        price: 30, 
        description: "Exhaust gasket for JCB Fastrac, designed for high heat and pressure endurance in tough conditions.", 
        image: "/images/jcbfastracexhaustgasket.jpg" 
      },
      { 
        id: 25, 
        name: "Oil Pan Gasket for International Harvester 1486", 
        price: 24, 
        description: "Oil pan gasket for International Harvester 1486, ensuring leak-free oil containment and engine protection.", 
        image: "/images/ih1486oilpangasket.jpg" 
      },
      { 
        id: 26, 
        name: "Rear Main Seal Gasket for John Deere 4000 Series", 
        price: 32, 
        description: "Rear main seal gasket for John Deere 4000 series, designed for engine durability and oil leak prevention.", 
        image: "/images/johndeere4000rearmainsealgasket.jpg" 
      },
      { 
        id: 27, 
        name: "Valve Cover Gasket for New Holland T9", 
        price: 28, 
        description: "Valve cover gasket for New Holland T9, ensuring proper oil retention and engine longevity.", 
        image: "/images/newhollandt9valvecovergasket.jpg" 
      },
      { 
        id: 28, 
        name: "Cylinder Head Gasket for AGCO 8480", 
        price: 63, 
        description: "Cylinder head gasket for AGCO 8480, engineered for high performance and durability under pressure.", 
        image: "/images/agco8480headgasket.jpg" 
      },
      { 
        id: 29, 
        name: "Intake Manifold Gasket for Landini Powerfarm", 
        price: 18, 
        description: "Intake manifold gasket for Landini Powerfarm, designed for optimized engine airflow and fuel efficiency.", 
        image: "/images/landinipowerfarmintakegasket.jpg" 
      },
      { 
        id: 30, 
        name: "Timing Chain Gasket for Massey Ferguson 5400", 
        price: 33, 
        description: "Timing chain gasket for Massey Ferguson 5400, designed for secure timing chain retention.", 
        image: "/images/massey5400timingchaingasket.jpg" 
      },
      { 
        id: 31, 
        name: "Timing Cover Gasket for AGCO Challenger", 
        price: 27, 
        description: "Timing cover gasket for AGCO Challenger, ensuring proper timing chain protection and engine seal.", 
        image: "/images/agcochallengertimingcovergasket.jpg" 
      },
      { 
        id: 32, 
        name: "Oil Filter Gasket for TYM T503", 
        price: 12, 
        description: "Oil filter gasket for TYM T503, designed for a tight seal to prevent oil leaks.", 
        image: "/images/tymt503oilfiltergasket.jpg" 
      },
      { 
        id: 33, 
        name: "Exhaust Gasket for Deutz-Fahr 5100", 
        price: 20, 
        description: "Exhaust gasket for Deutz-Fahr 5100, built for reliable performance under high temperature and pressure.", 
        image: "/images/deutzfahr5100exhaustgasket.jpg" 
      },
      { 
        id: 34, 
        name: "Water Pump Gasket for Valtra N Series", 
        price: 15, 
        description: "Water pump gasket for Valtra N Series, ensuring consistent coolant flow and temperature regulation.", 
        image: "/images/valtranserieswaterpumpgasket.jpg" 
      },
      { 
        id: 35, 
        name: "Intake Gasket for John Deere 6R", 
        price: 21, 
        description: "Intake gasket for John Deere 6R, designed for a secure fit and efficient engine air intake.", 
        image: "/images/johndeere6rintakegasket.jpg" 
      },
      { 
        id: 36, 
        name: "Exhaust Gasket for Case IH Puma", 
        price: 30, 
        description: "Exhaust gasket for Case IH Puma, engineered to withstand high exhaust temperatures and pressures.", 
        image: "/images/caseihpumaexhaustgasket.jpg" 
      },
      { 
        id: 37, 
        name: "Engine Block Gasket for Massey Ferguson 5400", 
        price: 39, 
        description: "Engine block gasket for Massey Ferguson 5400, designed to seal the engine block effectively for smooth operation.", 
        image: "/images/massey5400blockgasket.jpg" 
      },
      { 
        id: 38, 
        name: "Fuel Tank Gasket for Valmet 305", 
        price: 14, 
        description: "Fuel tank gasket for Valmet 305, built for leak prevention and fuel containment.", 
        image: "/images/valmet305fueltankgasket.jpg" 
      },
      { 
        id: 39, 
        name: "Hydraulic Pump Gasket for Kubota L Series", 
        price: 17, 
        description: "Hydraulic pump gasket for Kubota L Series, ensuring efficient and reliable hydraulic system operation.", 
        image: "/images/kubotalserieshydraulicpumpgasket.jpg" 
      },
      { 
        id: 40, 
        name: "Throttle Body Gasket for Claas Arion", 
        price: 20, 
        description: "Throttle body gasket for Claas Arion, ensuring precise throttle control and optimal performance.", 
        image: "/images/claasarionthrottlebodygasket.jpg" 
      }

  ],
  crankshafts:[
    { 
        id: 1, 
        name: "Crankshaft for John Deere 7200", 
        price: 500, 
        description: "High-quality crankshaft for John Deere 7200, designed for optimal engine performance and durability.", 
        image: "/images/johndeere7200crankshaft.jpg" 
      },
      { 
        id: 2, 
        name: "Crankshaft for Case IH Magnum", 
        price: 550, 
        description: "Durable crankshaft for Case IH Magnum, built to withstand high torque and extended use.", 
        image: "/images/caseihmagnumcrankshaft.jpg" 
      },
      { 
        id: 3, 
        name: "Crankshaft for Massey Ferguson 5600", 
        price: 480, 
        description: "Precision-engineered crankshaft for Massey Ferguson 5600, offering reliable performance and longevity.", 
        image: "/images/massey5600crankshaft.jpg" 
      },
      { 
        id: 4, 
        name: "Crankshaft for New Holland T7", 
        price: 600, 
        description: "High-performance crankshaft for New Holland T7, ensuring smooth engine operation and reliability.", 
        image: "/images/newhollandt7crankshaft.jpg" 
      },
      { 
        id: 5, 
        name: "Crankshaft for AGCO Fendt 720", 
        price: 620, 
        description: "Heavy-duty crankshaft for AGCO Fendt 720, designed for robust engine function and high load capacity.", 
        image: "/images/agcofendt720crankshaft.jpg" 
      },
      { 
        id: 6, 
        name: "Crankshaft for Valtra T Series", 
        price: 550, 
        description: "Reliable crankshaft for Valtra T Series, built for long-term use under tough conditions.", 
        image: "/images/valtratseriescrankshaft.jpg" 
      },
      { 
        id: 7, 
        name: "Crankshaft for Kubota M Series", 
        price: 430, 
        description: "Efficient crankshaft for Kubota M Series, ensuring consistent power delivery and engine efficiency.", 
        image: "/images/kubotamseriescrankshaft.jpg" 
      },
      { 
        id: 8, 
        name: "Crankshaft for Deutz-Fahr 6 Series", 
        price: 570, 
        description: "Top-quality crankshaft for Deutz-Fahr 6 Series, designed for reliable performance in demanding tasks.", 
        image: "/images/deutzfahr6seriescrankshaft.jpg" 
      },
      { 
        id: 9, 
        name: "Crankshaft for CLAAS Xerion", 
        price: 650, 
        description: "Strong and durable crankshaft for CLAAS Xerion, ensuring stability and high power output.", 
        image: "/images/claasxerioncrankshaft.jpg" 
      },
      { 
        id: 10, 
        name: "Crankshaft for Massey Ferguson 8400", 
        price: 500, 
        description: "Crankshaft for Massey Ferguson 8400, offering precision engineering for optimal engine operation.", 
        image: "/images/massey8400crankshaft.jpg" 
      },
      { 
        id: 11, 
        name: "Crankshaft for John Deere 8400R", 
        price: 620, 
        description: "Durable crankshaft for John Deere 8400R, built for maximum power and high efficiency.", 
        image: "/images/johndeere8400rcrankshaft.jpg" 
      },
      { 
        id: 12, 
        name: "Crankshaft for Case IH 300 Series", 
        price: 550, 
        description: "High-strength crankshaft for Case IH 300 Series, designed for high power output and engine reliability.", 
        image: "/images/caseih300seriescrankshaft.jpg" 
      },
      { 
        id: 13, 
        name: "Crankshaft for New Holland CR Series", 
        price: 580, 
        description: "Engineered crankshaft for New Holland CR Series, ensuring smooth operation under heavy loads.", 
        image: "/images/newhollandcrseriescrankshaft.jpg" 
      },
      { 
        id: 14, 
        name: "Crankshaft for AGCO Gleaner", 
        price: 590, 
        description: "Reliable crankshaft for AGCO Gleaner, built for durability and long-lasting engine performance.", 
        image: "/images/agcogleanercrankshaft.jpg" 
      },
      { 
        id: 15, 
        name: "Crankshaft for Valtra S Series", 
        price: 640, 
        description: "Heavy-duty crankshaft for Valtra S Series, designed for maximum load and high torque output.", 
        image: "/images/valtrasseriescrankshaft.jpg" 
      },
      { 
        id: 16, 
        name: "Crankshaft for Kubota L Series", 
        price: 460, 
        description: "Efficient crankshaft for Kubota L Series, designed to optimize engine performance and efficiency.", 
        image: "/images/kubotalseriescrankshaft.jpg" 
      },
      { 
        id: 17, 
        name: "Crankshaft for Deutz-Fahr 7 Series", 
        price: 670, 
        description: "Premium crankshaft for Deutz-Fahr 7 Series, built for high durability and top performance.", 
        image: "/images/deutzfahr7seriescrankshaft.jpg" 
      },
      { 
        id: 18, 
        name: "Crankshaft for CLAAS Arion", 
        price: 600, 
        description: "High-performance crankshaft for CLAAS Arion, ensuring reliable operation in various working conditions.", 
        image: "/images/claasarioncrankshaft.jpg" 
      },
      { 
        id: 19, 
        name: "Crankshaft for John Deere 6R Series", 
        price: 530, 
        description: "Engineered crankshaft for John Deere 6R Series, built for excellent torque and power delivery.", 
        image: "/images/johndeere6rseriescrankshaft.jpg" 
      },
      { 
        id: 20, 
        name: "Crankshaft for Case IH Steiger", 
        price: 720, 
        description: "Heavy-duty crankshaft for Case IH Steiger, designed for extreme performance and high efficiency.", 
        image: "/images/caseihsteigercrankshaft.jpg" 
      },
      { 
        id: 21, 
        name: "Crankshaft for AGCO Challenger 1000", 
        price: 650, 
        description: "Top-quality crankshaft for AGCO Challenger 1000, built for high power and seamless operation.", 
        image: "/images/agcochallenger1000crankshaft.jpg" 
      },
      { 
        id: 22, 
        name: "Crankshaft for Massey Ferguson 8700", 
        price: 560, 
        description: "Durable crankshaft for Massey Ferguson 8700, engineered for optimal engine efficiency and long lifespan.", 
        image: "/images/massey8700crankshaft.jpg" 
      },
      { 
        id: 23, 
        name: "Crankshaft for Valtra T Series", 
        price: 580, 
        description: "High-performance crankshaft for Valtra T Series, built to handle high torque and load conditions.", 
        image: "/images/valtratseriescrankshaft2.jpg" 
      },
      { 
        id: 24, 
        name: "Crankshaft for Kubota BX Series", 
        price: 400, 
        description: "Crankshaft for Kubota BX Series, designed for reliable performance in compact tractors.", 
        image: "/images/kubotabxseriescrankshaft.jpg" 
      },
      { 
        id: 25, 
        name: "Crankshaft for Deutz-Fahr 5 Series", 
        price: 490, 
        description: "Crankshaft for Deutz-Fahr 5 Series, engineered for strong performance and long-lasting use.", 
        image: "/images/deutzfahr5seriescrankshaft.jpg" 
      },
      { 
        id: 26, 
        name: "Crankshaft for CLAAS Axion", 
        price: 620, 
        description: "Premium crankshaft for CLAAS Axion, built for exceptional performance and durability in tough conditions.", 
        image: "/images/claasaxioncrankshaft.jpg" 
      },
      { 
        id: 27, 
        name: "Crankshaft for John Deere 9R Series", 
        price: 700, 
        description: "High-quality crankshaft for John Deere 9R Series, engineered for maximum power output and reliability.", 
        image: "/images/johndeere9rseriescrankshaft.jpg" 
      },
      { 
        id: 28, 
        name: "Crankshaft for Case IH 7200", 
        price: 580, 
        description: "Reliable crankshaft for Case IH 7200, designed for consistent high performance under heavy loads.", 
        image: "/images/caseih7200crankshaft.jpg" 
      },
      { 
        id: 29, 
        name: "Crankshaft for AGCO Fendt 1000 Vario", 
        price: 750, 
        description: "Heavy-duty crankshaft for AGCO Fendt 1000 Vario, known for high torque and fuel efficiency.", 
        image: "/images/agcofendt1000variocrankshaft.jpg" 
      },
      { 
        id: 30, 
        name: "Crankshaft for Valtra S Series", 
        price: 680, 
        description: "Crankshaft for Valtra S Series, built for high load tolerance and exceptional performance.", 
        image: "/images/valtrasseriescrankshaft2.jpg" 
      }
  ],
  camshafts: [
    { 
        id: 1, 
        name: "Camshaft for John Deere 7200", 
        price: 250, 
        description: "High-performance camshaft for John Deere 7200, designed to enhance engine timing and power output.", 
        image: "/images/johndeere7200camshaft.jpg" 
      },
      { 
        id: 2, 
        name: "Camshaft for Case IH Magnum", 
        price: 270, 
        description: "Durable camshaft for Case IH Magnum, optimized for high torque and efficiency.", 
        image: "/images/caseihmagnumcamshaft.jpg" 
      },
      { 
        id: 3, 
        name: "Camshaft for Massey Ferguson 5600", 
        price: 230, 
        description: "Precision-engineered camshaft for Massey Ferguson 5600, designed for reliable and smooth engine performance.", 
        image: "/images/massey5600camshaft.jpg" 
      },
      { 
        id: 4, 
        name: "Camshaft for New Holland T7", 
        price: 280, 
        description: "High-quality camshaft for New Holland T7, built for maximum engine efficiency and power enhancement.", 
        image: "/images/newhollandt7camshaft.jpg" 
      },
      { 
        id: 5, 
        name: "Camshaft for AGCO Fendt 720", 
        price: 300, 
        description: "High-performance camshaft for AGCO Fendt 720, providing superior power and fuel efficiency.", 
        image: "/images/agcofendt720camshaft.jpg" 
      },
      { 
        id: 6, 
        name: "Camshaft for Valtra T Series", 
        price: 270, 
        description: "Reliable camshaft for Valtra T Series, optimized for high power output and durability.", 
        image: "/images/valtratseriescamshaft.jpg" 
      },
      { 
        id: 7, 
        name: "Camshaft for Kubota M Series", 
        price: 220, 
        description: "Efficient camshaft for Kubota M Series, designed to boost engine performance and efficiency.", 
        image: "/images/kubotamseriescamshaft.jpg" 
      },
      { 
        id: 8, 
        name: "Camshaft for Deutz-Fahr 6 Series", 
        price: 290, 
        description: "High-quality camshaft for Deutz-Fahr 6 Series, built for robust engine function and smooth operation.", 
        image: "/images/deutzfahr6seriescamshaft.jpg" 
      },
      { 
        id: 9, 
        name: "Camshaft for CLAAS Xerion", 
        price: 310, 
        description: "High-performance camshaft for CLAAS Xerion, engineered for consistent power and fuel efficiency.", 
        image: "/images/claasxerioncamshaft.jpg" 
      },
      { 
        id: 10, 
        name: "Camshaft for Massey Ferguson 8400", 
        price: 260, 
        description: "Precision camshaft for Massey Ferguson 8400, optimized for high performance and fuel economy.", 
        image: "/images/massey8400camshaft.jpg" 
      },
      { 
        id: 11, 
        name: "Camshaft for John Deere 8400R", 
        price: 320, 
        description: "Advanced camshaft for John Deere 8400R, providing enhanced torque and high engine efficiency.", 
        image: "/images/johndeere8400rcamshaft.jpg" 
      },
      { 
        id: 12, 
        name: "Camshaft for Case IH 300 Series", 
        price: 280, 
        description: "Durable camshaft for Case IH 300 Series, built for consistent performance and maximum power.", 
        image: "/images/caseih300seriescamshaft.jpg" 
      },
      { 
        id: 13, 
        name: "Camshaft for New Holland CR Series", 
        price: 310, 
        description: "Engineered camshaft for New Holland CR Series, ensuring reliability under high stress and load conditions.", 
        image: "/images/newhollandcrseriescamshaft.jpg" 
      },
      { 
        id: 14, 
        name: "Camshaft for AGCO Gleaner", 
        price: 320, 
        description: "High-quality camshaft for AGCO Gleaner, built for efficient operation and reduced fuel consumption.", 
        image: "/images/agcogleanercamshaft.jpg" 
      },
      { 
        id: 15, 
        name: "Camshaft for Valtra S Series", 
        price: 350, 
        description: "Heavy-duty camshaft for Valtra S Series, designed to handle high torque and provide consistent performance.", 
        image: "/images/valtrasseriescamshaft.jpg" 
      },
      { 
        id: 16, 
        name: "Camshaft for Kubota L Series", 
        price: 210, 
        description: "Reliable camshaft for Kubota L Series, built for efficiency and long-term engine durability.", 
        image: "/images/kubotalseriescamshaft.jpg" 
      },
      { 
        id: 17, 
        name: "Camshaft for Deutz-Fahr 7 Series", 
        price: 340, 
        description: "Heavy-duty camshaft for Deutz-Fahr 7 Series, providing high power and smooth engine operation.", 
        image: "/images/deutzfahr7seriescamshaft.jpg" 
      },
      { 
        id: 18, 
        name: "Camshaft for CLAAS Arion", 
        price: 300, 
        description: "High-performance camshaft for CLAAS Arion, designed to maximize power output and fuel efficiency.", 
        image: "/images/claasarioncamshaft.jpg" 
      },
      { 
        id: 19, 
        name: "Camshaft for John Deere 6R Series", 
        price: 270, 
        description: "Durable camshaft for John Deere 6R Series, built to enhance engine performance and longevity.", 
        image: "/images/johndeere6rseriescamshaft.jpg" 
      },
      { 
        id: 20, 
        name: "Camshaft for Case IH Steiger", 
        price: 360, 
        description: "Heavy-duty camshaft for Case IH Steiger, engineered for exceptional power and high engine performance.", 
        image: "/images/caseihsteigercamshaft.jpg" 
      },
      { 
        id: 21, 
        name: "Camshaft for AGCO Challenger 1000", 
        price: 340, 
        description: "High-quality camshaft for AGCO Challenger 1000, providing reliable power output and engine efficiency.", 
        image: "/images/agcochallenger1000camshaft.jpg" 
      },
      { 
        id: 22, 
        name: "Camshaft for Massey Ferguson 8700", 
        price: 290, 
        description: "Engineered camshaft for Massey Ferguson 8700, designed for performance and fuel efficiency.", 
        image: "/images/massey8700camshaft.jpg" 
      },
      { 
        id: 23, 
        name: "Camshaft for Valtra T Series", 
        price: 310, 
        description: "Reliable camshaft for Valtra T Series, built for high torque and long-lasting performance.", 
        image: "/images/valtratseriescamshaft2.jpg" 
      },
      { 
        id: 24, 
        name: "Camshaft for Kubota BX Series", 
        price: 200, 
        description: "Precision camshaft for Kubota BX Series, optimized for compact engine performance.", 
        image: "/images/kubotabxseriescamshaft.jpg" 
      },
      { 
        id: 25, 
        name: "Camshaft for Deutz-Fahr 5 Series", 
        price: 230, 
        description: "Camshaft for Deutz-Fahr 5 Series, designed for reliability and efficient engine function.", 
        image: "/images/deutzfahr5seriescamshaft.jpg" 
      },
      { 
        id: 26, 
        name: "Camshaft for CLAAS Axion", 
        price: 320, 
        description: "Top-quality camshaft for CLAAS Axion, ensuring optimal timing and high engine efficiency.", 
        image: "/images/claasaxioncamshaft.jpg" 
      },
      { 
        id: 27, 
        name: "Camshaft for John Deere 9R Series", 
        price: 350, 
        description: "High-performance camshaft for John Deere 9R Series, built for optimal engine power and long-lasting use.", 
        image: "/images/johndeere9rseriescamshaft.jpg" 
      },
      { 
        id: 28, 
        name: "Camshaft for Case IH 7200", 
        price: 300, 
        description: "Engineered camshaft for Case IH 7200, designed for consistent power and smooth operation.", 
        image: "/images/caseih7200camshaft.jpg" 
      }
  ],
  clutchplates: [
    {
        id: 1,
        name: "Clutch Plate for John Deere 7200",
        price: 150,
        description: "High-quality clutch plate for John Deere 7200, engineered for maximum power transfer and durability.",
        image: "/images/johndeere7200clutchplate.jpg"
      },
      {
        id: 2,
        name: "Clutch Plate for Case IH Magnum",
        price: 160,
        description: "Durable clutch plate for Case IH Magnum, designed to provide reliable engagement and smooth operation.",
        image: "/images/caseihmagnumclutchplate.jpg"
      },
      {
        id: 3,
        name: "Clutch Plate for Massey Ferguson 5600",
        price: 140,
        description: "High-performance clutch plate for Massey Ferguson 5600, built for efficient power transfer and long lifespan.",
        image: "/images/massey5600clutchplate.jpg"
      },
      {
        id: 4,
        name: "Clutch Plate for New Holland T7",
        price: 170,
        description: "Top-quality clutch plate for New Holland T7, engineered for smooth clutch engagement and reliable performance.",
        image: "/images/newhollandt7clutchplate.jpg"
      },
      {
        id: 5,
        name: "Clutch Plate for AGCO Fendt 720",
        price: 180,
        description: "Heavy-duty clutch plate for AGCO Fendt 720, built for exceptional durability and power handling.",
        image: "/images/agcofendt720clutchplate.jpg"
      },
      {
        id: 6,
        name: "Clutch Plate for Valtra T Series",
        price: 160,
        description: "Reliable clutch plate for Valtra T Series, designed for seamless operation and high load tolerance.",
        image: "/images/valtratseriesclutchplate.jpg"
      },
      {
        id: 7,
        name: "Clutch Plate for Kubota M Series",
        price: 130,
        description: "Efficient clutch plate for Kubota M Series, optimized for consistent power transfer and smooth performance.",
        image: "/images/kubotamseriesclutchplate.jpg"
      },
      {
        id: 8,
        name: "Clutch Plate for Deutz-Fahr 6 Series",
        price: 190,
        description: "High-quality clutch plate for Deutz-Fahr 6 Series, designed for robust operation and enhanced engine performance.",
        image: "/images/deutzfahr6seriesclutchplate.jpg"
      },
      {
        id: 9,
        name: "Clutch Plate for CLAAS Xerion",
        price: 200,
        description: "Heavy-duty clutch plate for CLAAS Xerion, engineered for optimal performance under high loads.",
        image: "/images/claasxerionclutchplate.jpg"
      },
      {
        id: 10,
        name: "Clutch Plate for Massey Ferguson 8400",
        price: 150,
        description: "High-quality clutch plate for Massey Ferguson 8400, designed for smooth operation and reliable power transfer.",
        image: "/images/massey8400clutchplate.jpg"
      },
      {
        id: 11,
        name: "Clutch Plate for John Deere 8400R",
        price: 210,
        description: "Advanced clutch plate for John Deere 8400R, providing enhanced durability and smooth engagement.",
        image: "/images/johndeere8400rclutchplate.jpg"
      },
      {
        id: 12,
        name: "Clutch Plate for Case IH 300 Series",
        price: 170,
        description: "Durable clutch plate for Case IH 300 Series, engineered for smooth and reliable performance.",
        image: "/images/caseih300seriesclutchplate.jpg"
      },
      {
        id: 13,
        name: "Clutch Plate for New Holland CR Series",
        price: 190,
        description: "Heavy-duty clutch plate for New Holland CR Series, designed for consistent performance and power management.",
        image: "/images/newhollandcrseriesclutchplate.jpg"
      },
      {
        id: 14,
        name: "Clutch Plate for AGCO Gleaner",
        price: 200,
        description: "High-quality clutch plate for AGCO Gleaner, built for reliable performance and long-lasting use.",
        image: "/images/agcogleanerclutchplate.jpg"
      },
      {
        id: 15,
        name: "Clutch Plate for Valtra S Series",
        price: 220,
        description: "Heavy-duty clutch plate for Valtra S Series, designed to handle high loads and ensure smooth operation.",
        image: "/images/valtrasseriesclutchplate.jpg"
      },
      {
        id: 16,
        name: "Clutch Plate for Kubota L Series",
        price: 120,
        description: "Reliable clutch plate for Kubota L Series, designed for efficient engagement and reduced wear.",
        image: "/images/kubotalseriesclutchplate.jpg"
      },
      {
        id: 17,
        name: "Clutch Plate for Deutz-Fahr 7 Series",
        price: 220,
        description: "Heavy-duty clutch plate for Deutz-Fahr 7 Series, engineered for high performance and durability.",
        image: "/images/deutzfahr7seriesclutchplate.jpg"
      },
      {
        id: 18,
        name: "Clutch Plate for CLAAS Arion",
        price: 180,
        description: "Top-quality clutch plate for CLAAS Arion, optimized for smooth power transfer and engine performance.",
        image: "/images/claasarionclutchplate.jpg"
      },
      {
        id: 19,
        name: "Clutch Plate for John Deere 6R Series",
        price: 160,
        description: "Reliable clutch plate for John Deere 6R Series, built to enhance engine function and power handling.",
        image: "/images/johndeere6rseriesclutchplate.jpg"
      },
      {
        id: 20,
        name: "Clutch Plate for Case IH Steiger",
        price: 230,
        description: "Heavy-duty clutch plate for Case IH Steiger, providing optimal performance and durability.",
        image: "/images/caseihsteigerclutchplate.jpg"
      },
      {
        id: 21,
        name: "Clutch Plate for AGCO Challenger 1000",
        price: 210,
        description: "High-quality clutch plate for AGCO Challenger 1000, designed for consistent operation and reliability.",
        image: "/images/agcochallenger1000clutchplate.jpg"
      },
      {
        id: 22,
        name: "Clutch Plate for Massey Ferguson 8700",
        price: 180,
        description: "Durable clutch plate for Massey Ferguson 8700, engineered for efficient performance and smooth operation.",
        image: "/images/massey8700clutchplate.jpg"
      },
      {
        id: 23,
        name: "Clutch Plate for Valtra T Series",
        price: 190,
        description: "Reliable clutch plate for Valtra T Series, designed for high torque and efficient power handling.",
        image: "/images/valtratseriesclutchplate2.jpg"
      },
      {
        id: 24,
        name: "Clutch Plate for Kubota BX Series",
        price: 110,
        description: "Compact and efficient clutch plate for Kubota BX Series, optimized for reliable engagement.",
        image: "/images/kubotabxseriesclutchplate.jpg"
      },
      {
        id: 25,
        name: "Clutch Plate for Deutz-Fahr 5 Series",
        price: 140,
        description: "Reliable clutch plate for Deutz-Fahr 5 Series, built for consistent performance and durability.",
        image: "/images/deutzfahr5seriesclutchplate.jpg"
      },
      {
        id: 26,
        name: "Clutch Plate for CLAAS Axion",
        price: 210,
        description: "High-performance clutch plate for CLAAS Axion, ensuring smooth and efficient power transfer.",
        image: "/images/claasaxionclutchplate.jpg"
      },
      {
        id: 27,
        name: "Clutch Plate for John Deere 9R Series",
        price: 220,
        description: "High-quality clutch plate for John Deere 9R Series, engineered for maximum engine efficiency and durability.",
        image: "/images/johndeere9rseriesclutchplate.jpg"
      },
      {
        id: 28,
        name: "Clutch Plate for Case IH 7200",
        price: 180,
        description: "Durable clutch plate for Case IH 7200, optimized for smooth and reliable engagement.",
        image: "/images/caseih7200clutchplate.jpg"
      },
      {
        id: 29,
        name: "Clutch Plate for AGCO Fendt 1000 Vario",
        price: 250,
        description: "Heavy-duty clutch plate for AGCO Fendt 1000 Vario, built for exceptional power handling and performance.",
        image: "/images/agcofendt1000varioclutchplate.jpg"
      }

  ],
  bearings: [
    { 
        id: 1, 
        name: "Bearing for John Deere 7200", 
        price: 80, 
        description: "High-quality bearing for John Deere 7200, designed for enhanced stability and long-lasting performance.", 
        image: "/images/johndeere7200bearing.jpg" 
      },
      { 
        id: 2, 
        name: "Bearing for Case IH Magnum", 
        price: 85, 
        description: "Durable bearing for Case IH Magnum, built to withstand heavy loads and ensure smooth operation.", 
        image: "/images/caseihmagnumbearing.jpg" 
      },
      { 
        id: 3, 
        name: "Bearing for Massey Ferguson 5600", 
        price: 75, 
        description: "Reliable bearing for Massey Ferguson 5600, designed for durability and optimal engine performance.", 
        image: "/images/massey5600bearing.jpg" 
      },
      { 
        id: 4, 
        name: "Bearing for Kubota M Series", 
        price: 78, 
        description: "High-performance bearing for Kubota M Series, providing smooth operation and reduced friction.", 
        image: "/images/kubotamseriesbearing.jpg" 
      },
      { 
        id: 5, 
        name: "Bearing for Ford 5000", 
        price: 82, 
        description: "Sturdy bearing for Ford 5000 tractors, ensuring stable performance under demanding conditions.", 
        image: "/images/ford5000bearing.jpg" 
      },
      { 
        id: 6, 
        name: "Bearing for Rolls Royce 1300", 
        price: 90, 
        description: "Precision bearing for Rolls Royce 1300, built for excellent reliability and engine efficiency.", 
        image: "/images/rollsroyce1300bearing.jpg" 
      },
      { 
        id: 7, 
        name: "Bearing for New Holland T7", 
        price: 88, 
        description: "High-quality bearing for New Holland T7, designed for durability and high performance.", 
        image: "/images/newhollandt7bearing.jpg" 
      },
      { 
        id: 8, 
        name: "Bearing for Deutz-Fahr 6200", 
        price: 77, 
        description: "Reliable bearing for Deutz-Fahr 6200, optimized for long-lasting operation and high efficiency.", 
        image: "/images/deutzfahr6200bearing.jpg" 
      },
      { 
        id: 9, 
        name: "Bearing for Claas Arion 500", 
        price: 85, 
        description: "Durable bearing for Claas Arion 500, ensuring smooth mechanical functioning and high load resistance.", 
        image: "/images/claasarion500bearing.jpg" 
      },
      { 
        id: 10, 
        name: "Bearing for Fendt 720 Vario", 
        price: 95, 
        description: "High-performance bearing for Fendt 720 Vario, providing superior engine efficiency and reduced wear.", 
        image: "/images/fendt720variobearing.jpg" 
      },
      { 
        id: 11, 
        name: "Bearing for Zetor Forterra", 
        price: 76, 
        description: "Reliable bearing for Zetor Forterra, designed for durability and reliable engine performance.", 
        image: "/images/zetorforterrabearing.jpg" 
      },
      { 
        id: 12, 
        name: "Bearing for Massey Ferguson 8700", 
        price: 84, 
        description: "High-quality bearing for Massey Ferguson 8700, optimized for durability and reduced friction.", 
        image: "/images/massey8700bearing.jpg" 
      },
      { 
        id: 13, 
        name: "Bearing for Valtra T Series", 
        price: 92, 
        description: "Precision bearing for Valtra T Series, ensuring smooth operation and high performance under stress.", 
        image: "/images/valtratseriesbearing.jpg" 
      },
      { 
        id: 14, 
        name: "Bearing for Case IH Puma", 
        price: 83, 
        description: "Durable bearing for Case IH Puma, designed to support high power and provide stable operation.", 
        image: "/images/caseihpumabearing.jpg" 
      },
      { 
        id: 15, 
        name: "Bearing for John Deere 8530", 
        price: 86, 
        description: "Reliable bearing for John Deere 8530, built for heavy-duty tasks and long-lasting performance.", 
        image: "/images/johndeere8530bearing.jpg" 
      },
      { 
        id: 16, 
        name: "Bearing for Kubota BX Series", 
        price: 79, 
        description: "High-quality bearing for Kubota BX Series, designed for compact tractors with efficient power transfer.", 
        image: "/images/kubotabxseriesbearing.jpg" 
      },
      { 
        id: 17, 
        name: "Bearing for Case IH Steiger", 
        price: 98, 
        description: "Precision bearing for Case IH Steiger, providing maximum support for powerful machinery.", 
        image: "/images/caseihsteigerbearing.jpg" 
      },
      { 
        id: 18, 
        name: "Bearing for New Holland TS Series", 
        price: 80, 
        description: "Reliable bearing for New Holland TS Series, ensuring smooth operation and high load capacity.", 
        image: "/images/newhollandtsseriesbearing.jpg" 
      },
      { 
        id: 19, 
        name: "Bearing for Massey Ferguson 3000 Series", 
        price: 74, 
        description: "Durable bearing for Massey Ferguson 3000 Series, designed for reliable performance and minimal maintenance.", 
        image: "/images/massey3000seriesbearing.jpg" 
      },
      { 
        id: 20, 
        name: "Bearing for Claas Xerion", 
        price: 93, 
        description: "High-performance bearing for Claas Xerion, built for durability under challenging conditions.", 
        image: "/images/claasxerionbearing.jpg" 
      },
      { 
        id: 21, 
        name: "Bearing for Landini Rex", 
        price: 82, 
        description: "Reliable bearing for Landini Rex, engineered for optimal operation and reduced wear.", 
        image: "/images/landinirexbearing.jpg" 
      },
      { 
        id: 22, 
        name: "Bearing for Case IH Magnum 340", 
        price: 97, 
        description: "Precision bearing for Case IH Magnum 340, ensuring top performance and extended machine life.", 
        image: "/images/caseihmagnum340bearing.jpg" 
      },
      { 
        id: 23, 
        name: "Bearing for Valtra S Series", 
        price: 89, 
        description: "High-quality bearing for Valtra S Series, offering optimal performance and high load-bearing capacity.", 
        image: "/images/valtrasseriesbearing.jpg" 
      },
      { 
        id: 24, 
        name: "Bearing for Deutz-Fahr Agrotron", 
        price: 81, 
        description: "Durable bearing for Deutz-Fahr Agrotron, providing smooth and efficient operation under load.", 
        image: "/images/deutzfahragrotronbearing.jpg" 
      },
      { 
        id: 25, 
        name: "Bearing for JCB 8250", 
        price: 87, 
        description: "High-quality bearing for JCB 8250, designed for high-performance and minimal friction.", 
        image: "/images/jcb8250bearing.jpg" 
      }
  ],
  shafts: [
    { 
        id: 1, 
        name: "Steering Shaft for John Deere 7200", 
        price: 150, 
        description: "Durable steering shaft for John Deere 7200, designed for precise steering control and long-lasting use.", 
        image: "/images/johndeere7200steeringshaft.jpg" 
      },
      { 
        id: 2, 
        name: "Drive Shaft for Case IH Magnum", 
        price: 180, 
        description: "Heavy-duty drive shaft for Case IH Magnum, engineered for powerful torque transfer and durability.", 
        image: "/images/caseihmagnumdriveshaft.jpg" 
      },
      { 
        id: 3, 
        name: "Steering Shaft for Massey Ferguson 5600", 
        price: 140, 
        description: "Reliable steering shaft for Massey Ferguson 5600, providing smooth and responsive steering functionality.", 
        image: "/images/massey5600steeringshaft.jpg" 
      },
      { 
        id: 4, 
        name: "Drive Shaft for Kubota M Series", 
        price: 155, 
        description: "Precision drive shaft for Kubota M Series, ensuring efficient power delivery and reduced wear.", 
        image: "/images/kubotamseriesdriveshaft.jpg" 
      },
      { 
        id: 5, 
        name: "Steering Shaft for Ford 5000", 
        price: 135, 
        description: "High-quality steering shaft for Ford 5000, built to last under demanding conditions.", 
        image: "/images/ford5000steeringshaft.jpg" 
      },
      { 
        id: 6, 
        name: "Drive Shaft for Rolls Royce 1300", 
        price: 160, 
        description: "Premium drive shaft for Rolls Royce 1300, engineered for smooth performance and high reliability.", 
        image: "/images/rollsroyce1300driveshaft.jpg" 
      },
      { 
        id: 7, 
        name: "Steering Shaft for New Holland T7", 
        price: 145, 
        description: "High-performance steering shaft for New Holland T7, designed for precise steering control and durability.", 
        image: "/images/newhollandt7steeringshaft.jpg" 
      },
      { 
        id: 8, 
        name: "Drive Shaft for Deutz-Fahr 6200", 
        price: 170, 
        description: "Reliable drive shaft for Deutz-Fahr 6200, offering consistent torque transmission and reduced wear.", 
        image: "/images/deutzfahr6200driveshaft.jpg" 
      },
      { 
        id: 9, 
        name: "Steering Shaft for Claas Arion 500", 
        price: 150, 
        description: "Durable steering shaft for Claas Arion 500, ensuring stable and responsive steering performance.", 
        image: "/images/claasarion500steeringshaft.jpg" 
      },
      { 
        id: 10, 
        name: "Drive Shaft for Fendt 720 Vario", 
        price: 185, 
        description: "Precision drive shaft for Fendt 720 Vario, designed for high performance and minimal friction.", 
        image: "/images/fendt720variodriveshaft.jpg" 
      },
      { 
        id: 11, 
        name: "Steering Shaft for Zetor Forterra", 
        price: 140, 
        description: "High-quality steering shaft for Zetor Forterra, built for consistent and reliable steering performance.", 
        image: "/images/zetorforterrasteeringshaft.jpg" 
      },
      { 
        id: 12, 
        name: "Drive Shaft for Massey Ferguson 8700", 
        price: 165, 
        description: "Durable drive shaft for Massey Ferguson 8700, designed to handle powerful torque and reduce vibrations.", 
        image: "/images/massey8700driveshaft.jpg" 
      },
      { 
        id: 13, 
        name: "Steering Shaft for Valtra T Series", 
        price: 155, 
        description: "Reliable steering shaft for Valtra T Series, engineered for long-lasting durability and efficient steering control.", 
        image: "/images/valtratseriessteeringshaft.jpg" 
      },
      { 
        id: 14, 
        name: "Drive Shaft for Case IH Puma", 
        price: 160, 
        description: "Heavy-duty drive shaft for Case IH Puma, providing reliable torque transfer for high power machinery.", 
        image: "/images/caseihpumadriveshaft.jpg" 
      },
      { 
        id: 15, 
        name: "Steering Shaft for John Deere 8530", 
        price: 140, 
        description: "Sturdy steering shaft for John Deere 8530, designed for precision and high performance in harsh conditions.", 
        image: "/images/johndeere8530steeringshaft.jpg" 
      },
      { 
        id: 16, 
        name: "Drive Shaft for Kubota BX Series", 
        price: 150, 
        description: "Efficient drive shaft for Kubota BX Series, ensuring smooth operation and reduced wear over time.", 
        image: "/images/kubotabxseriesdriveshaft.jpg" 
      },
      { 
        id: 17, 
        name: "Steering Shaft for Case IH Steiger", 
        price: 170, 
        description: "Precision steering shaft for Case IH Steiger, designed for heavy-duty applications and durability.", 
        image: "/images/caseihsteigersteeringshaft.jpg" 
      },
      { 
        id: 18, 
        name: "Drive Shaft for New Holland TS Series", 
        price: 145, 
        description: "Reliable drive shaft for New Holland TS Series, engineered for smooth and efficient power delivery.", 
        image: "/images/newhollandtsseriesdriveshaft.jpg" 
      },
      { 
        id: 19, 
        name: "Steering Shaft for Massey Ferguson 3000 Series", 
        price: 135, 
        description: "Durable steering shaft for Massey Ferguson 3000 Series, built for high resilience and consistent performance.", 
        image: "/images/massey3000seriessteeringshaft.jpg" 
      },
      { 
        id: 20, 
        name: "Drive Shaft for Claas Xerion", 
        price: 180, 
        description: "High-performance drive shaft for Claas Xerion, designed for optimal torque transfer and efficiency.", 
        image: "/images/claasxeriondriveshaft.jpg" 
      },
      { 
        id: 21, 
        name: "Steering Shaft for Landini Rex", 
        price: 140, 
        description: "Reliable steering shaft for Landini Rex, designed for smooth operation and durability in tough conditions.", 
        image: "/images/landinirexsteeringshaft.jpg" 
      },
      { 
        id: 22, 
        name: "Drive Shaft for Case IH Magnum 340", 
        price: 195, 
        description: "High-quality drive shaft for Case IH Magnum 340, providing unmatched stability and long-lasting power transmission.", 
        image: "/images/caseihmagnum340driveshaft.jpg" 
      },
      { 
        id: 23, 
        name: "Steering Shaft for Valtra S Series", 
        price: 155, 
        description: "Durable steering shaft for Valtra S Series, built for superior control and reliable performance.", 
        image: "/images/valtrasseriessteeringshaft.jpg" 
      },
      { 
        id: 24, 
        name: "Drive Shaft for Deutz-Fahr Agrotron", 
        price: 150, 
        description: "High-quality drive shaft for Deutz-Fahr Agrotron, optimized for efficiency and reduced wear and tear.", 
        image: "/images/deutzfahragrotrondriveshaft.jpg" 
      },
      { 
        id: 25, 
        name: "Steering Shaft for JCB 8250", 
        price: 160, 
        description: "Sturdy steering shaft for JCB 8250, designed for high performance and extended use.", 
        image: "/images/jcb8250steeringshaft.jpg" 
      }
  ],
  hydraulic_pumps: [
    { 
        id: 1, 
        name: "Hydraulic Pump for John Deere 7200", 
        price: 300, 
        description: "High-performance hydraulic pump for John Deere 7200, designed for efficient fluid transfer and enhanced machinery performance.", 
        image: "/images/johndeere7200hydraulicpump.jpg" 
      },
      { 
        id: 2, 
        name: "Hydraulic Pump for Case IH Magnum", 
        price: 350, 
        description: "Heavy-duty hydraulic pump for Case IH Magnum, engineered for powerful hydraulic pressure and reliable operation.", 
        image: "/images/caseihmagnumhydraulicpump.jpg" 
      },
      { 
        id: 3, 
        name: "Hydraulic Pump for Massey Ferguson 5600", 
        price: 320, 
        description: "Durable hydraulic pump for Massey Ferguson 5600, built for continuous and efficient hydraulic power.", 
        image: "/images/massey5600hydraulicpump.jpg" 
      },
      { 
        id: 4, 
        name: "Hydraulic Pump for Kubota M Series", 
        price: 310, 
        description: "Reliable hydraulic pump for Kubota M Series, ensuring optimal pressure and smooth operation for all attachments.", 
        image: "/images/kubotamserieshydraulicpump.jpg" 
      },
      { 
        id: 5, 
        name: "Hydraulic Pump for Ford 5000", 
        price: 280, 
        description: "High-quality hydraulic pump for Ford 5000, designed for dependable performance and efficient fluid movement.", 
        image: "/images/ford5000hydraulicpump.jpg" 
      },
      { 
        id: 6, 
        name: "Hydraulic Pump for Rolls Royce 1300", 
        price: 340, 
        description: "Precision hydraulic pump for Rolls Royce 1300, crafted for high-pressure applications and long-lasting use.", 
        image: "/images/rollsroyce1300hydraulicpump.jpg" 
      },
      { 
        id: 7, 
        name: "Hydraulic Pump for New Holland T7", 
        price: 330, 
        description: "Efficient hydraulic pump for New Holland T7, ensuring high flow rate and reliable hydraulic performance.", 
        image: "/images/newhollandt7hydraulicpump.jpg" 
      },
      { 
        id: 8, 
        name: "Hydraulic Pump for Deutz-Fahr 6200", 
        price: 360, 
        description: "Robust hydraulic pump for Deutz-Fahr 6200, designed for heavy-duty work and consistent fluid pressure.", 
        image: "/images/deutzfahr6200hydraulicpump.jpg" 
      },
      { 
        id: 9, 
        name: "Hydraulic Pump for Claas Arion 500", 
        price: 310, 
        description: "Dependable hydraulic pump for Claas Arion 500, designed for reliable operation under tough conditions.", 
        image: "/images/claasarion500hydraulicpump.jpg" 
      },
      { 
        id: 10, 
        name: "Hydraulic Pump for Fendt 720 Vario", 
        price: 370, 
        description: "Advanced hydraulic pump for Fendt 720 Vario, engineered for optimal flow and pressure to power attachments.", 
        image: "/images/fendt720variovariohydraulicpump.jpg" 
      },
      { 
        id: 11, 
        name: "Hydraulic Pump for Zetor Forterra", 
        price: 300, 
        description: "High-performance hydraulic pump for Zetor Forterra, designed for smooth and efficient hydraulic system operations.", 
        image: "/images/zetorforterrahydraulicpump.jpg" 
      },
      { 
        id: 12, 
        name: "Hydraulic Pump for Massey Ferguson 8700", 
        price: 380, 
        description: "Premium hydraulic pump for Massey Ferguson 8700, providing optimal power and hydraulic fluid management.", 
        image: "/images/massey8700hydraulicpump.jpg" 
      },
      { 
        id: 13, 
        name: "Hydraulic Pump for Valtra T Series", 
        price: 340, 
        description: "Durable hydraulic pump for Valtra T Series, built for efficiency and reliable operation during heavy use.", 
        image: "/images/valtratserieshydraulicpump.jpg" 
      },
      { 
        id: 14, 
        name: "Hydraulic Pump for Case IH Puma", 
        price: 350, 
        description: "Heavy-duty hydraulic pump for Case IH Puma, optimized for high performance and minimal maintenance.", 
        image: "/images/caseihpumahydraulicpump.jpg" 
      },
      { 
        id: 15, 
        name: "Hydraulic Pump for John Deere 8530", 
        price: 320, 
        description: "Reliable hydraulic pump for John Deere 8530, designed for seamless power transfer and long-lasting efficiency.", 
        image: "/images/johndeere8530hydraulicpump.jpg" 
      },
      { 
        id: 16, 
        name: "Hydraulic Pump for Kubota BX Series", 
        price: 310, 
        description: "Efficient hydraulic pump for Kubota BX Series, ensuring optimal power and fluid management for compact machinery.", 
        image: "/images/kubotabxserieshydraulicpump.jpg" 
      },
      { 
        id: 17, 
        name: "Hydraulic Pump for Case IH Steiger", 
        price: 380, 
        description: "High-capacity hydraulic pump for Case IH Steiger, designed to deliver maximum pressure for heavy-duty use.", 
        image: "/images/caseihsteigerhydraulicpump.jpg" 
      },
      { 
        id: 18, 
        name: "Hydraulic Pump for New Holland TS Series", 
        price: 320, 
        description: "Dependable hydraulic pump for New Holland TS Series, engineered for reliability and optimal fluid flow.", 
        image: "/images/newhollandtsserieshydraulicpump.jpg" 
      },
      { 
        id: 19, 
        name: "Hydraulic Pump for Massey Ferguson 3000 Series", 
        price: 310, 
        description: "High-quality hydraulic pump for Massey Ferguson 3000 Series, designed for continuous and efficient operation.", 
        image: "/images/massey3000serieshydraulicpump.jpg" 
      },
      { 
        id: 20, 
        name: "Hydraulic Pump for Claas Xerion", 
        price: 370, 
        description: "Precision hydraulic pump for Claas Xerion, built for powerful and consistent hydraulic fluid delivery.", 
        image: "/images/claasxerionhydraulicpump.jpg" 
      },
      { 
        id: 21, 
        name: "Hydraulic Pump for Landini Rex", 
        price: 310, 
        description: "Reliable hydraulic pump for Landini Rex, designed for smooth operation and efficient fluid transfer.", 
        image: "/images/landinirexhydraulicpump.jpg" 
      },
      { 
        id: 22, 
        name: "Hydraulic Pump for Case IH Magnum 340", 
        price: 400, 
        description: "Premium hydraulic pump for Case IH Magnum 340, engineered for high power and fluid pressure capacity.", 
        image: "/images/caseihmagnum340hydraulicpump.jpg" 
      },
      { 
        id: 23, 
        name: "Hydraulic Pump for Valtra S Series", 
        price: 350, 
        description: "Durable hydraulic pump for Valtra S Series, designed for enhanced reliability and efficient power transmission.", 
        image: "/images/valtrasserieshydraulicpump.jpg" 
      },
      { 
        id: 24, 
        name: "Hydraulic Pump for Deutz-Fahr Agrotron", 
        price: 320, 
        description: "High-quality hydraulic pump for Deutz-Fahr Agrotron, designed for long-term performance and minimal wear.", 
        image: "/images/deutzfahragrotrondriveshaft.jpg" 
      },
      { 
        id: 25, 
        name: "Hydraulic Pump for JCB 8250", 
        price: 340, 
        description: "Reliable hydraulic pump for JCB 8250, designed for consistent and high-pressure fluid delivery.", 
        image: "/images/jcb8250hydraulicpump.jpg" 
      }
  ],
  hydraulicCylinders: [
    { 
        id: 1, 
        name: "Hydraulic Cylinder for John Deere 7200", 
        price: 450, 
        description: "Heavy-duty hydraulic cylinder for John Deere 7200, designed for high pressure and durability in tough conditions.", 
        image: "/images/johndeere7200hydrauliccylinder.jpg" 
      },
      { 
        id: 2, 
        name: "Hydraulic Cylinder for Case IH Magnum", 
        price: 470, 
        description: "Robust hydraulic cylinder for Case IH Magnum, engineered for maximum pressure and reliable operation.", 
        image: "/images/caseihmagnumhydrauliccylinder.jpg" 
      },
      { 
        id: 3, 
        name: "Hydraulic Cylinder for Massey Ferguson 5600", 
        price: 430, 
        description: "High-performance hydraulic cylinder for Massey Ferguson 5600, ensuring smooth and powerful hydraulic function.", 
        image: "/images/massey5600hydrauliccylinder.jpg" 
      },
      { 
        id: 4, 
        name: "Hydraulic Cylinder for Kubota M Series", 
        price: 440, 
        description: "Reliable hydraulic cylinder for Kubota M Series, built for consistent performance under high load conditions.", 
        image: "/images/kubotamserieshydrauliccylinder.jpg" 
      },
      { 
        id: 5, 
        name: "Hydraulic Cylinder for Ford 5000", 
        price: 420, 
        description: "Durable hydraulic cylinder for Ford 5000, designed for dependable operation and long-lasting use.", 
        image: "/images/ford5000hydrauliccylinder.jpg" 
      },
      { 
        id: 6, 
        name: "Hydraulic Cylinder for Rolls Royce 1300", 
        price: 460, 
        description: "Precision hydraulic cylinder for Rolls Royce 1300, crafted for high efficiency and minimal wear.", 
        image: "/images/rollsroyce1300hydrauliccylinder.jpg" 
      },
      { 
        id: 7, 
        name: "Hydraulic Cylinder for New Holland T7", 
        price: 450, 
        description: "High-capacity hydraulic cylinder for New Holland T7, designed to handle high hydraulic pressure efficiently.", 
        image: "/images/newhollandt7hydrauliccylinder.jpg" 
      },
      { 
        id: 8, 
        name: "Hydraulic Cylinder for Deutz-Fahr 6200", 
        price: 480, 
        description: "Heavy-duty hydraulic cylinder for Deutz-Fahr 6200, built to withstand demanding agricultural tasks.", 
        image: "/images/deutzfahr6200hydrauliccylinder.jpg" 
      },
      { 
        id: 9, 
        name: "Hydraulic Cylinder for Claas Arion 500", 
        price: 440, 
        description: "Reliable hydraulic cylinder for Claas Arion 500, designed for long-lasting and efficient operation.", 
        image: "/images/claasarion500hydrauliccylinder.jpg" 
      },
      { 
        id: 10, 
        name: "Hydraulic Cylinder for Fendt 720 Vario", 
        price: 490, 
        description: "Advanced hydraulic cylinder for Fendt 720 Vario, designed for high-performance and smooth hydraulic action.", 
        image: "/images/fendt720variovariohydrauliccylinder.jpg" 
      },
      { 
        id: 11, 
        name: "Hydraulic Cylinder for Zetor Forterra", 
        price: 430, 
        description: "Dependable hydraulic cylinder for Zetor Forterra, built to deliver consistent performance in challenging conditions.", 
        image: "/images/zetorforterrahydrauliccylinder.jpg" 
      },
      { 
        id: 12, 
        name: "Hydraulic Cylinder for Massey Ferguson 8700", 
        price: 500, 
        description: "Heavy-duty hydraulic cylinder for Massey Ferguson 8700, ensuring powerful and consistent hydraulic performance.", 
        image: "/images/massey8700hydrauliccylinder.jpg" 
      },
      { 
        id: 13, 
        name: "Hydraulic Cylinder for Valtra T Series", 
        price: 470, 
        description: "Robust hydraulic cylinder for Valtra T Series, engineered for high performance under demanding conditions.", 
        image: "/images/valtratserieshydrauliccylinder.jpg" 
      },
      { 
        id: 14, 
        name: "Hydraulic Cylinder for Case IH Puma", 
        price: 460, 
        description: "Precision hydraulic cylinder for Case IH Puma, designed for seamless hydraulic power transfer.", 
        image: "/images/caseihpumahydrauliccylinder.jpg" 
      },
      { 
        id: 15, 
        name: "Hydraulic Cylinder for John Deere 8530", 
        price: 450, 
        description: "Durable hydraulic cylinder for John Deere 8530, ensuring consistent and smooth hydraulic function.", 
        image: "/images/johndeere8530hydrauliccylinder.jpg" 
      },
      { 
        id: 16, 
        name: "Hydraulic Cylinder for Kubota BX Series", 
        price: 440, 
        description: "High-quality hydraulic cylinder for Kubota BX Series, designed for efficient and reliable operation.", 
        image: "/images/kubotabxserieshydrauliccylinder.jpg" 
      },
      { 
        id: 17, 
        name: "Hydraulic Cylinder for Case IH Steiger", 
        price: 500, 
        description: "Heavy-duty hydraulic cylinder for Case IH Steiger, designed for maximum hydraulic power under tough conditions.", 
        image: "/images/caseihsteigerhydrauliccylinder.jpg" 
      },
      { 
        id: 18, 
        name: "Hydraulic Cylinder for New Holland TS Series", 
        price: 430, 
        description: "Reliable hydraulic cylinder for New Holland TS Series, designed for consistent and efficient hydraulic function.", 
        image: "/images/newhollandtsserieshydrauliccylinder.jpg" 
      },
      { 
        id: 19, 
        name: "Hydraulic Cylinder for Massey Ferguson 3000 Series", 
        price: 440, 
        description: "High-performance hydraulic cylinder for Massey Ferguson 3000 Series, designed for optimal power and pressure.", 
        image: "/images/massey3000serieshydrauliccylinder.jpg" 
      },
      { 
        id: 20, 
        name: "Hydraulic Cylinder for Claas Xerion", 
        price: 480, 
        description: "High-capacity hydraulic cylinder for Claas Xerion, engineered for efficient hydraulic fluid transfer.", 
        image: "/images/claasxerionhydrauliccylinder.jpg" 
      },
      { 
        id: 21, 
        name: "Hydraulic Cylinder for Landini Rex", 
        price: 450, 
        description: "Dependable hydraulic cylinder for Landini Rex, built to deliver consistent and smooth hydraulic power.", 
        image: "/images/landinirexhydrauliccylinder.jpg" 
      },
      { 
        id: 22, 
        name: "Hydraulic Cylinder for Case IH Magnum 340", 
        price: 500, 
        description: "Heavy-duty hydraulic cylinder for Case IH Magnum 340, designed for maximum pressure and durability.", 
        image: "/images/caseihmagnum340hydrauliccylinder.jpg" 
      },
      { 
        id: 23, 
        name: "Hydraulic Cylinder for Valtra S Series", 
        price: 470, 
        description: "Reliable hydraulic cylinder for Valtra S Series, optimized for high pressure and long-term use.", 
        image: "/images/valtrasserieshydrauliccylinder.jpg" 
      },
      { 
        id: 24, 
        name: "Hydraulic Cylinder for Deutz-Fahr Agrotron", 
        price: 450, 
        description: "Precision hydraulic cylinder for Deutz-Fahr Agrotron, ensuring high efficiency and minimal wear.", 
        image: "/images/deutzfahragrotrondriveshaft.jpg" 
      },
      { 
        id: 25, 
        name: "Hydraulic Cylinder for JCB 8250", 
        price: 460, 
        description: "High-performance hydraulic cylinder for JCB 8250, designed for reliable and consistent hydraulic pressure.", 
        image: "/images/jcb8250hydrauliccylinder.jpg" 
      }
  ],
  valves: [
    { 
        id: 1, 
        name: "Valve for John Deere 7200", 
        price: 150, 
        description: "High-quality valve for John Deere 7200, engineered for optimal performance and reliability.", 
        image: "/images/johndeere7200valve.jpg" 
      },
      { 
        id: 2, 
        name: "Valve for Case IH Magnum", 
        price: 160, 
        description: "Durable valve for Case IH Magnum, designed to withstand high pressures and ensure smooth operation.", 
        image: "/images/caseihmagnumvalve.jpg" 
      },
      { 
        id: 3, 
        name: "Valve for Massey Ferguson 5600", 
        price: 140, 
        description: "Precision valve for Massey Ferguson 5600, offering consistent and efficient hydraulic control.", 
        image: "/images/massey5600valve.jpg" 
      },
      { 
        id: 4, 
        name: "Valve for Kubota M Series", 
        price: 155, 
        description: "Reliable valve for Kubota M Series, designed for high durability and effective performance under load.", 
        image: "/images/kubotamseriesvalve.jpg" 
      },
      { 
        id: 5, 
        name: "Valve for Ford 5000", 
        price: 145, 
        description: "Durable valve for Ford 5000, engineered for long-lasting and efficient hydraulic function.", 
        image: "/images/ford5000valve.jpg" 
      },
      { 
        id: 6, 
        name: "Valve for Rolls Royce 1300", 
        price: 165, 
        description: "High-performance valve for Rolls Royce 1300, designed for consistent and reliable hydraulic control.", 
        image: "/images/rollsroyce1300valve.jpg" 
      },
      { 
        id: 7, 
        name: "Valve for New Holland T7", 
        price: 150, 
        description: "High-capacity valve for New Holland T7, optimized for seamless fluid transfer and durability.", 
        image: "/images/newhollandt7valve.jpg" 
      },
      { 
        id: 8, 
        name: "Valve for Deutz-Fahr 6200", 
        price: 160, 
        description: "Robust valve for Deutz-Fahr 6200, designed to provide consistent pressure and performance.", 
        image: "/images/deutzfahr6200valve.jpg" 
      },
      { 
        id: 9, 
        name: "Valve for Claas Arion 500", 
        price: 155, 
        description: "Dependable valve for Claas Arion 500, built for high performance and long-term use under pressure.", 
        image: "/images/claasarion500valve.jpg" 
      },
      { 
        id: 10, 
        name: "Valve for Fendt 720 Vario", 
        price: 170, 
        description: "Precision valve for Fendt 720 Vario, designed for high-pressure fluid regulation and durability.", 
        image: "/images/fendt720variovariovalve.jpg" 
      },
      { 
        id: 11, 
        name: "Valve for Zetor Forterra", 
        price: 140, 
        description: "Reliable valve for Zetor Forterra, designed for smooth hydraulic control and consistent performance.", 
        image: "/images/zetorforterravalve.jpg" 
      },
      { 
        id: 12, 
        name: "Valve for Massey Ferguson 8700", 
        price: 180, 
        description: "High-quality valve for Massey Ferguson 8700, optimized for heavy-duty use and reliable hydraulic control.", 
        image: "/images/massey8700valve.jpg" 
      },
      { 
        id: 13, 
        name: "Valve for Valtra T Series", 
        price: 165, 
        description: "Advanced valve for Valtra T Series, designed for superior performance and resistance to wear.", 
        image: "/images/valtratseriesvalve.jpg" 
      },
      { 
        id: 14, 
        name: "Valve for Case IH Puma", 
        price: 155, 
        description: "Robust valve for Case IH Puma, ensuring high efficiency and seamless hydraulic fluid management.", 
        image: "/images/caseihpumavalve.jpg" 
      },
      { 
        id: 15, 
        name: "Valve for John Deere 8530", 
        price: 150, 
        description: "Dependable valve for John Deere 8530, designed for optimal hydraulic pressure regulation.", 
        image: "/images/johndeere8530valve.jpg" 
      },
      { 
        id: 16, 
        name: "Valve for Kubota BX Series", 
        price: 145, 
        description: "High-performance valve for Kubota BX Series, built to ensure efficient hydraulic operation.", 
        image: "/images/kubotabxseriesvalve.jpg" 
      },
      { 
        id: 17, 
        name: "Valve for Case IH Steiger", 
        price: 170, 
        description: "Heavy-duty valve for Case IH Steiger, engineered for high capacity and exceptional pressure control.", 
        image: "/images/caseihsteigervalve.jpg" 
      },
      { 
        id: 18, 
        name: "Valve for New Holland TS Series", 
        price: 140, 
        description: "Reliable valve for New Holland TS Series, designed for consistent fluid management under pressure.", 
        image: "/images/newhollandtsseriesvalve.jpg" 
      },
      { 
        id: 19, 
        name: "Valve for Massey Ferguson 3000 Series", 
        price: 150, 
        description: "High-quality valve for Massey Ferguson 3000 Series, designed for long-lasting and smooth operation.", 
        image: "/images/massey3000seriesvalve.jpg" 
      },
      { 
        id: 20, 
        name: "Valve for Claas Xerion", 
        price: 160, 
        description: "Advanced valve for Claas Xerion, optimized for powerful and efficient hydraulic fluid control.", 
        image: "/images/claasxerionvalve.jpg" 
      },
      { 
        id: 21, 
        name: "Valve for Landini Rex", 
        price: 150, 
        description: "Durable valve for Landini Rex, built for reliable hydraulic operation and consistent performance.", 
        image: "/images/landinirexvalve.jpg" 
      },
      { 
        id: 22, 
        name: "Valve for Case IH Magnum 340", 
        price: 170, 
        description: "Heavy-duty valve for Case IH Magnum 340, designed for superior performance and pressure management.", 
        image: "/images/caseihmagnum340valve.jpg" 
      },
      { 
        id: 23, 
        name: "Valve for Valtra S Series", 
        price: 165, 
        description: "Reliable valve for Valtra S Series, engineered for high-pressure endurance and long-term use.", 
        image: "/images/valtrasseriesvalve.jpg" 
      },
      { 
        id: 24, 
        name: "Valve for Deutz-Fahr Agrotron", 
        price: 150, 
        description: "Precision valve for Deutz-Fahr Agrotron, designed for high efficiency and seamless operation.", 
        image: "/images/deutzfahragrotronvalve.jpg" 
      },
      { 
        id: 25, 
        name: "Valve for JCB 8250", 
        price: 155, 
        description: "Durable valve for JCB 8250, designed for smooth hydraulic operation and high performance.", 
        image: "/images/jcb8250valve.jpg" 
      }
  ],
  hosesAndFittings: [
    { 
        id: 1, 
        name: "Hydraulic Hose for John Deere 7200", 
        price: 75, 
        description: "High-quality hydraulic hose for John Deere 7200, designed for efficient fluid transfer and durability.", 
        image: "/images/johndeere7200hose.jpg" 
      },
      { 
        id: 2, 
        name: "Hydraulic Fitting for Case IH Magnum", 
        price: 45, 
        description: "Durable hydraulic fitting for Case IH Magnum, providing secure connections and leak-proof performance.", 
        image: "/images/caseihmagnumfitting.jpg" 
      },
      { 
        id: 3, 
        name: "High-Pressure Hose for Massey Ferguson 5600", 
        price: 80, 
        description: "Heavy-duty high-pressure hose for Massey Ferguson 5600, built for reliable and long-lasting use.", 
        image: "/images/massey5600hose.jpg" 
      },
      { 
        id: 4, 
        name: "Hydraulic Hose Fitting for Kubota M Series", 
        price: 50, 
        description: "Precision hydraulic hose fitting for Kubota M Series, designed to ensure leak-free and secure connections.", 
        image: "/images/kubotamseriesfitting.jpg" 
      },
      { 
        id: 5, 
        name: "Flexible Hydraulic Hose for Ford 5000", 
        price: 70, 
        description: "Flexible hydraulic hose for Ford 5000, engineered for superior pressure resistance and performance.", 
        image: "/images/ford5000hose.jpg" 
      },
      { 
        id: 6, 
        name: "High-Performance Hose for Rolls Royce 1300", 
        price: 90, 
        description: "High-performance hydraulic hose for Rolls Royce 1300, ensuring top-notch fluid transfer and durability.", 
        image: "/images/rollsroyce1300hose.jpg" 
      },
      { 
        id: 7, 
        name: "Heavy-Duty Hydraulic Hose for New Holland T7", 
        price: 85, 
        description: "Heavy-duty hydraulic hose for New Holland T7, optimized for high-pressure fluid transfer and reliability.", 
        image: "/images/newhollandt7hose.jpg" 
      },
      { 
        id: 8, 
        name: "Hydraulic Hose Fitting for Deutz-Fahr 6200", 
        price: 55, 
        description: "High-quality hydraulic hose fitting for Deutz-Fahr 6200, providing leak-proof connections and lasting durability.", 
        image: "/images/deutzfahr6200fitting.jpg" 
      },
      { 
        id: 9, 
        name: "Hydraulic Hose for Claas Arion 500", 
        price: 78, 
        description: "Flexible hydraulic hose for Claas Arion 500, designed for high durability and efficient fluid management.", 
        image: "/images/claasarion500hose.jpg" 
      },
      { 
        id: 10, 
        name: "Hydraulic Fitting for Fendt 720 Vario", 
        price: 60, 
        description: "Reliable hydraulic fitting for Fendt 720 Vario, engineered for high pressure and leak-resistant functionality.", 
        image: "/images/fendt720variovalve.jpg" 
      },
      { 
        id: 11, 
        name: "Hydraulic Hose for Zetor Forterra", 
        price: 72, 
        description: "High-quality hydraulic hose for Zetor Forterra, built for smooth fluid transfer and extended lifespan.", 
        image: "/images/zetorforterrahose.jpg" 
      },
      { 
        id: 12, 
        name: "Hydraulic Fitting for Massey Ferguson 8700", 
        price: 48, 
        description: "Durable hydraulic fitting for Massey Ferguson 8700, ensuring a secure and leak-proof connection.", 
        image: "/images/massey8700fitting.jpg" 
      },
      { 
        id: 13, 
        name: "Flexible Hydraulic Hose for Valtra T Series", 
        price: 77, 
        description: "Flexible hydraulic hose for Valtra T Series, designed for reliable operation under high pressure.", 
        image: "/images/valtratserieshose.jpg" 
      },
      { 
        id: 14, 
        name: "Hydraulic Hose Fitting for Case IH Puma", 
        price: 52, 
        description: "High-quality hydraulic hose fitting for Case IH Puma, designed for durability and leak-free performance.", 
        image: "/images/caseihpumafitting.jpg" 
      },
      { 
        id: 15, 
        name: "Heavy-Duty Hose for John Deere 8530", 
        price: 88, 
        description: "Heavy-duty hydraulic hose for John Deere 8530, ensuring top performance and extended service life.", 
        image: "/images/johndeere8530hose.jpg" 
      },
      { 
        id: 16, 
        name: "Hydraulic Hose for Kubota BX Series", 
        price: 65, 
        description: "Reliable hydraulic hose for Kubota BX Series, engineered for high durability and performance.", 
        image: "/images/kubotabxserieshose.jpg" 
      },
      { 
        id: 17, 
        name: "High-Pressure Hose for Case IH Steiger", 
        price: 95, 
        description: "High-pressure hydraulic hose for Case IH Steiger, built to handle heavy-duty applications with ease.", 
        image: "/images/caseihsteigerhose.jpg" 
      },
      { 
        id: 18, 
        name: "Hydraulic Hose Fitting for New Holland TS Series", 
        price: 50, 
        description: "Durable hydraulic hose fitting for New Holland TS Series, ensuring a secure and tight seal.", 
        image: "/images/newhollandtsseriesfitting.jpg" 
      },
      { 
        id: 19, 
        name: "Hydraulic Hose for Massey Ferguson 3000 Series", 
        price: 78, 
        description: "Reliable hydraulic hose for Massey Ferguson 3000 Series, built for pressure stability and long-term use.", 
        image: "/images/massey3000serieshose.jpg" 
      },
      { 
        id: 20, 
        name: "Hydraulic Fitting for Claas Xerion", 
        price: 53, 
        description: "Durable hydraulic fitting for Claas Xerion, designed for high-pressure endurance and leak-proof sealing.", 
        image: "/images/claasxerionfitting.jpg" 
      },
      { 
        id: 21, 
        name: "Flexible Hydraulic Hose for Landini Rex", 
        price: 67, 
        description: "Flexible hydraulic hose for Landini Rex, offering smooth fluid flow and high-pressure resistance.", 
        image: "/images/landinirexhose.jpg" 
      },
      { 
        id: 22, 
        name: "High-Pressure Hydraulic Hose for Case IH Magnum 340", 
        price: 98, 
        description: "High-pressure hydraulic hose for Case IH Magnum 340, designed for superior durability and long-term reliability.", 
        image: "/images/caseihmagnum340hose.jpg" 
      },
      { 
        id: 23, 
        name: "Hydraulic Fitting for Valtra S Series", 
        price: 60, 
        description: "High-quality hydraulic fitting for Valtra S Series, built for pressure resistance and reliable connections.", 
        image: "/images/valtrasseriesfitting.jpg" 
      },
      { 
        id: 24, 
        name: "Hydraulic Hose for Deutz-Fahr Agrotron", 
        price: 74, 
        description: "Reliable hydraulic hose for Deutz-Fahr Agrotron, optimized for long-lasting performance and pressure stability.", 
        image: "/images/deutzfahragrotronhose.jpg" 
      },
      { 
        id: 25, 
        name: "Hydraulic Fitting for JCB 8250", 
        price: 55, 
        description: "Durable hydraulic fitting for JCB 8250, providing secure and leak-proof connections for hydraulic systems.", 
        image: "/images/jcb8250fitting.jpg" 
      }
  ],
  filters: [
    { 
        id: 1, 
        name: "Oil Filter for John Deere 7200", 
        price: 30, 
        description: "High-quality oil filter for John Deere 7200, ensuring optimal engine lubrication and performance.", 
        image: "/images/johndeere7200oilfilter.jpg" 
      },
      { 
        id: 2, 
        name: "Air Filter for Case IH Magnum", 
        price: 25, 
        description: "Durable air filter for Case IH Magnum, providing clean air intake and engine protection.", 
        image: "/images/caseihmagnumairfilter.jpg" 
      },
      { 
        id: 3, 
        name: "Fuel Filter for Massey Ferguson 5600", 
        price: 28, 
        description: "High-quality fuel filter for Massey Ferguson 5600, designed to ensure clean fuel supply to the engine.", 
        image: "/images/massey5600fuelfilter.jpg" 
      },
      { 
        id: 4, 
        name: "Hydraulic Filter for Kubota M Series", 
        price: 35, 
        description: "Reliable hydraulic filter for Kubota M Series, ensuring efficient hydraulic system operation.", 
        image: "/images/kubotamserieshydraulicfilter.jpg" 
      },
      { 
        id: 5, 
        name: "Fuel Filter for Ford 5000", 
        price: 22, 
        description: "Durable fuel filter for Ford 5000, built for maintaining optimal fuel cleanliness and engine performance.", 
        image: "/images/ford5000fuelfilter.jpg" 
      },
      { 
        id: 6, 
        name: "Oil Filter for Rolls Royce 1300", 
        price: 32, 
        description: "High-performance oil filter for Rolls Royce 1300, designed for superior engine protection and oil circulation.", 
        image: "/images/rollsroyce1300oilfilter.jpg" 
      },
      { 
        id: 7, 
        name: "Electric Filter for John Deere 8530", 
        price: 40, 
        description: "Electric filter for John Deere 8530, designed to keep electrical systems free of contaminants and maintain function.", 
        image: "/images/johndeere8530electricfilter.jpg" 
      },
      { 
        id: 8, 
        name: "Air Filter for New Holland T7", 
        price: 27, 
        description: "Premium air filter for New Holland T7, designed for superior filtration and engine performance.", 
        image: "/images/newhollandt7airfilter.jpg" 
      },
      { 
        id: 9, 
        name: "Hydraulic Filter for Deutz-Fahr 6200", 
        price: 33, 
        description: "High-quality hydraulic filter for Deutz-Fahr 6200, ensuring smooth and efficient operation of hydraulic systems.", 
        image: "/images/deutzfahr6200hydraulicfilter.jpg" 
      },
      { 
        id: 10, 
        name: "Fuel Filter for Claas Arion 500", 
        price: 24, 
        description: "Reliable fuel filter for Claas Arion 500, designed to maintain fuel purity and enhance engine longevity.", 
        image: "/images/claasarion500fuelfilter.jpg" 
      },
      { 
        id: 11, 
        name: "Oil Filter for Fendt 720 Vario", 
        price: 31, 
        description: "Premium oil filter for Fendt 720 Vario, providing enhanced filtration and superior engine protection.", 
        image: "/images/fendt720variooilfilter.jpg" 
      },
      { 
        id: 12, 
        name: "Air Filter for Zetor Forterra", 
        price: 23, 
        description: "High-quality air filter for Zetor Forterra, designed for effective dust and debris filtration.", 
        image: "/images/zetorforterraairfilter.jpg" 
      },
      { 
        id: 13, 
        name: "Fuel Filter for Massey Ferguson 8700", 
        price: 26, 
        description: "Durable fuel filter for Massey Ferguson 8700, ensuring clean fuel supply for peak engine performance.", 
        image: "/images/massey8700fuelfilter.jpg" 
      },
      { 
        id: 14, 
        name: "Hydraulic Filter for Valtra T Series", 
        price: 34, 
        description: "Reliable hydraulic filter for Valtra T Series, optimized for extended use and system efficiency.", 
        image: "/images/valtratserieshydraulicfilter.jpg" 
      },
      { 
        id: 15, 
        name: "Oil Filter for Case IH Steiger", 
        price: 29, 
        description: "High-quality oil filter for Case IH Steiger, designed for reliable oil filtration and engine protection.", 
        image: "/images/caseihsteigeroilfilter.jpg" 
      },
      { 
        id: 16, 
        name: "Electric Filter for Landini Rex", 
        price: 36, 
        description: "Efficient electric filter for Landini Rex, protecting the electrical components from contaminants and debris.", 
        image: "/images/landinirexelectricfilter.jpg" 
      },
      { 
        id: 17, 
        name: "Hydraulic Filter for JCB 8250", 
        price: 38, 
        description: "Durable hydraulic filter for JCB 8250, ensuring consistent performance and protection of hydraulic systems.", 
        image: "/images/jcb8250hydraulicfilter.jpg" 
      },
      { 
        id: 18, 
        name: "Fuel Filter for Valtra S Series", 
        price: 21, 
        description: "Reliable fuel filter for Valtra S Series, ensuring a clean and efficient fuel flow for optimal performance.", 
        image: "/images/valtraseriesfuelfilter.jpg" 
      },
      { 
        id: 19, 
        name: "Oil Filter for New Holland TS Series", 
        price: 30, 
        description: "High-quality oil filter for New Holland TS Series, designed to provide effective oil filtration and engine protection.", 
        image: "/images/newhollandtsoilfilter.jpg" 
      },
      { 
        id: 20, 
        name: "Hydraulic Filter for Deutz-Fahr Agrotron", 
        price: 32, 
        description: "Reliable hydraulic filter for Deutz-Fahr Agrotron, providing efficient filtration and maintaining hydraulic performance.", 
        image: "/images/deutzfahragrotronhydraulicfilter.jpg" 
      },
      { 
        id: 21, 
        name: "Air Filter for Kubota BX Series", 
        price: 22, 
        description: "High-performance air filter for Kubota BX Series, ensuring optimal air intake and engine efficiency.", 
        image: "/images/kubotabxseriesairfilter.jpg" 
      },
      { 
        id: 22, 
        name: "Fuel Filter for Case IH Magnum 340", 
        price: 25, 
        description: "Durable fuel filter for Case IH Magnum 340, built for long-lasting use and maintaining engine health.", 
        image: "/images/caseihmagnum340fuelfilter.jpg" 
      },
      { 
        id: 23, 
        name: "Oil Filter for John Deere 8530", 
        price: 29, 
        description: "High-quality oil filter for John Deere 8530, providing superior filtration and oil protection for the engine.", 
        image: "/images/johndeere8530oilfilter.jpg" 
      },
      { 
        id: 24, 
        name: "Hydraulic Filter for Fendt 720 Vario", 
        price: 37, 
        description: "Durable hydraulic filter for Fendt 720 Vario, ensuring smooth and efficient hydraulic system operation.", 
        image: "/images/fendt720variohydraulicfilter.jpg" 
      },
      { 
        id: 25, 
        name: "Electric Filter for Claas Xerion", 
        price: 34, 
        description: "Electric filter for Claas Xerion, protecting the electrical components from dust and other particles.", 
        image: "/images/claasxerionelectricfilter.jpg" 
      }
  ],
  alternators: [
    { 
        id: 1, 
        name: "Alternator for John Deere 7200", 
        price: 450, 
        description: "High-efficiency alternator for John Deere 7200, providing reliable power generation for all electrical systems.", 
        image: "/images/johndeere7200alternator.jpg" 
      },
      { 
        id: 2, 
        name: "Alternator for Case IH Magnum", 
        price: 475, 
        description: "Durable alternator for Case IH Magnum, designed for consistent power output and high performance.", 
        image: "/images/caseihmagnumalternator.jpg" 
      },
      { 
        id: 3, 
        name: "Alternator for Massey Ferguson 5600", 
        price: 430, 
        description: "Reliable alternator for Massey Ferguson 5600, ensuring a steady power supply for all operational needs.", 
        image: "/images/massey5600alternator.jpg" 
      },
      { 
        id: 4, 
        name: "Alternator for Kubota M Series", 
        price: 460, 
        description: "High-performance alternator for Kubota M Series, built for durability and long-term use.", 
        image: "/images/kubotamseriesalternator.jpg" 
      },
      { 
        id: 5, 
        name: "Alternator for Ford 5000", 
        price: 420, 
        description: "Strong and reliable alternator for Ford 5000, providing dependable electrical support for your machine.", 
        image: "/images/ford5000alternator.jpg" 
      },
      { 
        id: 6, 
        name: "Alternator for Rolls Royce 1300", 
        price: 495, 
        description: "Top-quality alternator for Rolls Royce 1300, ensuring continuous and stable electrical power delivery.", 
        image: "/images/rollsroyce1300alternator.jpg" 
      },
      { 
        id: 7, 
        name: "Alternator for John Deere 8530", 
        price: 510, 
        description: "Heavy-duty alternator for John Deere 8530, designed for peak power generation and operational reliability.", 
        image: "/images/johndeere8530alternator.jpg" 
      },
      { 
        id: 8, 
        name: "Alternator for New Holland T7", 
        price: 440, 
        description: "High-capacity alternator for New Holland T7, offering excellent power performance and longevity.", 
        image: "/images/newhollandt7alternator.jpg" 
      },
      { 
        id: 9, 
        name: "Alternator for Deutz-Fahr 6200", 
        price: 480, 
        description: "Efficient alternator for Deutz-Fahr 6200, providing consistent voltage and a strong electrical system.", 
        image: "/images/deutzfahr6200alternator.jpg" 
      },
      { 
        id: 10, 
        name: "Alternator for Claas Arion 500", 
        price: 425, 
        description: "Durable alternator for Claas Arion 500, ensuring a steady electrical supply for optimal performance.", 
        image: "/images/claasarion500alternator.jpg" 
      },
      { 
        id: 11, 
        name: "Alternator for Fendt 720 Vario", 
        price: 490, 
        description: "Reliable alternator for Fendt 720 Vario, designed to provide uninterrupted power for all tractor functions.", 
        image: "/images/fendt720varioalternator.jpg" 
      },
      { 
        id: 12, 
        name: "Alternator for Zetor Forterra", 
        price: 415, 
        description: "Strong alternator for Zetor Forterra, providing consistent and efficient electrical output.", 
        image: "/images/zetorforterraalternator.jpg" 
      },
      { 
        id: 13, 
        name: "Alternator for Massey Ferguson 8700", 
        price: 475, 
        description: "High-performance alternator for Massey Ferguson 8700, designed for maximum reliability and power generation.", 
        image: "/images/massey8700alternator.jpg" 
      },
      { 
        id: 14, 
        name: "Alternator for Valtra T Series", 
        price: 460, 
        description: "Premium alternator for Valtra T Series, known for efficient power output and dependable performance.", 
        image: "/images/valtratseriesalternator.jpg" 
      },
      { 
        id: 15, 
        name: "Alternator for Case IH Steiger", 
        price: 480, 
        description: "Heavy-duty alternator for Case IH Steiger, built for the toughest jobs and highest reliability.", 
        image: "/images/caseihsteigeralternator.jpg" 
      },
      { 
        id: 16, 
        name: "Alternator for Landini Rex", 
        price: 455, 
        description: "Reliable alternator for Landini Rex, ensuring efficient power management and consistent operation.", 
        image: "/images/landinirexalternator.jpg" 
      },
      { 
        id: 17, 
        name: "Alternator for JCB 8250", 
        price: 485, 
        description: "Robust alternator for JCB 8250, designed for reliable and efficient energy conversion.", 
        image: "/images/jcb8250alternator.jpg" 
      },
      { 
        id: 18, 
        name: "Alternator for Valtra S Series", 
        price: 430, 
        description: "Strong alternator for Valtra S Series, built for consistent electrical output and machine performance.", 
        image: "/images/valtrasseriesalternator.jpg" 
      },
      { 
        id: 19, 
        name: "Alternator for New Holland TS Series", 
        price: 440, 
        description: "High-capacity alternator for New Holland TS Series, ensuring continuous and efficient power supply.", 
        image: "/images/newhollandtsseriesalternator.jpg" 
      },
      { 
        id: 20, 
        name: "Alternator for Deutz-Fahr Agrotron", 
        price: 470, 
        description: "Powerful alternator for Deutz-Fahr Agrotron, providing reliable power and electrical system efficiency.", 
        image: "/images/deutzfahragrotronalternator.jpg" 
      },
      { 
        id: 21, 
        name: "Alternator for Kubota BX Series", 
        price: 425, 
        description: "Durable alternator for Kubota BX Series, built to maintain power and enhance machine performance.", 
        image: "/images/kubotabxseriesalternator.jpg" 
      },
      { 
        id: 22, 
        name: "Alternator for Case IH Magnum 340", 
        price: 500, 
        description: "Heavy-duty alternator for Case IH Magnum 340, designed for maximum electrical output and reliability.", 
        image: "/images/caseihmagnum340alternator.jpg" 
      },
      { 
        id: 23, 
        name: "Alternator for John Deere 8530", 
        price: 510, 
        description: "High-efficiency alternator for John Deere 8530, providing optimal power generation for agricultural operations.", 
        image: "/images/johndeere8530alternator.jpg" 
      },
      { 
        id: 24, 
        name: "Alternator for Fendt 720 Vario", 
        price: 495, 
        description: "Reliable alternator for Fendt 720 Vario, ensuring efficient and continuous power for all functions.", 
        image: "/images/fendt720varioalternator2.jpg" 
      },
      { 
        id: 25, 
        name: "Alternator for Claas Xerion", 
        price: 480, 
        description: "Strong alternator for Claas Xerion, designed for efficient power generation and long-lasting use.", 
        image: "/images/claasxerionalternator.jpg" 
      }
  ],
  starters: [
    { 
        id: 1, 
        name: "Starter for John Deere 7200", 
        price: 300, 
        description: "High-performance starter for John Deere 7200, ensuring reliable engine start-up under all conditions.", 
        image: "/images/johndeere7200starter.jpg" 
      },
      { 
        id: 2, 
        name: "Starter for Case IH Magnum", 
        price: 320, 
        description: "Durable starter for Case IH Magnum, designed for smooth and efficient engine starts.", 
        image: "/images/caseihmagnumstarter.jpg" 
      },
      { 
        id: 3, 
        name: "Starter for Massey Ferguson 5600", 
        price: 310, 
        description: "Reliable starter for Massey Ferguson 5600, built for consistent starting performance and longevity.", 
        image: "/images/massey5600starter.jpg" 
      },
      { 
        id: 4, 
        name: "Starter for Kubota M Series", 
        price: 330, 
        description: "Heavy-duty starter for Kubota M Series, designed for reliable and fast engine engagement.", 
        image: "/images/kubotamseriesstarter.jpg" 
      },
      { 
        id: 5, 
        name: "Starter for Ford 5000", 
        price: 300, 
        description: "Powerful starter for Ford 5000, ensuring efficient and reliable engine start-ups.", 
        image: "/images/ford5000starter.jpg" 
      },
      { 
        id: 6, 
        name: "Starter for Rolls Royce 1300", 
        price: 350, 
        description: "Premium starter for Rolls Royce 1300, providing consistent starting power and efficiency.", 
        image: "/images/rollsroyce1300starter.jpg" 
      },
      { 
        id: 7, 
        name: "Starter for John Deere 8530", 
        price: 340, 
        description: "High-quality starter for John Deere 8530, designed for optimal engine performance and quick start-ups.", 
        image: "/images/johndeere8530starter.jpg" 
      },
      { 
        id: 8, 
        name: "Starter for New Holland T7", 
        price: 315, 
        description: "Strong starter for New Holland T7, ensuring reliable and powerful engine starting capabilities.", 
        image: "/images/newhollandt7starter.jpg" 
      },
      { 
        id: 9, 
        name: "Starter for Deutz-Fahr 6200", 
        price: 325, 
        description: "Durable starter for Deutz-Fahr 6200, built for reliable performance and long service life.", 
        image: "/images/deutzfahr6200starter.jpg" 
      },
      { 
        id: 10, 
        name: "Starter for Claas Arion 500", 
        price: 310, 
        description: "Reliable starter for Claas Arion 500, designed for consistent engine performance and dependable operation.", 
        image: "/images/claasarion500starter.jpg" 
      },
      { 
        id: 11, 
        name: "Starter for Fendt 720 Vario", 
        price: 340, 
        description: "High-performance starter for Fendt 720 Vario, providing fast and reliable engine starts.", 
        image: "/images/fendt720variostarter.jpg" 
      },
      { 
        id: 12, 
        name: "Starter for Zetor Forterra", 
        price: 300, 
        description: "Powerful starter for Zetor Forterra, designed for efficient starting and enhanced machine operation.", 
        image: "/images/zetorforterrastarter.jpg" 
      },
      { 
        id: 13, 
        name: "Starter for Massey Ferguson 8700", 
        price: 330, 
        description: "High-quality starter for Massey Ferguson 8700, engineered for reliable and efficient starting power.", 
        image: "/images/massey8700starter.jpg" 
      },
      { 
        id: 14, 
        name: "Starter for Valtra T Series", 
        price: 315, 
        description: "Durable starter for Valtra T Series, designed for consistent engine starting and power delivery.", 
        image: "/images/valtratseriesstarter.jpg" 
      },
      { 
        id: 15, 
        name: "Starter for Case IH Steiger", 
        price: 340, 
        description: "Heavy-duty starter for Case IH Steiger, providing dependable and quick starting capabilities.", 
        image: "/images/caseihsteigerstarter.jpg" 
      },
      { 
        id: 16, 
        name: "Starter for Landini Rex", 
        price: 310, 
        description: "Efficient starter for Landini Rex, ensuring a strong and reliable start every time.", 
        image: "/images/landinirexstarter.jpg" 
      },
      { 
        id: 17, 
        name: "Starter for JCB 8250", 
        price: 335, 
        description: "Robust starter for JCB 8250, built for consistent and powerful engine starts under all conditions.", 
        image: "/images/jcb8250starter.jpg" 
      },
      { 
        id: 18, 
        name: "Starter for Valtra S Series", 
        price: 320, 
        description: "Reliable starter for Valtra S Series, designed to provide consistent performance and long-term durability.", 
        image: "/images/valtrasseriesstarter.jpg" 
      },
      { 
        id: 19, 
        name: "Starter for New Holland TS Series", 
        price: 325, 
        description: "High-quality starter for New Holland TS Series, built for effective engine starting and smooth operations.", 
        image: "/images/newhollandtsseriesstarter.jpg" 
      },
      { 
        id: 20, 
        name: "Starter for Deutz-Fahr Agrotron", 
        price: 340, 
        description: "Heavy-duty starter for Deutz-Fahr Agrotron, offering reliable and efficient starting power.", 
        image: "/images/deutzfahragrotronstarter.jpg" 
      },
      { 
        id: 21, 
        name: "Starter for Kubota BX Series", 
        price: 310, 
        description: "Dependable starter for Kubota BX Series, designed for smooth and consistent engine starts.", 
        image: "/images/kubotabxseriessstarter.jpg" 
      },
      { 
        id: 22, 
        name: "Starter for Case IH Magnum 340", 
        price: 345, 
        description: "Strong and durable starter for Case IH Magnum 340, providing optimal power and reliability.", 
        image: "/images/caseihmagnum340starter.jpg" 
      },
      { 
        id: 23, 
        name: "Starter for John Deere 8530", 
        price: 355, 
        description: "Heavy-duty starter for John Deere 8530, built for efficient engine start-up and reliability.", 
        image: "/images/johndeere8530starter2.jpg" 
      },
      { 
        id: 24, 
        name: "Starter for Fendt 720 Vario", 
        price: 350, 
        description: "Reliable starter for Fendt 720 Vario, designed for optimal starting power and smooth operation.", 
        image: "/images/fendt720variostarter2.jpg" 
      },
      { 
        id: 25, 
        name: "Starter for Claas Xerion", 
        price: 330, 
        description: "Powerful starter for Claas Xerion, known for consistent engine starts and long-lasting performance.", 
        image: "/images/claasxerionstarter.jpg" 
      }
  ],
  lights: [
    { 
        id: 1, 
        name: "Headlight for John Deere 7200", 
        price: 150, 
        description: "High-brightness headlight for John Deere 7200, designed for maximum visibility and durability.", 
        image: "/images/johndeere7200headlight.jpg" 
      },
      { 
        id: 2, 
        name: "Tail Light for Case IH Magnum", 
        price: 140, 
        description: "Reliable tail light for Case IH Magnum, providing excellent rear visibility and safety.", 
        image: "/images/caseihmagnumtaillight.jpg" 
      },
      { 
        id: 3, 
        name: "Fog Light for Massey Ferguson 5600", 
        price: 130, 
        description: "Durable fog light for Massey Ferguson 5600, designed for enhanced visibility in foggy conditions.", 
        image: "/images/massey5600foglight.jpg" 
      },
      { 
        id: 4, 
        name: "LED Work Light for Kubota M Series", 
        price: 160, 
        description: "High-intensity LED work light for Kubota M Series, perfect for nighttime tasks and long-lasting use.", 
        image: "/images/kubotamseriesworklight.jpg" 
      },
      { 
        id: 5, 
        name: "Warning Beacon for Ford 5000", 
        price: 170, 
        description: "Bright warning beacon for Ford 5000, ensuring visibility and safety during operation.", 
        image: "/images/ford5000warningbeacon.jpg" 
      },
      { 
        id: 6, 
        name: "Hazard Light for Rolls Royce 1300", 
        price: 180, 
        description: "High-visibility hazard light for Rolls Royce 1300, designed for alerting other operators to potential dangers.", 
        image: "/images/rollsroyce1300hazardlight.jpg" 
      },
      { 
        id: 7, 
        name: "Headlight for John Deere 8530", 
        price: 150, 
        description: "Powerful headlight for John Deere 8530, ensuring optimal visibility and long-lasting performance.", 
        image: "/images/johndeere8530headlight.jpg" 
      },
      { 
        id: 8, 
        name: "Tail Light for New Holland T7", 
        price: 140, 
        description: "Sturdy tail light for New Holland T7, providing excellent rear illumination for added safety.", 
        image: "/images/newhollandt7taillight.jpg" 
      },
      { 
        id: 9, 
        name: "LED Front Light for Deutz-Fahr 6200", 
        price: 160, 
        description: "Bright LED front light for Deutz-Fahr 6200, ideal for high-performance lighting during work.", 
        image: "/images/deutzfahr6200ledfrontlight.jpg" 
      },
      { 
        id: 10, 
        name: "Work Light for Claas Arion 500", 
        price: 155, 
        description: "Reliable work light for Claas Arion 500, providing clear illumination for all work conditions.", 
        image: "/images/claasarion500worklight.jpg" 
      },
      { 
        id: 11, 
        name: "Warning Light for Fendt 720 Vario", 
        price: 170, 
        description: "Durable warning light for Fendt 720 Vario, ensuring high visibility during operation for safety.", 
        image: "/images/fendt720variowarninglight.jpg" 
      },
      { 
        id: 12, 
        name: "LED Tail Light for Zetor Forterra", 
        price: 145, 
        description: "LED tail light for Zetor Forterra, designed to provide excellent rear visibility and durability.", 
        image: "/images/zetorforterrataillight.jpg" 
      },
      { 
        id: 13, 
        name: "High Beam Light for Massey Ferguson 8700", 
        price: 160, 
        description: "High beam light for Massey Ferguson 8700, designed for powerful and clear road illumination.", 
        image: "/images/massey8700highbeamlight.jpg" 
      },
      { 
        id: 14, 
        name: "Spotlight for Valtra T Series", 
        price: 155, 
        description: "Strong spotlight for Valtra T Series, providing focused illumination for specific work areas.", 
        image: "/images/valtratseriesspotlight.jpg" 
      },
      { 
        id: 15, 
        name: "Front Light for Case IH Steiger", 
        price: 165, 
        description: "Reliable front light for Case IH Steiger, offering enhanced visibility and powerful illumination.", 
        image: "/images/caseihsteigerfrontlight.jpg" 
      },
      { 
        id: 16, 
        name: "Headlamp for Landini Rex", 
        price: 150, 
        description: "Durable headlamp for Landini Rex, ensuring bright and reliable lighting for all conditions.", 
        image: "/images/landinirexheadlamp.jpg" 
      },
      { 
        id: 17, 
        name: "Halogen Headlight for JCB 8250", 
        price: 145, 
        description: "High-performance halogen headlight for JCB 8250, perfect for day and night operations.", 
        image: "/images/jcb8250halogenheadlight.jpg" 
      },
      { 
        id: 18, 
        name: "Rear Work Light for Valtra S Series", 
        price: 150, 
        description: "Sturdy rear work light for Valtra S Series, providing reliable illumination for safe working conditions.", 
        image: "/images/valtrasseriesrearworklight.jpg" 
      },
      { 
        id: 19, 
        name: "Amber Beacon for New Holland TS Series", 
        price: 175, 
        description: "Bright amber beacon for New Holland TS Series, designed for high-visibility operation in all weather.", 
        image: "/images/newhollandtsseriesamberbeacon.jpg" 
      },
      { 
        id: 20, 
        name: "High-Powered Work Light for Deutz-Fahr Agrotron", 
        price: 165, 
        description: "Powerful work light for Deutz-Fahr Agrotron, built for long-lasting, high-intensity illumination.", 
        image: "/images/deutzfahragrotronworklight.jpg" 
      },
      { 
        id: 21, 
        name: "LED Flood Light for Kubota BX Series", 
        price: 160, 
        description: "Energy-efficient LED flood light for Kubota BX Series, designed for wide-area lighting and safety.", 
        image: "/images/kubotabxseriesfloodlight.jpg" 
      },
      { 
        id: 22, 
        name: "Warning Light for Case IH Magnum 340", 
        price: 180, 
        description: "Bright warning light for Case IH Magnum 340, built for visibility and safety during operation.", 
        image: "/images/caseihmagnum340warninglight.jpg" 
      },
      { 
        id: 23, 
        name: "Dual Beam Light for John Deere 8530", 
        price: 170, 
        description: "Dual beam light for John Deere 8530, providing bright and adjustable lighting options for enhanced performance.", 
        image: "/images/johndeere8530dualbeamlight.jpg" 
      },
      { 
        id: 24, 
        name: "LED Spot Light for Fendt 720 Vario", 
        price: 165, 
        description: "LED spot light for Fendt 720 Vario, designed to offer focused illumination for precise work." , 
        image: "/images/fendt720varioledspotlight.jpg" 
      },
      { 
        id: 25, 
        name: "Work Light for Claas Xerion", 
        price: 150, 
        description: "Powerful work light for Claas Xerion, built to illuminate work areas with high intensity and clarity.", 
        image: "/images/claasxerionworklight.jpg" 
      }
  ],
  switches: [
    { 
        id: 1, 
        name: "Ignition Switch for John Deere 7200", 
        price: 75, 
        description: "Reliable ignition switch for John Deere 7200, providing seamless engine start and secure operation.", 
        image: "/images/johndeere7200ignitionswitch.jpg" 
      },
      { 
        id: 2, 
        name: "Headlight Switch for Case IH Magnum", 
        price: 70, 
        description: "Durable headlight switch for Case IH Magnum, designed for easy control of front and rear lights.", 
        image: "/images/caseihmagnumheadlightswitch.jpg" 
      },
      { 
        id: 3, 
        name: "Fog Light Switch for Massey Ferguson 5600", 
        price: 65, 
        description: "Convenient fog light switch for Massey Ferguson 5600, ensuring clear control during adverse weather.", 
        image: "/images/massey5600foglightswitch.jpg" 
      },
      { 
        id: 4, 
        name: "Starter Switch for Kubota M Series", 
        price: 80, 
        description: "High-quality starter switch for Kubota M Series, providing reliable starting performance.", 
        image: "/images/kubotamseriesstarterswitch.jpg" 
      },
      { 
        id: 5, 
        name: "Auxiliary Switch for Ford 5000", 
        price: 60, 
        description: "Multipurpose auxiliary switch for Ford 5000, designed for activating additional functions like lights or attachments.", 
        image: "/images/ford5000auxswitch.jpg" 
      },
      { 
        id: 6, 
        name: "Control Panel Switch for Rolls Royce 1300", 
        price: 85, 
        description: "Control panel switch for Rolls Royce 1300, ensuring ease of operation and maintenance for various systems.", 
        image: "/images/rollsroyce1300controlswitch.jpg" 
      },
      { 
        id: 7, 
        name: "Wiper Switch for John Deere 8530", 
        price: 70, 
        description: "High-durability wiper switch for John Deere 8530, designed for efficient windshield cleaning and visibility.", 
        image: "/images/johndeere8530wiperswitch.jpg" 
      },
      { 
        id: 8, 
        name: "Turn Signal Switch for New Holland T7", 
        price: 68, 
        description: "Reliable turn signal switch for New Holland T7, ensuring safety during turns and lane changes.", 
        image: "/images/newhollandt7turnsignalswitch.jpg" 
      },
      { 
        id: 9, 
        name: "PTO Control Switch for Deutz-Fahr 6200", 
        price: 72, 
        description: "PTO control switch for Deutz-Fahr 6200, designed for easy and efficient power take-off management.", 
        image: "/images/deutzfahr6200ptoswitch.jpg" 
      },
      { 
        id: 10, 
        name: "Cab Light Switch for Claas Arion 500", 
        price: 65, 
        description: "Cab light switch for Claas Arion 500, providing simple control over the interior lighting of the tractor cab.", 
        image: "/images/claasarion500cabswitch.jpg" 
      },
      { 
        id: 11, 
        name: "Hydraulic Control Switch for Fendt 720 Vario", 
        price: 90, 
        description: "Hydraulic control switch for Fendt 720 Vario, designed for precise control of hydraulic systems.", 
        image: "/images/fendt720variohydroswitch.jpg" 
      },
      { 
        id: 12, 
        name: "Brake Light Switch for Zetor Forterra", 
        price: 70, 
        description: "Reliable brake light switch for Zetor Forterra, ensuring optimal braking signal visibility for safety.", 
        image: "/images/zetorforterrabreaklight.jpg" 
      },
      { 
        id: 13, 
        name: "Engine Control Switch for Massey Ferguson 8700", 
        price: 80, 
        description: "Engine control switch for Massey Ferguson 8700, designed for smooth engine operation and control.", 
        image: "/images/massey8700enginecontrolswitch.jpg" 
      },
      { 
        id: 14, 
        name: "Indicator Switch for Valtra T Series", 
        price: 75, 
        description: "Indicator switch for Valtra T Series, ensuring clear signaling for directional changes.", 
        image: "/images/valtratseriesindicatorswitch.jpg" 
      },
      { 
        id: 15, 
        name: "Rear Light Switch for Case IH Steiger", 
        price: 77, 
        description: "Rear light switch for Case IH Steiger, providing easy operation for rear illumination control.", 
        image: "/images/caseihsteigerrearlightswitch.jpg" 
      },
      { 
        id: 16, 
        name: "Heater Control Switch for Landini Rex", 
        price: 78, 
        description: "Heater control switch for Landini Rex, designed to regulate the heating system for optimal comfort.", 
        image: "/images/landinirexheaterswitch.jpg" 
      },
      { 
        id: 17, 
        name: "Light Control Switch for JCB 8250", 
        price: 82, 
        description: "Light control switch for JCB 8250, offering easy access for switching between lights and light functions.", 
        image: "/images/jcb8250lightcontrolswitch.jpg" 
      },
      { 
        id: 18, 
        name: "Reverse Light Switch for Valtra S Series", 
        price: 73, 
        description: "Reverse light switch for Valtra S Series, ensuring clear visibility when reversing the tractor.", 
        image: "/images/valtraseriesreverselightswitch.jpg" 
      },
      { 
        id: 19, 
        name: "Power Window Switch for New Holland TS Series", 
        price: 69, 
        description: "Power window switch for New Holland TS Series, designed for easy operation of tractor windows.", 
        image: "/images/newhollandtsseriespowerwindowswitch.jpg" 
      },
      { 
        id: 20, 
        name: "Dual Control Switch for Case IH Magnum 340", 
        price: 85, 
        description: "Dual control switch for Case IH Magnum 340, built to manage multiple functions from one interface.", 
        image: "/images/caseihmagnum340dualcontrol.jpg" 
      },
      { 
        id: 21, 
        name: "Dashboard Light Switch for John Deere 8530", 
        price: 72, 
        description: "Dashboard light switch for John Deere 8530, designed for comfortable operation in low-light environments.", 
        image: "/images/johndeere8530dashboardlightswitch.jpg" 
      },
      { 
        id: 22, 
        name: "Fuel Pump Switch for Fendt 720 Vario", 
        price: 88, 
        description: "Fuel pump switch for Fendt 720 Vario, designed for quick and efficient fuel system operation.", 
        image: "/images/fendt720variofuelpumpswitch.jpg" 
      },
      { 
        id: 23, 
        name: "Ignition Control Switch for Deutz-Fahr Agrotron", 
        price: 90, 
        description: "Ignition control switch for Deutz-Fahr Agrotron, ensuring consistent starting and engine control.", 
        image: "/images/deutzfahragrotronignitionswitch.jpg" 
      },
      { 
        id: 24, 
        name: "Parking Brake Switch for Kubota BX Series", 
        price: 74, 
        description: "Parking brake switch for Kubota BX Series, built for secure and reliable parking control.", 
        image: "/images/kubotabxseriesparkingbrakeswitch.jpg" 
      },
      { 
        id: 25, 
        name: "Air Conditioning Control Switch for JCB 8250", 
        price: 78, 
        description: "Air conditioning control switch for JCB 8250, designed for comfortable temperature management in the cab.", 
        image: "/images/jcb8250airconditioningcontrol.jpg" 
      },
      { 
        id: 26, 
        name: "Oil Pressure Switch for John Deere 4000 Series", 
        price: 50, 
        description: "Reliable oil pressure switch for John Deere 4000 Series, ensuring accurate oil pressure readings and engine protection.", 
        image: "/images/johndeere4000oilpressureswitch.jpg" 
      },
      {
        id: 27,
        name: "Oil Pressure Switch for John Deere 8000 Series",
        price: 75,
        description: "High-quality oil pressure switch designed for John Deere 8000 Series, providing reliable monitoring and engine protection.",
        image: "/images/johndeere8000oilpressureswitch.jpg"
    },
    {
        id: 28,
        name: "Oil Pressure Switch for Case IH Puma",
        price: 80,
        description: "Durable oil pressure switch for Case IH Puma series, ensuring optimal oil pressure readings and preventing engine issues.",
        image: "/images/caseihpumaoilpressureswitch.jpg"
    },
    {
        id: 29,
        name: "Oil Pressure Switch for New Holland Boomer",
        price: 70,
        description: "Reliable oil pressure switch for New Holland Boomer tractors, designed for accurate pressure detection and long-term performance.",
        image: "/images/newhollandboomeroilpressureswitch.jpg"
    },
    {
        id: 30,
        name: "Oil Pressure Switch for Massey Ferguson 500 Series",
        price: 60,
        description: "Premium oil pressure switch for Massey Ferguson 500 Series, providing consistent readings and ensuring engine safety.",
        image: "/images/massey500oilpressureswitch.jpg"
    },
    {
        id: 31,
        name: "Oil Pressure Switch for Kubota M Series",
        price: 65,
        description: "Reliable oil pressure switch for Kubota M Series tractors, designed for optimal performance and long-lasting use.",
        image: "/images/kubotamseriesoilpressureswitch.jpg"
    },
    {
        id: 32,
        name: "Oil Pressure Switch for Ford 7000 Series",
        price: 55,
        description: "Durable oil pressure switch for Ford 7000 Series tractors, built to maintain accurate oil pressure and protect the engine.",
        image: "/images/ford7000oilpressureswitch.jpg"
    },
    {
        id: 33,
        name: "Universal High-Performance Oil Pressure Switch",
        price: 50,
        description: "Universal oil pressure switch suitable for a wide range of tractor models, providing reliable and accurate oil pressure readings.",
        image: "/images/universalhighperformanceoilpressureswitch.jpg"
    }
      
  ],
  brakePads: [
    {
        id: 1,
        name: "Brake Pads for John Deere 8000 Series",
        price: 100,
        description: "High-quality brake pads for John Deere 8000 Series, designed for reliable stopping power and safety.",
        image: "/images/johndeere8000brakepads.jpg"
    },
    {
        id: 2,
        name: "Brake Pads for Massey Ferguson 500 Series",
        price: 95,
        description: "Durable brake pads for Massey Ferguson 500 Series, ensuring efficient braking performance.",
        image: "/images/massey500brakepads.jpg"
    },
    {
        id: 3,
        name: "Brake Pads for Kubota M Series",
        price: 90,
        description: "Premium brake pads for Kubota M Series tractors, built for superior braking and long-lasting use.",
        image: "/images/kubotamseriesbrakepads.jpg"
    },
    {
        id: 4,
        name: "Brake Pads for Ford 7000 Series",
        price: 85,
        description: "Reliable brake pads for Ford 7000 Series, designed to enhance stopping performance and vehicle control.",
        image: "/images/ford7000brakepads.jpg"
    },
    {
        id: 5,
        name: "Universal Tractor Brake Pads",
        price: 80,
        description: "Versatile brake pads suitable for a variety of tractor models, providing dependable stopping power.",
        image: "/images/universaltractorbrakepads.jpg"
    }
],
brakeDiscs: [
    {
        id: 1,
        name: "Brake Disc for John Deere 8000 Series",
        price: 150,
        description: "High-quality brake disc for John Deere 8000 Series, designed for durability and superior braking performance.",
        image: "/images/johndeere8000brakedisc.jpg"
    },
    {
        id: 2,
        name: "Brake Disc for Massey Ferguson 500 Series",
        price: 140,
        description: "Durable brake disc for Massey Ferguson 500 Series, ensuring reliable and consistent braking.",
        image: "/images/massey500brakedisc.jpg"
    },
    {
        id: 3,
        name: "Brake Disc for Kubota M Series",
        price: 130,
        description: "Premium brake disc for Kubota M Series tractors, providing long-lasting braking performance.",
        image: "/images/kubotamseriesbrakedisc.jpg"
    },
    {
        id: 4,
        name: "Brake Disc for Ford 7000 Series",
        price: 125,
        description: "Reliable brake disc for Ford 7000 Series, offering effective braking and wear resistance.",
        image: "/images/ford7000brakedisc.jpg"
    },
    {
        id: 5,
        name: "Universal Tractor Brake Disc",
        price: 120,
        description: "Versatile brake disc suitable for various tractor models, designed for consistent and reliable braking.",
        image: "/images/universaltractorbrakedisc.jpg"
    }
],
brakeDrums: [
    {
        id: 1,
        name: "Brake Drum for John Deere 8000 Series",
        price: 160,
        description: "Heavy-duty brake drum for John Deere 8000 Series, built for optimal braking and durability.",
        image: "/images/johndeere8000brakedrum.jpg"
    },
    {
        id: 2,
        name: "Brake Drum for Massey Ferguson 500 Series",
        price: 150,
        description: "Reliable brake drum for Massey Ferguson 500 Series, ensuring consistent braking performance.",
        image: "/images/massey500brakedrum.jpg"
    },
    {
        id: 3,
        name: "Brake Drum for Kubota M Series",
        price: 140,
        description: "Durable brake drum for Kubota M Series tractors, designed for long-lasting use and reliable braking.",
        image: "/images/kubotamseriesbrakedrum.jpg"
    },
    {
        id: 4,
        name: "Brake Drum for Ford 7000 Series",
        price: 135,
        description: "Premium brake drum for Ford 7000 Series, built to withstand heavy-duty use and provide reliable braking.",
        image: "/images/ford7000brakedrum.jpg"
    },
    {
        id: 5,
        name: "Universal Tractor Brake Drum",
        price: 130,
        description: "Versatile brake drum suitable for a variety of tractor models, ensuring effective braking performance.",
        image: "/images/universaltractorbrakedrum.jpg"
    }
],
mastercylinders: [
    {
        id: 1,
        name: "Master Cylinder for John Deere 8000 Series",
        price: 200,
        description: "High-quality master cylinder for John Deere 8000 Series, ensuring optimal hydraulic pressure and braking efficiency.",
        image: "/images/johndeere8000mastercylinder.jpg"
    },
    {
        id: 2,
        name: "Master Cylinder for Massey Ferguson 500 Series",
        price: 190,
        description: "Reliable master cylinder for Massey Ferguson 500 Series, designed for smooth brake operation.",
        image: "/images/massey500mastercylinder.jpg"
    },
    {
        id: 3,
        name: "Master Cylinder for Kubota M Series",
        price: 180,
        description: "Durable master cylinder for Kubota M Series tractors, offering consistent hydraulic pressure.",
        image: "/images/kubotamseriesmastercylinder.jpg"
    },
    {
        id: 4,
        name: "Master Cylinder for Ford 7000 Series",
        price: 175,
        description: "Premium master cylinder for Ford 7000 Series, built for reliable performance and hydraulic efficiency.",
        image: "/images/ford7000mastercylinder.jpg"
    },
    {
        id: 5,
        name: "Universal Tractor Master Cylinder",
        price: 170,
        description: "Versatile master cylinder for various tractor models, ensuring optimal braking performance and reliability.",
        image: "/images/universaltractormastercylinder.jpg"
    }
],
brakecables: [
    {
        id: 1,
        name: "Brake Cable for John Deere 8000 Series",
        price: 50,
        description: "High-quality brake cable for John Deere 8000 Series, designed for consistent and responsive braking.",
        image: "/images/johndeere8000brakecable.jpg"
    },
    {
        id: 2,
        name: "Brake Cable for Massey Ferguson 500 Series",
        price: 45,
        description: "Durable brake cable for Massey Ferguson 500 Series, ensuring reliable braking operation.",
        image: "/images/massey500brakecable.jpg"
    },
    {
        id: 3,
        name: "Brake Cable for Kubota M Series",
        price: 40,
        description: "Premium brake cable for Kubota M Series tractors, built for durability and effective braking.",
        image: "/images/kubotamseriesbrakecable.jpg"
    },
    {
        id: 4,
        name: "Brake Cable for Ford 7000 Series",
        price: 38,
        description: "Reliable brake cable for Ford 7000 Series, providing smooth and dependable braking performance.",
        image: "/images/ford7000brakecable.jpg"
    },
    {
        id: 5,
        name: "Universal Tractor Brake Cable",
        price: 35,
        description: "Versatile brake cable suitable for various tractor models, ensuring consistent braking functionality.",
        image: "/images/universaltractorbrakecable.jpg"
    }
]

};
