// cataloguedata.js
const catalogueData = [
    {
      id: 1,
      name: "Product A",
      description: "High-quality tractor spare part for engine systems.",
      price: "$150",
      image: "https://example.com/product-a.jpg",
      link: "/product-a"
    },
    {
      id: 2,
      name: "Product B",
      description: "Durable spare part for tractor hydraulics.",
      price: "$200",
      image: "https://example.com/product-b.jpg",
      link: "/product-b"
    },
    // Add more products here
  ];
  
  export default catalogueData;
  