import React, { useState } from "react";
import { useParams, Link } from "react-router-dom";
import { productsData } from "./productData"; // Import the product data
import "./product.css";

const ProductPage = ({ addToCart }) => {
  const { subcategory } = useParams(); // Get the subcategory name from the URL
  const products = productsData[subcategory] || [];

  // State for search, filter, and sort
  const [searchQuery, setSearchQuery] = useState("");
  const [filterPrice, setFilterPrice] = useState("all");
  const [sortOrder, setSortOrder] = useState("none");

  // State for modal and quantity selection
  const [showQuantityModal, setShowQuantityModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quantity, setQuantity] = useState(1);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // Filter products based on search and price
  const filteredProducts = products.filter((product) => {
    const matchesSearch =
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.description.toLowerCase().includes(searchQuery.toLowerCase());

    const matchesPrice =
      filterPrice === "all" ||
      (filterPrice === "under50" && product.price < 50) ||
      (filterPrice === "50to100" && product.price >= 50 && product.price <= 100) ||
      (filterPrice === "over100" && product.price > 100);

    return matchesSearch && matchesPrice;
  });

  // Sort products based on the selected sort order
  const sortedProducts = [...filteredProducts].sort((a, b) => {
    if (sortOrder === "priceAsc") return a.price - b.price;
    if (sortOrder === "priceDesc") return b.price - a.price;
    return 0;
  });

  // Pagination logic
  const indexOfLastProduct = currentPage * itemsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
  const currentProducts = sortedProducts.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  const totalPages = Math.ceil(sortedProducts.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Function to show the modal and set the selected product
  const handleAddToCartClick = (product) => {
    setSelectedProduct(product);
    setShowQuantityModal(true);
  };

  // Function to handle adding product to the cart
  const handleAddToCart = () => {
    if (selectedProduct) {
      addToCart(selectedProduct, quantity); // Use addToCart prop
      setShowQuantityModal(false);
      setSelectedProduct(null);
      setQuantity(1);
    }
  };

  return (
    <div className="product-page">
      <h2>Products for {subcategory}</h2>

      {/* Back to Categories button */}
      <Link to="/category">
        <button className="back-button">Back to Categories</button>
      </Link>

      {/* Search Bar */}
      <div className="search-container">
        <input
          type="text"
          placeholder="Search products..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Filter by Price */}
      <div className="filter-container">
        <select value={filterPrice} onChange={(e) => setFilterPrice(e.target.value)}>
          <option value="all">All Prices</option>
          <option value="under50">Under $50</option>
          <option value="50to100">$50 - $100</option>
          <option value="over100">Over $100</option>
        </select>
      </div>

      {/* Sort Products */}
      <div className="sort-container">
        <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
          <option value="none">Sort By</option>
          <option value="priceAsc">Price: Low to High</option>
          <option value="priceDesc">Price: High to Low</option>
        </select>
      </div>

      <div className="product-list">
        {currentProducts.length > 0 ? (
          currentProducts.map((product) => (
            <div key={product.id} className="product-card">
              <img src={product.image} alt={product.name} />
              <h3>{product.name}</h3>
              <p>{product.description}</p>
              <p className="price">Price: ${product.price}</p>
              <button onClick={() => handleAddToCartClick(product)}>Add to Cart</button>
            </div>
          ))
        ) : (
          <p>No products found in this category.</p>
        )}
      </div>

      <div className="pagination">
  {/* Previous Button */}
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  >
    Previous
  </button>

  {/* Current Page Number */}
  <button
    onClick={() => handlePageChange(currentPage)}
    className="active"
  >
    {currentPage}
  </button>

  {/* Next Button */}
  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  >
    Next
  </button>
</div>



      {/* Quantity Modal */}
      {showQuantityModal && (
        <div className="quantity-modal">
          <h3>Select Quantity</h3>
          <input
            type="number"
            value={quantity}
            min="1"
            onChange={(e) => setQuantity(Number(e.target.value))}
          />
          <button onClick={handleAddToCart}>Add to Cart</button>
          <button onClick={() => setShowQuantityModal(false)}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default ProductPage;
