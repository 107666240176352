import './banks.css';

const bankDetails = [
  {
    bankName: "ACCESS BANK",
    accountHolder: "BEN-FIEG COMPANY LTD",
    accountNumber: "0024534971",
    
  },
  {
    bankName: "UNITED BANK FOR AFRICA",
    accountHolder: "EGEDE FIDELIS/NJIDEKA",
    accountNumber: "1001224370",
    
  }
];

export default bankDetails;
