import React, { useState } from "react";
import axios from "axios";

import "./form.css";

const Form = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleForm = () => {
    setErrorMessage("");
    setIsLogin(!isLogin);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");

    if (!isLogin) {
      if (!agreeToTerms) {
        setErrorMessage("You must agree to the Terms and Conditions to sign up.");
        return;
      }
      if (password !== confirmPassword) {
        setErrorMessage("Passwords do not match!");
        return;
      }
    }

    try {
      const endpoint = isLogin 
        ? `${process.env.REACT_APP_API_URL}/api/login` 
        : `${process.env.REACT_APP_API_URL}/api/signup`;

      const data = isLogin
        ? { email, password }
        : { email, password, name, phone };

      const response = await axios.post(endpoint, data);
      alert(`${isLogin ? "Login" : "Sign-Up"} successful!`);
      localStorage.setItem("token", response.data.token);

      // Reset form after successful submission
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setName("");
      setPhone("");
      setAgreeToTerms(false);
    } catch (error) {
      console.error(`${isLogin ? "Login" : "Sign-Up"} failed:`, error);
      setErrorMessage(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };

  return (
    <div className="form-container">
      <h2>{isLogin ? "Login" : "Sign-Up"}</h2>
      {errorMessage && <p className="form-error">{errorMessage}</p>}
      <form onSubmit={handleSubmit} className="form">
        {!isLogin && (
          <>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-input"
            />
            <input
              type="tel"
              placeholder="Phone Number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="form-input"
            />
          </>
        )}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="form-input"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="form-input"
        />
        {!isLogin && (
          <>
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="form-input"
            />
            <label className="terms-checkbox">
              <input
                type="checkbox"
                checked={agreeToTerms}
                onChange={(e) => setAgreeToTerms(e.target.checked)}
              />
              I agree to the <a href="/terms" target="_blank">Terms and Conditions</a>
            </label>
          </>
        )}
        <button type="submit" className="form-button">
          {isLogin ? "Login" : "Sign Up"}
        </button>
      </form>
      <p className="form-toggle">
        {isLogin ? "Don't have an account?" : "Already have an account?"}
        <button onClick={toggleForm} className="toggle-button">
          {isLogin ? "Sign Up" : "Login"}
        </button>
      </p>
    </div>
  );
};

export default Form;
