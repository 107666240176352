import React from "react";
import { useNavigate } from "react-router-dom";
import "./cart.css";

const Cart = ({ cartItems = [], onUpdateQuantity, onRemoveItem, onCheckout }) => {
  // Function to calculate the total cost of the items in the cart
  const calculateTotal = () => {
    return cartItems.reduce((acc, item) => acc + item.price * item.quantity, 0).toFixed(2);
  };

  // Use the useNavigate hook to redirect to the checkout page
  const navigate = useNavigate();

  return (
    <div className="cart-container">
      <h2>Your Shopping Cart</h2>

      {/* Display when cart is empty */}
      {cartItems.length === 0 ? (
        <p className="empty-cart">Your cart is empty. Start shopping now!</p>
      ) : (
        <>
          {/* Render all cart items */}
          <div className="cart-items">
            {cartItems.map((item) => (
              <div key={item.id} className="cart-item">
                {/* Display product image */}
                <img src={item.image} alt={item.name} className="cart-item-image" />

                {/* Product details */}
                <div className="cart-item-details">
                  <h3 className="cart-item-name">{item.name}</h3>
                  <p className="cart-item-price">Price: ${item.price.toFixed(2)}</p>

                  {/* Quantity controls */}
                  <div className="cart-item-quantity">
                    <button
                      onClick={() => onUpdateQuantity(item.id, item.quantity - 1)}
                      disabled={item.quantity <= 1} // Prevent quantity from going below 1
                    >
                      -
                    </button>
                    <span>{item.quantity}</span>
                    <button onClick={() => onUpdateQuantity(item.id, item.quantity + 1)}>
                      +
                    </button>
                  </div>
                </div>

                {/* Remove item button */}
                <button onClick={() => onRemoveItem(item.id)} className="remove-item">
                  Remove
                </button>
              </div>
            ))}
          </div>

          {/* Cart summary section */}
          <div className="cart-summary">
            <h3>Total: ${calculateTotal()}</h3>
            <button
              onClick={() => {
                onCheckout(); // This function can be customized to include additional logic
                navigate("/checkout"); // Redirect to the checkout page
              }}
              className="checkout-button"
            >
              Proceed to Checkout
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
