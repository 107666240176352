import React from 'react';
import './termsofservice.css';

const TermsOfService = () => {
  return (
    <div className="terms-of-service-container">
      <header>
        <h1>Terms of Service</h1>
      </header>
      <div className="terms-of-service-content">
        <section>
          <h2>Introduction</h2>
          <p>
            Welcome to our platform. These Terms of Service govern your use of our website and services. By accessing or using the services, you agree to comply with and be bound by these terms. If you do not agree with these terms, please refrain from using our services. We reserve the right to modify or update these terms at any time. Your continued use of our services after such changes will constitute your acceptance of the revised terms.
          </p>
          <p>
            Our services include access to digital content, communications, and various functionalities designed to enhance user experience. By using our services, you also agree to comply with any additional rules or guidelines that may be posted on our platform.
          </p>
        </section>

        <section>
          <h2>Acceptable Use</h2>
          <p>
            Our services may only be used for lawful purposes. You may not use our services to engage in any activities that may harm or interfere with the proper functioning of our services. You are prohibited from using the services to:
          </p>
          <p>
            - Engage in any illegal activity or violate any local, state, national, or international laws.<br />
            - Harass, abuse, or harm others, including stalking or threatening individuals.<br />
            - Transmit harmful software, viruses, or malware that could damage or impair the platform or other users' devices.<br />
            - Attempt unauthorized access to any of our services, systems, or networks.<br />
            - Engage in spamming, phishing, or any form of unsolicited communication.<br />
            - Use our services for any fraudulent or deceptive purposes.
          </p>
        </section>

        <section>
          <h2>Account Responsibility</h2>
          <p>
            You are solely responsible for maintaining the confidentiality of your account details, including your username, password, and other authentication credentials. You agree to notify us immediately if you suspect any unauthorized access to your account or if you become aware of any breach of security.
          </p>
          <p>
            We are not responsible for any loss or damage resulting from unauthorized access to your account, whether or not you have shared your login information. You agree to take all necessary precautions to safeguard your login details.
          </p>
          <p>
            In the event of a security breach, we may suspend your account or take any necessary actions to prevent further unauthorized access. You acknowledge that you are responsible for all activities that occur under your account, whether or not you authorized them.
          </p>
        </section>

        <section>
          <h2>Privacy</h2>
          <p>
            We value your privacy and are committed to protecting your personal data. Please refer to our <a href="/privacypolicy">Privacy Policy</a> for detailed information on how we collect, store, and use your personal information. By using our services, you consent to the collection and processing of your data as outlined in our Privacy Policy.
          </p>
          <p>
            We may collect information such as your name, email address, IP address, and usage data to improve our services and provide you with personalized content. We will not sell or rent your personal information to third parties without your consent.
          </p>
          <p>
            We implement industry-standard security measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. However, we cannot guarantee the absolute security of your information, as no method of transmission over the internet is entirely secure.
          </p>
        </section>

        <section>
          <h2>Intellectual Property</h2>
          <p>
            All content provided through our services, including but not limited to text, images, videos, logos, trademarks, software, and other materials, is owned by us or our licensors and is protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, distribute, or modify any of our content without prior written consent.
          </p>
          <p>
            Any user-generated content that you submit to our platform remains your property, but by submitting it, you grant us a license to use, display, and distribute the content as necessary to provide our services. You represent and warrant that you have all necessary rights to submit the content and that it does not infringe on the intellectual property rights of any third party.
          </p>
        </section>

        <section>
          <h2>Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by law, we are not liable for any direct, indirect, incidental, special, or consequential damages arising out of your use of our services, including but not limited to data loss, service interruptions, errors, omissions, or financial loss. You acknowledge that your use of our services is at your own risk.
          </p>
          <p>
            We are not liable for any content that you encounter while using our services. While we make efforts to ensure that the content on our platform is accurate and appropriate, we do not guarantee the accuracy, completeness, or reliability of the information provided.
          </p>
        </section>

        <section>
          <h2>Termination</h2>
          <p>
            We reserve the right to suspend or terminate your access to the services at any time, without notice, for violations of these Terms of Service or other reasons deemed necessary. Upon termination, you must immediately cease all use of our services and destroy any content you have obtained from the platform.
          </p>
          <p>
            We may also suspend your account or limit access to certain features if we detect suspicious or harmful activities associated with your account. If you believe your account was suspended or terminated in error, you may contact us for clarification or to appeal the decision.
          </p>
        </section>

        <section>
          <h2>Dispute Resolution</h2>
          <p>
            Any disputes arising from or related to these Terms of Service or your use of our services shall be resolved through binding arbitration. The arbitration shall be conducted in [Your City/State], and the laws of [Your State/Country] will govern. You agree that any claims, disputes, or legal proceedings will be exclusively handled in this jurisdiction.
          </p>
          <p>
            Before initiating any formal dispute resolution process, we encourage you to contact us directly to attempt to resolve the matter amicably. If a resolution cannot be reached through direct communication, the dispute will proceed to arbitration as outlined above.
          </p>
        </section>

        <section>
          <h2>Changes to the Terms</h2>
          <p>
            We may update or modify these Terms of Service from time to time. Any changes will be posted on this page with an updated effective date. We encourage you to review these terms periodically to stay informed about any changes. Your continued use of our services after any such modifications will be deemed acceptance of the updated terms.
          </p>
          <p>
            If you do not agree with the updated terms, you must discontinue your use of the services. We may also notify you of significant changes to these terms through email or on our platform, as applicable.
          </p>
        </section>

        <section>
          <h2>Contact Information</h2>
          <p>
            If you have any questions or concerns regarding these Terms of Service or any other aspect of our services, please feel free to contact us on <a href="mailto:benfieg@benfieg.com.ng">our mail</a>. We will do our best to respond to your inquiries in a timely and helpful manner.
          </p>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
